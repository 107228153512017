import { useTranslation } from "react-i18next";
import Insta from "../../assets/icons/Insta";
import Tel from '../../assets/icons/Telegram';
import teamImage1 from "../../assets/images/masooreh-team1.webp";
import teamImage2 from "../../assets/images/masooreh-team2.webp";
import teamImage3 from "../../assets/images/masooreh-team3.webp";

function Meet() {
  const { i18n } = useTranslation(); // هوک ترجمه
  const {
    meetTitle,
    memberName1,
    memberRole1,
    memberName2,
    memberRole2,
    memberName3,
    memberRole3,
  } = i18n.getDataByLanguage(i18n.language); // دریافت داده‌ها بر اساس زبان فعلی

  const members = [
    { name: memberName1, role: memberRole1, instaLink: "https://www.instagram.com/username1", telLink: "https://t.me/username1",image : teamImage1 },
    { name: memberName2, role: memberRole2, instaLink: "https://www.instagram.com/username2", telLink: "https://t.me/username2",image : teamImage2 },
    { name: memberName3, role: memberRole3, instaLink: "https://www.instagram.com/username3", telLink: "https://t.me/username3",image : teamImage3 },
  ];
  
  return (
    <div className="w-11/12 mt-20 m-auto">
      <h6 className="text-[30px] text-base-accent-1 text-center mb-4">
        {meetTitle}
      </h6>
      <div className="grid grid-cols-1 md:gap-7 md:grid-cols-3">
        {members.map((member, index) => (
          <div
            key={index}
            className="relative  w-[100%] group bg-transparent p-4 rounded-card overflow-hidden transition duration-300"
          >
            <img src={member.image} className="rounded-card" alt="Team Member" />

            <div className="absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-30 transition duration-300 rounded-card"></div>

            <div className="flex flex-col gap-4 items-center mt-4 relative z-10">
              <p className="text-base-accent-1 font-semibold text-[18px]">
                {member.name}
              </p>
              <p className="text-base-accent-1">{member.role}</p>
            </div>

            <div
              className="absolute p-3 md:p-0 top-4 right-4 md:inset-0 md:flex md:justify-center md:items-center flex flex-col md:flex-row gap-2 md:gap-4 opacity-100 md:opacity-0 group-hover:md:opacity-100 transition duration-300 z-10"
            >
              <a href='https://www.instagram.com/masooreh.de' target="_blank" rel="noopener noreferrer">
                <span className="w-[30px] h-[30px] flex items-center justify-center bg-white rounded-[50%] shadow-md">
                  <Insta />
                </span>
              </a>

              <a href='https://t.me/masooreh' target="_blank" rel="noopener noreferrer">
                <span className="w-[30px] h-[30px] flex items-center justify-center bg-white rounded-[50%] shadow-md">
                  <Tel />
                </span>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Meet;
