import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { showInfo } from '../../utils/toastUtils';
import getToken from '../../utils/getToken';
import formatPrice from '../../utils/formatPrice';
import { api } from '../../services/apiServices';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import translationOrderDetails from '../../translations/userPannel/translationsOrderDetails'; 

const OrderDetailsPage = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newDeliveryStatus, setNewDeliveryStatus] = useState('PENDING');
  const [newPayStatus, setNewPayStatus] = useState('PENDING');
  const token = getToken();
  const toast = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { i18n } = useTranslation();
  const translations = translationOrderDetails[i18n.language];

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await api.get(`orders/search?orderId=${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
       
        setOrderDetails(response.data);
        setNewDeliveryStatus(response.data.deliveryStatus);
        setNewPayStatus(response.data.status);
      } catch (err) {
        setError(translations.fetchError);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId, token]);

  const updateOrderStatus = async () => {
    try {
      await api.put(
        `orders/${orderId}/status?status=${newPayStatus}&deliveryStatus=${newDeliveryStatus}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrderDetails((prev) => ({ ...prev, deliveryStatus: newDeliveryStatus }));
      showInfo(toast, 'success', 'Success', translations.updateSuccess);
    } catch (err) {
      showInfo(toast, 'error', 'Error', translations.updateError);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'PENDING':
        return 'text-yellow-500';
      case 'SHIPPED':
        return 'text-blue-500';
      case 'IN_TRANSIT':
        return 'text-orange-500';
      case 'DELIVERED':
        return 'text-green-500';
      case 'FAILED':
        return 'text-red-500';
      case 'COMPLETED':
        return 'text-green-600';
      case 'CANCELLED':
        return 'text-red-600';
      default:
        return 'text-gray-400';
    }
  };

  if (loading) return <Loader />;
  if (error) return <div className="text-center py-16 text-red-500">{error}</div>;

  return (
    <div className="w-11/12  m-auto">
                        <Toast  position="top-center" className='w-11/12 z-50  m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />
                        <h6 className="text-xl text-base-accent-1 font-semibold mb-4">{translations.title}</h6>
      {orderDetails && (
        <div className="text-base-accent-1  my-5">

          <p className="font-semibold text-[14px] md:text-[16px]">{translations.orderId} <span className="text-gray-400 text-[14px]">{orderDetails.orderNumber}</span></p>

          <p className="font-semibold text-[14px] md:text-[16px]">{translations.totalPrice} <span className="text-gray-400 text-[14px]">{formatPrice(orderDetails.totalPrice)}</span></p>
          <p className="font-semibold text-[14px] md:text-[16px]">{translations.deliveryStatus} <span className={`text-[14px] ${getStatusColor(orderDetails.deliveryStatus)}`}>{orderDetails.deliveryStatus}</span></p>
          <p className="font-semibold text-[14px] md:text-[16px]">{translations.paymentStatus} <span className={`text-[14px] ${getStatusColor(orderDetails.status)}`}>{orderDetails.status}</span></p>
          <p className="font-semibold text-[14px] md:text-[16px]">{translations.orderCreatedAt} <span className="text-gray-400 text-[14px]">{moment(orderDetails.createdAt).format('YYYY-MM-DD HH:mm')}</span></p>
          {orderDetails.updatedAt && <p className="font-semibold">{translations.lastUpdatedAt} <span className="text-gray-400 text-[14px]">{moment(orderDetails.updatedAt).format('YYYY-MM-DD HH:mm')}</span></p>}
          {orderDetails.pdfUrl && (
            <a
              href={orderDetails.pdfUrl}
              className="text-blue-500 underline text-[14px] mt-2 inline-block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translations.downloadInvoice}
            </a>
          )}

         <div className='overflow-x-scroll'>  <table className="min-w-full bg-white border my-5 border-gray-200 rounded-md">
            <thead>
              <tr>
                <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">{translations.productId}</th>
                <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">{translations.quantity}</th>
                <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">{translations.price}</th>
                <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">{translations.image}</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails.orderItems.map((item, index) => (
                <tr key={index}>
                  <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">{item.productId}</td>
                  <td className="border-b  px-4 py-2 text-[14px] md:text-[16px]">{item.quantity}</td>
                  <td className="border-b  px-4 py-2 text-[14px] md:text-[16px]">{formatPrice(item.price)}</td>
                  <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                    <img src={item.imageUrl} alt="Product" className="w-16 h-16 object-cover" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      )}
      {user.roles[0].roleName === 'ROLE_ADMIN' && (
        <div className="flex flex-col md:flex-row md:gap-10 gap-2">
          <div className="mt-4 flex flex-col">
            <label htmlFor="deliveryStatus" className="block font-bold mb-1">{translations.changeDeliveryStatus}</label>
            <select
              id="deliveryStatus"
              className="border rounded-[30px] p-2 mb-4 w-[300px] bg-white"
              value={newDeliveryStatus}
              onChange={(e) => setNewDeliveryStatus(e.target.value)}
            >
              <option value="PENDING">{translations.pending}</option>
              <option value="SHIPPED">{translations.shipped}</option>
              <option value="IN_TRANSIT">{translations.inTransit}</option>
              <option value="DELIVERED">{translations.delivered}</option>
              <option value="FAILED">{translations.failed}</option>
            </select>
            <button
              className="bg-base-accent-1 mb-5 text-white px-4 py-2 rounded-[30px] w-fit  text-[14px] md:w-[300px]"
              onClick={() => updateOrderStatus()}
            >
              {translations.updateDeliveryStatus}
            </button>
          </div>
          <div className="mt-4 flex flex-col">
            <label htmlFor="payStatus" className="block font-bold mb-1">{translations.changePaymentStatus}</label>
            <select
              id="payStatus"
              className="border rounded-[30px]  p-2 mb-4 w-[300px] bg-white"
              value={newPayStatus}
              onChange={(e) => setNewPayStatus(e.target.value)}
            >
              <option value="PENDING">{translations.pending}</option>
              <option value="COMPLETED">{translations.completed}</option>
              <option value="CANCELLED">{translations.cancelled}</option>
            </select>
            <button
              className="bg-base-accent-1 mb-5 text-white px-4 py-2 rounded-[30px] w-fit md:w-[300px]"
              onClick={() => updateOrderStatus()}
            >
              {translations.updatePaymentStatus}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsPage;