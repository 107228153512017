import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { Toast } from 'primereact/toast';
import BannerAccount from "../components/banner/BannerAccount";
import { useTranslation } from "react-i18next";
import Captcha from "../components/captch/GoogleRecaptch"; // Import Captcha
import { api } from "../services/apiServices";

function ForgotPassword() {
    const toast = useRef(null);
    const navigate = useNavigate(); // برای هدایت کاربر به صفحه دیگر
    const [email, setEmail] = useState('');
    const [isVerified, setIsVerified] = useState(false); // CAPTCHA verification state

    const { i18n } = useTranslation();
    const {
        resetPasswordTitle,
        resetPasswordMessage,
        emailPlaceholder,
        submitButton,
        cancelLink,
        errorEmptyEmail,
        errorSendFailure,
        emailCheckMessage, // پیام بررسی ایمیل
    } = i18n.getDataByLanguage(i18n.language);

    const showInfo = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail, life: 3000 });
    };

    const sendEmail = async (event) => {
        event.preventDefault();

        if (!isVerified) {
            showInfo("error", "Error", "Please complete the CAPTCHA.");
            return;
        }

        if (!email) {
            // Display error message if input is empty
            showInfo("error", "Error", errorEmptyEmail);
            return;
        }

        try {
            const res = await api.post(
                "auth/forgot-password",
                email, // Send email as raw string
                {
                    headers: {
                        "Content-Type": "text/plain", // Set the data type to plain text
                    },
                    transformRequest: [(data) => data], // Prevent data conversion to JSON
                }
            );

            showInfo("success", "Success", emailCheckMessage || "Please check your email for the reset link.");

            setTimeout(() => {
                navigate("/"); // Navigate to home or login page after success
            }, 3000);
        } catch (error) {
            // Display error message if an error occurs
            showInfo("error", "Error", error.response?.data?.message || errorSendFailure);
        }
    };

    return (
        <>
            <div>
                <BannerAccount />
                <div className='text-base-accent-1 w-11/12 md:3/4 lg:w-1/2 m-auto flex flex-col gap-5 items-center text-center my-20 justify-center'>
                    <h5 className='text-[30px]'>{resetPasswordTitle}</h5>
                    <p>{resetPasswordMessage}</p>
                    <form className='flex flex-col w-full m-auto md:w-11/12 gap-5 mt-6 text-[14px]' onSubmit={sendEmail}>
                        <Toast position="top-center" className='w-11/12 z-50 m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />
                        
                        {/* Email Input */}
                        <div className="relative">
                            <input
                                type="text"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                className='py-3 outline-none px-6 rounded-[25px] border border-1 border-[rgba(23,64,60,0.2)]'
                            />
                            <label htmlFor="email" className="absolute text-sm text-base-second_border left-6 top-0 pt-2">{emailPlaceholder || "Email Address"}</label>
                        </div>

                        {/* Captcha */}
                        <Captcha onVerified={setIsVerified} /> 

                        {/* Submit Button */}
                        <div className='mx-auto w-full '>
                            <button
                                className={`text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[100%] md:w-[130px] md:mx-auto text-center border border-1 border-base-accent-2`}
                            >
                                {submitButton || "Send Reset Link"}
                            </button>
                        </div>
                    </form>

                    {/* Link to Cancel / Go to Login */}
                    <div className="w-full text-center mt-2 text-[14px] md:text-[15px]">
                        <Link to='/account/login' className="underline text-base-accent-1">{cancelLink || "Back to Login"}</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
