import React, { useEffect, useRef, useState } from 'react'
import getToken from '../../utils/getToken';
import EditCategoryModal from './EditCategoryModal'
import { showInfo } from '../../utils/toastUtils';
import { Toast } from 'primereact/toast';
import Loader from '../Loader/Loader';
import PencilMd from '../../assets/icons/PencilMd';
import Trash from '../../assets/icons/Trash';
import DeleteModal from './DeleteModal';
import Discount from '../../assets/icons/discount-svgrepo-com.svg'
import { api } from '../../services/apiServices';

function AllCategories({ categories, setCategories }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false); // New state for confirmation modal
    const [categoryToDelete, setCategoryToDelete] = useState(null); // Store the category to delete
    const [showDiscountModal, setShowDiscountModal] = useState(false); // State to show the discount modal
    const [discountPercentage, setDiscountPercentage] = useState(0); // State for storing the discount percentage
    const [categoryForDiscount, setCategoryForDiscount] = useState(null); // Store the category for discount
    const toast = useRef(null);

    useEffect(() => {
        // Fetch categories from API 
        const fetchCategories = async () => {
            try {
                const response = await api.get('product_categories');
                setCategories(response.data);
            } catch (error) {
                
            }
        };

        fetchCategories();
    }, [setCategories]);

    const handleEditClick = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedCategory(null);
    };

    const handleDeleteClick = (category) => {
        setCategoryToDelete(category); // Set the category to delete
        setIsDeleting(true); // Show the confirmation modal
    };

    const confirmDelete = async () => {
        const token = getToken();
        try {
            const response = await api.delete(`product_categories/${categoryToDelete.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            showInfo(toast, "success", "Success", 'Category removed.');
            setCategories(categories.filter(category => category.id !== categoryToDelete.id));
            setIsDeleting(false); // Close the confirmation modal
        } catch (err) {
           
            setIsDeleting(false); // Close the confirmation modal in case of an error
        }
    };

    const cancelDelete = () => {
        setIsDeleting(false); // Close the confirmation modal
        setCategoryToDelete(null); // Clear the category to delete
    };

    // Handle opening discount modal
    const handleDiscountClick = (category) => {
        setCategoryForDiscount(category); // Set the category for which discount is applied
        setDiscountPercentage(category.discount || 0); // Prepopulate the discount percentage if already set
        setShowDiscountModal(true); // Show the modal to enter discount
    };

    const handleDiscountSubmit = async () => {
        if (discountPercentage <= 0 || discountPercentage > 100) {
            showInfo(toast, "error", "Invalid Discount", "Please enter a valid discount percentage (1-100).");
            return;
        }

        const token = getToken();
        try {
            const response = await api.put(
                `products/discount/category/${categoryForDiscount.id}?discountPercentage=${discountPercentage}&isDiscount=true`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            showInfo(toast, "success", "Discount applied", `Discount of ${discountPercentage}% applied to category.`);
            // Update the categories with the new discount information
            setCategories(categories.map(cat =>
                cat.id === categoryForDiscount.id ? { ...cat, discount: discountPercentage } : cat
            ));
            setShowDiscountModal(false); // Close the discount modal
        } catch (error) {
            showInfo(toast, "error", "Error", "Failed to apply discount.");
        }
    };

    const cancelDiscount = () => {
        setShowDiscountModal(false); // Close the discount modal without applying anything
        setDiscountPercentage(0); // Reset discount percentage
    };

    if (categories.length === 0) return <Loader />;

    return (
        <>
            <ul className="space-y-4 ">
            <Toast  position="top-center" className='w-11/12 z-50  m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />
            {categories?.map(category => (
                    <li key={category.id} className="flex border-b border-gray-100 justify-between items-center px-4 py-2 ">
                        <div className='flex gap-4 items-center'>
                            <img className='w-[40px] h-[40px] rounded-md' src={category.categoryImage !== null && category.categoryImage.imageUrl} />
                            <div>
                                <strong>{category.translations && category.translations.length > 0 && category.translations[0].name}</strong>
                            </div>
                        </div>
                        <div className="flex items-center">
                            {/* Disable the discount button if the category already has a discount */}
                            <button 
                                onClick={() => handleDiscountClick(category)} 
                                className={`mr-2  'text-green-600'}`}
                                
                            >
                                <img src={Discount} alt='%' className='w-[20px] h-[20px]' />
                            </button>
                            <button onClick={() => handleEditClick(category)} className="mr-2 text-blue-600"><PencilMd /></button>
                            <button onClick={() => handleDeleteClick(category)} className="text-red-600"><Trash /></button>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Confirmation Modal for Deleting Category */}
            {isDeleting && (
                <DeleteModal confirmDelete={confirmDelete} cancelDelete={cancelDelete} text='category' />
            )}

            {/* Discount Modal */}
            {showDiscountModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-md">
                        <p className="text-lg font-semibold text-base-accent-1">Apply Discount</p>
                        <input
                            type="number"
                            value={discountPercentage}
                            onChange={(e) => setDiscountPercentage(e.target.value)}
                            placeholder="Enter discount percentage"
                            min="0"
                            max="100"
                            className="border p-2 rounded mt-2"
                        />
                        <div className="mt-4">
                            <button 
                                onClick={handleDiscountSubmit} 
                                className="bg-base-accent-1 text-white px-4 py-2 rounded-[30px] mr-2"
                            >
                                Apply
                            </button>
                            <button 
                                onClick={cancelDiscount} 
                                className="bg-gray-300 text-black px-4 py-2 rounded-[30px]"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <EditCategoryModal
                    category={selectedCategory}
                    onClose={handleModalClose}
                    setCategories={setCategories} // Pass setCategories to update the list
                />
            )}
        </>
    );
}

export default AllCategories;
