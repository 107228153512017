import React, { useEffect, useState } from "react";
import { api } from "../../services/apiServices";
import Loader from "../../components/Loader/Loader";
import moment from "moment";

function ContactusMessages() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllMessages = async () => {
      setLoading(true);
      try {
        const res = await api.get("/contact-us");
        setMessages(res.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    getAllMessages();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="p-6  min-h-screen">
                        <h5 className="text-xl md:text-2xl text-base-accent-1 my-8 font-semibold text-center md:text-left mb-4">Messages</h5>

      {messages.length > 0 ? (
        <div className="overflow-x-auto  bg-white">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead >
              <tr>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  #
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Name
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Email
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Message
                </th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">
              {messages.map((message, index) => (
                <tr key={message.id} className="hover:bg-gray-50   ">
                  <td className=" text-sm text-blue-400 border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">
                    {index + 1}
                  </td>
                  <td className=" text-sm text-gray-700 border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">
                    {message.firstName}
                  </td>
                  <td className="text-sm text-gray-700 border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">
                    {message.email}
                  </td>
                  <td className=" text-sm text-gray-700 border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">
                    {message.message}
                  </td>
                  <td className=" text-sm text-gray-700 border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">
                    {moment(message.createdAt).format('YYYY-MM-DD HH:mm')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center mt-6">
          <p className="text-gray-700 text-lg">No messages found.</p>
        </div>
      )}
    </div>
  );
}

export default ContactusMessages;
