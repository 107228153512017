import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ onVerified }) => {
    const [captchaVerified, setCaptchaVerified] = useState(false);

    // Handle CAPTCHA token changes
    const handleCaptchaChange = (token) => {
        if (token) {
            setCaptchaVerified(true); // Mark CAPTCHA as verified
            onVerified(true); // Notify the parent component that CAPTCHA is verified
        } else {
            setCaptchaVerified(false); // Mark CAPTCHA as not verified
            onVerified(false); // Notify the parent component that CAPTCHA is not verified
        }
    };

    return (
        <ReCAPTCHA
        sitekey="6Ld-WaAqAAAAALVp-_PGIGwwl2eIicOniH5IeTIq"            onChange={handleCaptchaChange} // Function to handle CAPTCHA changes
            size="normal" // Use "normal" size which only requires ticking the box
            badge="bottomright" // Position of the reCAPTCHA badge
        />
    );
};

export default Captcha;
