import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SlideBox from './SlideBox';

// Import Swiper modules
import { Autoplay, EffectFade } from 'swiper/modules';

const Slider = () => {
  const { i18n } = useTranslation();

  const slides = i18n.getDataByLanguage(i18n.language).slides;

  return (
    <Swiper
      className="swiper"
      spaceBetween={50} // Space between slides
      slidesPerView={1} // Number of slides visible at a time
      modules={[Autoplay, EffectFade]} // Adding required modules
      autoplay={{
        delay: 7000, // Time between each slide in milliseconds
        disableOnInteraction: false, // Prevent autoplay from stopping when the user interacts with the slider
      }}
      effect="slide" // Adding slide effect
      fadeEffect={{
        crossFade: true, // Enable smoother transition between slides
      }}
      speed={1000} // Set slide transition speed (in milliseconds)
      loop={true} // Enable loop for continuous sliding
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <SlideBox
            title={slide.title}
            description={slide.description}
            button={slide.button}
            bgImage={slide.bgImage}
            image={slide.image}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
