import { Link } from "react-router-dom";
import leftImage from "../../assets/images/masooreh.home1.webp";
import rightImage1 from "../../assets/images/masooreh.home3.webp";

import rightImage2 from "../../assets/images/masooreh.home2.webp";
import ArrowRight from "../../assets/icons/ArrowRight";
import { useTranslation } from "react-i18next";

function TopProducts() {
  const { i18n } = useTranslation();
  const { topProductsTitle,ShopNowBtn } = i18n.getDataByLanguage(i18n.language);
  return (
    <div className="w-11/12 my-16 md:my-20  m-auto flex  flex-col md:flex-row ">
        <div className="md:w-7/12 lg:w-1/2 ">
          <img src={leftImage} className="h-9/12 rounded-card"  alt="product"/>
        </div>
        <div className="flex to md:w-5/12 lg:w-1/2 items-center flex-col justify-center  ">
            <h4 className="text-[20px] m-auto md:text-[25px] mt-10 lg:text-[30px] text-center mb-8 w-11/12 lg:w-3/4  text-base-accent-1">{topProductsTitle} </h4>
           <div className="flex w-full md:w-10/12 gap-7 ">
           <img  className=' rounded-card w-5/12 m-auto md:w-full lg:w-1/2'src={rightImage1}  alt="product"/>
           <img src={rightImage2} className="w-5/12 m-auto rounded-card md:w-1/2 md:hidden lg:block "  alt="product"/>
           </div>
           <div className=' my-14  '>
                <Link to='/products' className={`text-base-accent-1  bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover   flex items-center  justify-center  gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-fit text-center border border-1 border-base-accent-2`}>
                {ShopNowBtn}
                <span>
                   <ArrowRight />
                </span>

            </Link>
        </div>
        </div>
    </div>
  )
}

export default TopProducts