import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { api } from '../../services/apiServices';
import getToken from '../../utils/getToken';
import SuccessPayment from '../../assets/images/successPayment.svg';
import { useDispatch } from 'react-redux';
import { RESET_CART } from '../../store/slices/cartSlice';
import Loader from '../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';

export function PaymentSuccess() {
  const [isLoadingFetch, setIsLoadingFetch] = useState(true);
  const dispatch = useDispatch();
  

  const isSuccess = localStorage.getItem('isSuccess');
  const orderId = localStorage.getItem('orderId');
  
  const {i18n}=useTranslation()
    const {  PaymentSuccessful,
      proccessOrder,
      proccessSuccessFully,
      viewOrderPayment,
      goToHomePayment}=i18n.getDataByLanguage(i18n.language);
      
  useEffect(() => {
    const token = getToken();
    if (!orderId) return;


    const handleSuccessOrder = async () => {
      setIsLoadingFetch(true);
      try {

        const res = await api.put(
          `orders/${orderId}/status?status=COMPLETED`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        localStorage.setItem('isSuccess', true);
        localStorage.removeItem('orderId');
        dispatch(RESET_CART ());
      } catch (error) {
        if (error.response?.status === 404) {
          //showInfo('Order already processed or not found.');
        } else {
          // Handle other errors if necessary
        }
      } finally {
        setIsLoadingFetch(false);
      }
    };

    if (!isSuccess) {
      handleSuccessOrder();
    }

    return () => {
      localStorage.removeItem('isSuccess');
    };
  }, [isSuccess, dispatch, orderId]);

  return (
   <> {
      isLoadingFetch?
      <Loader />
      :
      <div className="min-h-screen py-10 flex flex-col items-center justify-center bg-green-50 text-base-accent-1">
      <div className="w-full bg-green-50 md:bg-white items-center md:w-10/12 flex flex-col md:flex-row justify-between p-5 md:p-10 md:border md:border-1 rounded-sm">
        <div className="w-full md:w-1/2 text-left">
          <h6 className="text-2xl font-semibold text-base-accent-1 mb-4">{PaymentSuccessful}</h6>
          <p className="text-base-accent-1 mb-6">
            {isLoadingFetch
              ? {proccessOrder}
              : proccessSuccessFully}
          </p>
          <div className="flex flex-col md:flex-row gap-4">
            <Link
              to="/user/orders"
              className="px-5 w-full md:w-fit text-center py-3 bg-base-accent-1 text-white rounded-[30px] hover:bg-green-700 transition duration-300"
              disabled={isLoadingFetch} // Disable the button while loading
            >
              {viewOrderPayment}
            </Link>
            <Link
              to="/"
              className="px-5 py-3 w-full md:w-fit  text-center bg-base-accent-1/50 text-base-accent-1 rounded-[30px] hover:bg-green-200 transition duration-300"
              disabled={isLoadingFetch} // Disable the button while loading
            >
              {goToHomePayment}
            </Link>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <img src={SuccessPayment} className="text-right mt-10 md:mt-0" alt="Payment Successful" />
        </div>
      </div>
    </div>

    }
        </>
  );
}
