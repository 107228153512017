import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 

import { Autoplay } from 'swiper/modules'; // ماژول Autoplay را اضافه کنید
import img1 from '../../assets/images/masooreh.grid-home1.webp';
import img2 from '../../assets/images/masooreh.grid-home2.webp';
import img3 from '../../assets/images/masooreh.grid-home3.webp';
import img4 from '../../assets/images/masooreh.grid-home4.webp';
import img5 from '../../assets/images/masooreh.grid-home5.webp';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/icons/ArrowRight';

const images = [
  { src: img1, id: 1 },
  { src: img2, id: 2 },
  { src: img3, id: 3 },
  { src: img4, id: 4 },
  { src: img5, id: 5 },
];

const GridSlider = () => {
  const { i18n } = useTranslation();

  // Load slides
  const { title, description, shopButton } = i18n.getDataByLanguage(i18n.language);

  return (
    <div className="my-0 p-0">
      <Swiper
        slidesPerView={3}
        spaceBetween={0}
        autoplay={{
          delay: 5000, 
          disableOnInteraction: false,
        }}
        speed={1000} 
        pagination={{ clickable: true }}
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          998: {
            slidesPerView: 3,
          },
        }}
        modules={[Autoplay]} // اضافه کردن ماژول Autoplay
      >
        {images.map((image, index) => (
          <SwiperSlide className="m-0 w-[100%]" key={index}>
            <div className="w-full h-[500px] flex items-center justify-center">
              <img
                src={image.src}
                alt={`Slide ${index}`}
                className="w-full object-cover"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
          <div className="my-4">
            <Link
              to="/products"
              className={`text-base-accent-1 m-auto bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[220px] text-center border border-1 border-base-accent-2`}
            >
              {shopButton}
              <ArrowRight />
            </Link>
          </div>
    </div>
  );
};

export default GridSlider;
