import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BoxFilterLaptop = ({ filters = {}, handleApplyFilters }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // گرفتن پارامترهای فیلتر از URL
  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      minPrice: searchParams.get('minPrice') || filters.minPrice || '',
      maxPrice: searchParams.get('maxPrice') || filters.maxPrice || '',
      sortOrder: searchParams.get('sortOrder') || filters.sortOrder || 'asc',
      search: searchParams.get('search') || filters.search || '',
    };
  };

  // مدیریت فیلترهای محلی
  const [localFilters, setLocalFilters] = useState(getFiltersFromURL);

  const {
    filtersTitle,
    priceLabel,
    sortLabel,
    ascending,
    descending,
    searchPlaceholder,
    applyButton,
  } = i18n.getDataByLanguage(i18n.language);

  const updateURL = (filters) => {
    const searchParams = new URLSearchParams();

    if (filters.minPrice) searchParams.set('minPrice', filters.minPrice);
    if (filters.maxPrice) searchParams.set('maxPrice', filters.maxPrice);
    if (filters.sortOrder) searchParams.set('sortOrder', filters.sortOrder);
    if (filters.search) searchParams.set('search', filters.search);

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  // بروزرسانی فیلترها در صورت تغییر URL
  useEffect(() => {
    setLocalFilters(getFiltersFromURL());
  }, [location.search]);

  // مدیریت تغییرات ورودی‌ها
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'radio') {
      // برای دکمه‌های رادیویی، فقط مقدار checked را برای sortOrder تغییر می‌دهیم
      const updatedFilters = { ...localFilters, sortOrder: value };
      setLocalFilters(updatedFilters);
      updateURL(updatedFilters);
    } else {
      // سایر ورودی‌ها مثل text و number
      const updatedFilters = { ...localFilters, [name]: value };
      setLocalFilters(updatedFilters);
      updateURL(updatedFilters);
    }
  };

  return (
    <div className="hidden lg:block">
      <div className="p-4 shadow-md bg-white rounded-lg">
        <h3 className="text-lg font-semibold mb-4">{filtersTitle}</h3>

        {/* فیلتر قیمت */}
        <div className="mb-6">
          <span className="text-sm font-medium">{priceLabel}</span>
          <div className="mt-2 flex space-x-2">
            <input
              type="number"
              name="minPrice"
              placeholder="Min"
              min="0"
              value={localFilters.minPrice}
              onChange={handleInputChange}
              className="border border-gray-300 outline-none rounded p-2 w-full"
            />
            <input
              type="number"
              name="maxPrice"
              placeholder="Max"
              min="0"
              value={localFilters.maxPrice}
              onChange={handleInputChange}
              className="border border-gray-300 outline-none rounded p-2 w-full"
            />
          </div>
        </div>

        {/* مرتب‌سازی */}
        <div className="mb-6">
          <span className="text-sm font-medium">{sortLabel}</span>
          <div className="mt-2">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="sortOrder"
                value="asc"
                checked={localFilters.sortOrder === 'asc'}
                onChange={handleInputChange}
                className="cursor-pointer"
              />
              <span>{ascending}</span>
            </label>
            <label className="flex items-center space-x-2 mt-2">
              <input
                type="radio"
                name="sortOrder"
                value="desc"
                checked={localFilters.sortOrder === 'desc'}
                onChange={handleInputChange}
                className="cursor-pointer"
              />
              <span>{descending}</span>
            </label>
          </div>
        </div>

        {/* جستجو */}
        <div className="mb-6">
          <input
            type="text"
            name="search"
            placeholder={searchPlaceholder}
            value={localFilters.search}
            onChange={handleInputChange}
            className="border border-gray-300 outline-none rounded p-2 w-full"
          />
        </div>

        <button
          onClick={() => handleApplyFilters(localFilters)}
          className="bg-base-accent-1 text-white py-2 px-4 rounded"
        >
          {applyButton}
        </button>
      </div>
    </div>
  );
};

export default BoxFilterLaptop;
