
const translationsFailed = {
    en: {
        PaymentFailed:"Payment Failed",
        proccessFailed:'Unfortunately, your payment could not be processed. Please try again or contact support for assistance.',
        goToHomePayment:'Go to Home',
     
  
    },
    de: {
        PaymentFailed:'Zahlung erfolgreich!',
        proccessFailed:'Leider konnte Ihre Zahlung nicht verarbeitet werden. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support, um Hilfe zu erhalten.',

        goToHomePayment:'Gehe zur Startseite',

    
    }
  };
  
  export default translationsFailed;