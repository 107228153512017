import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addTranslations from '../../utils/convertProductsToi18nFormat'; 
import ProductshoppingCard from '../shopping/ProductshoppingCard';
import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/icons/ArrowRight';

const ProductsList = ({ products }) => {
  const { i18n } = useTranslation();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    addTranslations(products, i18n);
    setTranslationsLoaded(true); // Set to true after translations are added
  }, [products, i18n]);

  // If translations are not loaded yet, avoid rendering the component
  if (!translationsLoaded) {
    return null;
  }

  const { titleAbove, descriptionAbove,ShopNowBtn } = i18n.getDataByLanguage(i18n.language);

  return (
    <div className='pt-10 '>
      <div className='text-center flex flex-col gap-1 w-10/12 m-auto md:w-full pb-8'>
        <h6 className='text-[20px] m-auto text-center w-11/12  md:w-3/4  md:text-[30px] md:text-center text-base-accent-1'> {titleAbove} </h6>
        <p className='text-base-accent-1 w-11/12 m-auto text-center md:w-3/4   text-[16px]'>{descriptionAbove}</p>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 w-11/12 gap-8 m-auto'>
        {products.length > 0 && products?.map((item) => (
          <ProductshoppingCard 
            product={item} 
            key={item.id} 
            productName={i18n.t(`product:productName-${item.id}`)}    
          />
        ))}
      </div>
      <div className="mt-10 lg:mt-16 flex justify-center">
                    <Link
                        to="/products"
                        className={`text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-fit text-center border border-1 border-base-accent-2`}
                    >
                       {ShopNowBtn}
                        <span>
                            <ArrowRight />
                        </span>
                    </Link>
                </div>
    </div>
  );
};

export default ProductsList;
