const addTranslations = (products, i18n) => {
  const translations = products.reduce((acc, product) => {
    if (!product || !product.translations || !Array.isArray(product.translations)) {
      return acc;
    }

   product.translations&& product?.translations.forEach((translation) => {
      if (!translation.languageCode || !translation.name) {
        return; // Skip invalid translation
      }

      if (!acc[translation.languageCode]) {
        acc[translation.languageCode] = {};
      }

      // Store the product name for each language, safely accessing the `id`
      acc[translation.languageCode][`productName-${product.id}`] = translation.name;
    });

    return acc;
  }, {});

  // Add the translations to i18next
  Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, 'product', translations[lang], true, true);
  });
};

export default addTranslations;
