import React, { useState, useEffect, useRef } from 'react';
import getToken from '../../utils/getToken';
import { Toast } from 'primereact/toast';
import { showInfo } from '../../utils/toastUtils';
import AddressList from './AddressList';
import AddressForm from './AddAdressForm';
import Loader from '../Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../services/apiServices';
import paypal from '../../assets/images/paypal.svg'
import { useNavigate } from 'react-router-dom';

function AddressSelectionPage({ type = 'default' }) {
  const [clickDelete, setClickDelete] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [user, setUser] = useState([]);

  const [payUrl,setPayUrl]=useState('')
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const [showSubmitButton, setShowSubmitButton] = useState(false); // New state for controlling submit button visibility
  const token = getToken();
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const toast = useRef(null);
  const [userId,setUserId]=useState('')
  const {totalCost}=useSelector(state=>state.cart)
  const [isLoadingPay, setIsLoadingPay] = useState(false);
  const [isOrder,setIsOrder]=useState(false);
  const cancelUrl = `${process.env.REACT_APP_REDIRECT_URL}/payment/failed`;
  const successUrl = `${process.env.REACT_APP_REDIRECT_URL}/payment/success`;
  useEffect(() => {
    const fetchAddresses = async () => {
      setIsLoadingFetch(true);
      try {
        const response = await api.get(`auth/user-info?token=${token}`);
        const fetchedAddresses = response.data.addresses;

        setAddresses(fetchedAddresses);
        setUser(response.data);
        if (fetchedAddresses.length > 0 && !selectedAddress) {
          setSelectedAddress(fetchedAddresses[0].id);
        }
      } catch (error) {
        // Handle error if needed
      } finally {
        setIsLoadingFetch(false);
      }
    };

    fetchAddresses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  // Validate Postal Code before adding
  const validatePostalCode = async (postalCode) => {
    try {
      const response = await api.get(`locations/${postalCode}`);
      return true; // If valid, return true
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showInfo(toast, 'error', 'Error', 'Invalid postal code. Please enter a valid one.');
        return false;
      } else {
        showInfo(toast, 'error', 'Error', 'An error occurred while validating the postal code. Please try again.');
        return false;
      }
    }
  };

  const handleAddAddress = async () => {
    // Check if all fields are filled
    const { street, city, state, postalCode, country } = newAddress;
    if (!street || !city || !state || !postalCode || !country) {
      showInfo(toast, 'error', 'Error', 'Please fill in all the fields before adding an address.');
      return;
    }
  
    if (addresses.length >= 3) {
      showInfo(toast, 'error', 'Error', 'You can only have up to 3 addresses.');
      return;
    }
  
    const isPostalValid = await validatePostalCode(newAddress.postalCode);
    if (!isPostalValid) {
      return;
    }
  
    try {
      const response = await api.post(
        `addresses`,
        newAddress,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setAddresses([...addresses, response.data]); // Add new address to list
      setSelectedAddress(response.data.id); // Automatically select the new address
      setIsAddingNew(false); // Close the form
      setNewAddress({ street: '', city: '', state: '', postalCode: '', country: '' }); // Reset form
    } catch (error) {
      // Handle error if needed
    }
  };
  

  const handleDeleteAddress = async (id) => {
    if (addresses.length <= 1) {
      showInfo(toast, 'error', 'Error', 'You must have at least one address.');
      return;
    }
    setClickDelete(true);
    try {
      await api.delete(`addresses/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClickDelete(false);
      const updatedAddresses = addresses.filter((address) => address.id !== id);
      setAddresses(updatedAddresses);
      if (id === selectedAddress && updatedAddresses.length > 0) {
        setSelectedAddress(updatedAddresses[0].id);
      }
    } catch (error) {
      // Handle error if needed
    }
  };

  

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    
    setShowSubmitButton(isChecked);
  
    if (isChecked) {
      await handleSubmit();
    }
  };
  
  const handleSubmit = async () => {
    const orderId=localStorage.getItem('orderId')
    setIsOrder(false )
  

    if (!selectedAddress) {
      showInfo(toast, 'error', 'Error', 'You must select an address to submit.');
      return;
    }
    try{
      const userResponse = await api.get(`auth/user-info?token=${token}`);
      setUserId(userResponse.data.id)
      if (!orderId){
        try{   const orderResponse = await api.post(
             `orders/user/${userResponse.data.id}`,
             { addressId: +selectedAddress },
             {
               headers: {
                 Authorization: `Bearer ${token}`,
               },
             }
           );
            localStorage.setItem('orderId',orderResponse.data.id);
           setIsOrder(true)
         }
         catch(err)
         {
           setIsOrder(false)
         }
       }
       else {
         try{ 
            const orderResponse = await api.get(
           `orders/search?orderId=${orderId}`,
           
           {
             headers: {
               Authorization: `Bearer ${token}`,
             },
           }
         );
         if (orderResponse.data.id)
          localStorage.setItem('orderId',orderResponse.data.id);
          setIsOrder(true)
          
         
       }
       catch(err)
       {
       }
       }

    }
    catch(err){

    }
   
  
   setIsLoadingPay(true)
  

    if ( localStorage.getItem('orderId')) {
      
      const formattedTotalCost = Number(parseFloat(totalCost).toFixed(2)) 
    try {

        const paypalResponse = await api.post(
            `paypal/pay?total=${formattedTotalCost}&currency=EUR&method=paypal&intent=sale&description=test&cancelUrl=${cancelUrl}&successUrl=${successUrl}`,
            {addressId: +selectedAddress  },
            {
              headers:{
                Authorization: `Bearer ${token}`,
              },
        }
        );
        const paypalUrl = paypalResponse.data;
       
        setIsLoadingPay(false)
        setPayUrl(paypalUrl)
      } catch (error) {
        
        showInfo(toast, 'error', 'Error', 'Failed to submit order.');
      }
        
    }
  };
  const handlePaypalUrl=()=>{
    

    if (payUrl&& localStorage.getItem('orderId')) {
      showInfo(toast, 'success', 'Redirecting to PayPal...');
      window.location.href=payUrl

    } else {
      showInfo(toast, 'error', 'Error', 'Failed to retrieve PayPal URL.');
    }
    
  }
  
  if (isLoadingFetch) return <Loader />;

  return (
    <div className={` w-11/12 m-auto`}>
                        <Toast  position="top-center" className='w-11/12 z-50  m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />
      
      <h6 className={`text-xl w-3/4 text-base-accent-1 md:text-2xl ${type == 'pannel' && 'hidden'} my-5`}>
        Select or Add Address
      </h6>
      <div className="">
        {addresses.length > 0 &&   (
          <AddressList
            addresses={addresses}
            selectedAddress={selectedAddress}
            onSelect={setSelectedAddress}
            onDelete={handleDeleteAddress}
            disableDelete={clickDelete}
            type={type}
          />
        )}
      </div>
      {!isAddingNew && addresses.length < 3 && (
          <button
            onClick={() => setIsAddingNew(true)}
            className="flex items-center mt-5 gap-2 rounded-[50%] bg-gray-200 text-base-accent-1 px-4 py-2 hover:bg-gray-400 duration-300"
          >
            <span className="text-xl ">+</span>
          </button>
    )}

      {isAddingNew && (
        <AddressForm
          newAddress={newAddress}
          onChange={handleInputChange}
          onSave={handleAddAddress}
          onCancel={() => setIsAddingNew(false)}
        />
      )}
      <div className="my-5">
        {addresses.length > 0 &&  type!=='pannel' && (
          <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={showSubmitButton}
            onChange={handleCheckboxChange}  
            id="showSubmitOrder"
            class="w-3 h-3 text-base-accent-1  border-gray-300 rounded focus:text-base-accent-1   focus:ring-2 "

          />
          <p htmlFor="showSubmitOrder" className="text-sm flex gap-1 items-center">
            <img src={paypal} className="w-[30px]" alt="PayPal" /> <span>Paypal</span>
          </p>
        </div>
        
        )}
        {showSubmitButton && localStorage.getItem('orderId')  && (
          <button
           onClick={handlePaypalUrl}
            className={`bg-base-accent-1 mt-3 disabled:bg-gray-300 w-full md:w-fit duration-300 text-white px-6 py-2 rounded-md hover:bg-base-background-3 ${type === 'pannel' && 'hidden'}`}
            disabled={!isOrder||!selectedAddress || addresses.length === 0}
          >
             Order
          </button>
        )}
      </div>
    </div>
  );
}

export default AddressSelectionPage;
