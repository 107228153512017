import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import getToken from "../../utils/getToken";
import Loader from "../../components/Loader/Loader";
import formatPrice from "../../utils/formatPrice";
import { api } from "../../services/apiServices";
import { useTranslation } from "react-i18next";
import translationUserOrders from  '../../translations/userPannel/translationsUserOrders'; 
import moment from "moment";

function UserOrders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const { i18n } = useTranslation();
  const translations = translationUserOrders[i18n.language];

  useEffect(() => {
    const token = getToken();
    const getOrders = async () => {
      try {
        const res = await api.get(`orders/user/${user.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(res.data);
      } catch (err) {
        console.error("Error fetching orders", err);
      } finally {
        setLoading(false);
      }
    };
    if (user) getOrders();
  }, [user]);

  if (isLoading) return <Loader />;

  return (
    <div className="container mx-auto w-11/12 m-auto py-8 ">
      <h6 className="text-[15px] md:text-[25px] border-l-[10px] border-base-accent-1 text-base-accent-1 bg-gray-100 mb-4 p-4">
        {translations.title}
      </h6>

      {orders.length === 0 ? (
        <div className="text-center text-gray-600">
          <p className="text-lg">{translations.noOrders}</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead className="text-base-accent-1">
              <tr>
                <th className="border border-gray-300 px-4 py-2">{translations.orderNumber}</th>
                <th className="border border-gray-300 px-4 py-2">{translations.date}</th>
                <th className="border border-gray-300 px-4 py-2">{translations.total}</th>
                <th className="border border-gray-300 px-4 py-2">{translations.status}</th>
                <th className="border border-gray-300 px-4 py-2">{translations.firstItemImage}</th>
                <th className="border border-gray-300 px-4 py-2">{translations.details}</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2 text-[14px]">{order.id}</td>
                  <td className="border border-gray-300 px-4 py-2 text-[14px]">{moment(order.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                  <td className="border border-gray-300 px-4 py-2 text-[14px]">{formatPrice(order.totalPrice)}</td>
                  <td
                    className={`border border-gray-300 px-4 py-2 font-bold text-[14px] ${
                      order.status === "COMP" ? "text-green-500" : "text-yellow-500"
                    }`}
                  >
                    {order.deliveryStatus}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {order.orderItems.length > 0 && (
                      <img
                        src={order.orderItems[0].imageUrl}
                        alt={order.orderItems[0].name}
                        className="w-16 h-16 m-auto object-cover rounded"
                      />
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    <Link
                      to={`/orders/${order.id}`}
                      className="text-blue-500 text-[14px] hover:underline"
                    >
                      {translations.viewDetails}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default UserOrders;