import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useTranslation } from 'react-i18next';
import CategoryBox from './CategoryBox';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../../store/slices/categoriesSlice';
import addTranslations from '../../../utils/convertCategoriesToi18Format'; 
import Arrow from '../../../assets/icons/ArrowRight';

const Categories = () => {
    const { i18n, t } = useTranslation();
    const categories = useSelector((state) => state.categories.items);
    const status = useSelector((state) => state.categories.status);
    const dispatch = useDispatch();
    const { categoriesTitle, ViewAllBtn } = i18n.getDataByLanguage(i18n.language);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchCategories());
        }
    }, [status, dispatch]);

    useEffect(() => {
        if (categories.length > 0) {
            addTranslations(categories, i18n);
        }
    }, [categories, i18n]);

    return (
        <div className="flex justify-center  items-center w-full mt-12 mb-12">
            <div className="w-11/12 m-auto">
                <h6 className="text-[20px] m-auto w-11/12  text-center md:w-3/4 md:text-[30px] md:text-center text-base-accent-1 mb-5">
                    {categoriesTitle}
                </h6>

                {/* Centered Grid Container */}
                <div className='flex flex-col gap-8 md:flex-row flex-wrap justify-center'>
                    {categories.length > 0 && categories.map((category) => (
                        <Link key={category.id} to={`/categories/${category.id}`}>
                            <CategoryBox
                                src={category.categoryImage?.imageUrl}
                                name={t(`category:categoryName-${category.id}`, { defaultValue: category.translations[0]?.name })}
                            />
                        </Link>
                    ))}
                </div>

                <div className="mt-10 lg:mt-16 flex justify-center ">
                    <Link
                        to="/categories"
                        className={`text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-fit text-center border border-1 border-base-accent-2`}
                    >
                        {ViewAllBtn}
                        <span>
                            <Arrow />
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Categories;
