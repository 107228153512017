import React from 'react';
import AddressItem from './AddressItem';
import { useTranslation } from 'react-i18next';

const AddressList = ({ addresses, selectedAddress, onSelect, onDelete, disableDelete, type }) =>{
  const { i18n } = useTranslation();
  const {
    noAddress
  } = i18n.getDataByLanguage(i18n.language);
 
  if (addresses.length === 0) {
    return <p>{noAddress}</p>;
  }

  return (
    <div className="my-6 grid grid-cols-1 md:grid-cols-2 gap-5 ">
      {addresses.map((address) => (
        <AddressItem
          key={address.id}
          address={address}
          isSelected={selectedAddress === address.id}
          onSelect={() => onSelect(address.id)}
          onDelete={() => onDelete(address.id)}
          disableDelete={disableDelete}
          showRadio={type !== 'pannel'}
        />
      ))}
    </div>
  );
}

export default AddressList;
