
const translationsHeader = {
    en: {
      HomeMenu:'Home',
      ShopMenu:"Shop",
      AboutUsMenu:"About us",
      ContactUsMenu:'Contact us'
  
    },
    de: {
        HomeMenu:'Home',
        ShopMenu:"Shop",
        AboutUsMenu:"Über uns",
        ContactUsMenu:'Kontakt'
    
    }
  };
  
  export default translationsHeader;