import React, { useState } from 'react';

export default function CookieConsent() {
    // Check localStorage to see if the user has already given or rejected consent
    const [bannerVisible, setBannerVisible] = useState(() => {
        // If "cookieConsent" key is not set, show the banner
        return localStorage.getItem('cookieConsent') === null;
    });

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setBannerVisible(false);
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'false');
        setBannerVisible(false);
    };

    // If the user has already accepted/rejected cookies, don't render the banner
    if (!bannerVisible) {
        return null;
    }

    return (
        <div className="fixed bottom-0 w-full bg-gray-100 border-t border-gray-300 py-20 px-4 flex flex-col sm:flex-row items-center justify-between z-50">
            <p className="text-center sm:text-left text-sm text-base-background-2 mb-2 sm:mb-0">
                Wir verwenden Cookies, um Inhalte zu personalisieren und die Nutzung der Website zu analysieren.
                Indem Sie auf „OK“ klicken, stimmen Sie der Verwendung von Cookies zu.
            </p>
            <div className="flex space-x-2">
                <button
                    onClick={handleAccept}
                    className="px-8 py-2 text-sm font-medium text-white bg-base-accent-1 hover:bg-base-background-3  duration-300 rounded-[30px]"
                >
                    OK
                </button>
                <button
                    onClick={handleReject}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-[30px]"
                >
                    Ablehnen
                </button>
            </div>
        </div>
    );
}