
const translationTopProducts = {
    en: {
      topProductsTitle:'Premium handcrafted bags made from fabric and yarn',
    
  
    },
    de: {
        topProductsTitle:'Premium handgefertigte Taschen aus Stoff und Garn',

    
    }
  };
  
  export default translationTopProducts;