import React, { useState, useEffect } from 'react';
import getToken from '../../utils/getToken';
import { api } from '../../services/apiServices';

const EditCategoryModal = ({ category, onClose, setCategories }) => {
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    description: '',
    translations: [
      { id: 1, languageCode: 'en', name: '', description: '' },
      { id: 2, languageCode: 'de', name: '', description: '' },
    ],
    categoryImage: { id: 0, imageUrl: '' },
    active: true,
  });

  const [image, setImage] = useState(null); // To save the image file
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state for button disable

  useEffect(() => {

    if (category) {
      setFormData({
        id: category.id,
        name: category.name,
        description: category.description,
        translations: category.translations,
        categoryImage: category.categoryImage || { id: 0, imageUrl: '' },
        active: category.active,
      });
    }
  }, [category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTranslationChange = (lang, field, value) => {
    const updatedTranslations = formData.translations.map((translation) =>
      translation.languageCode === lang ? { ...translation, [field]: value } : translation
    );
    setFormData((prev) => ({
      ...prev,
      translations: updatedTranslations,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    const token = getToken();

    try {
      const response = await api.put(
        `product_categories/${category.id}`,
        {
          id: formData.id,
          name: formData.name,
          description: formData.description,
          translations: formData.translations,
          categoryImage: formData.categoryImage,
          active: formData.active,

        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    
      setFormData(response.data);
      setShowImageUpload(true); // Show the photo upload box after the text is successfully sent
    } catch (error) {
      
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleImageUpload = async (event) => {
    if (!image) return;
    setIsLoading(true); // Start loading
    const token = getToken();

    try {
      const formDat = new FormData();
      formDat.append('file', image);

      const response = await api.put(
        `category_images?categoryId=${category.id}`,
        formDat,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const uploadedImageUrl = response.data.imageUrl;
      setFormData((prev) => ({
        ...prev,
        categoryImage: { ...prev.categoryImage,
        imageUrl:uploadedImageUrl 
  }
      }));

      handleSubmit(event)

      setCategories((prev) =>
        prev.map((cat) =>
          cat.id === formData.id
            ? { ...formData, categoryImage: { imageUrl: uploadedImageUrl } }
            : cat
        )
      );

      onClose();
    } catch (error) {
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white h-[75%] overflow-y-scroll p-4 rounded shadow-lg w-96">
        <h5 className="text-xl font-bold mb-4">Edit Category</h5>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium">Category Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
              disabled={isLoading}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              required
              disabled={isLoading}
            />
          </div>

          <h2 className="text-lg font-semibold mt-4">English Translation</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium">Name</label>
            <input
              type="text"
              value={formData.translations.find((t) => t.languageCode === 'en')?.name || ''}
              onChange={(e) => handleTranslationChange('en', 'name', e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              disabled={isLoading}
            />
            <label className="block text-sm font-medium">Description</label>
            <textarea
              value={formData.translations.find((t) => t.languageCode === 'en')?.description || ''}
              onChange={(e) => handleTranslationChange('en', 'description', e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              disabled={isLoading}
            />
          </div>

          <h2 className="text-lg font-semibold mt-4">German Translation</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium">Name</label>
            <input
              type="text"
              value={formData.translations.find((t) => t.languageCode === 'de')?.name || ''}
              onChange={(e) => handleTranslationChange('de', 'name', e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              disabled={isLoading}
            />
            <label className="block text-sm font-medium">Description</label>
            <textarea
              value={formData.translations.find((t) => t.languageCode === 'de')?.description || ''}
              onChange={(e) => handleTranslationChange('de', 'description', e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              disabled={isLoading}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium">Active</label>
            <input
              type="checkbox"
              checked={formData.active}
              onChange={(e) => setFormData((prev) => ({ ...prev, active: e.target.checked }))}
              className="mt-1"
              disabled={isLoading}
            />
          </div>

          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="mr-2 text-gray-600" disabled={isLoading}>
              Cancel
            </button>
            <button type="submit" className="bg-base-text text-white p-2 rounded" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>

        {showImageUpload && (
          <div className="mt-4">
            <h5 className="text-lg font-bold mb-2">Upload Image</h5>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              className="mb-2 block w-full border border-gray-300 rounded-md p-2"
              disabled={isLoading}
            />
            <button
              onClick={(event)=>handleImageUpload(event)}
              className="bg-base-text text-white p-2 rounded"
              disabled={isLoading}
            >
              {isLoading ? 'Uploading...' : 'Upload Image'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCategoryModal;
