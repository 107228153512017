import React, { useState, useEffect } from 'react';
import Banner from '../components/banner/bannerShop';

import AboutusProducts from '../components/Aboutus/AboutusProducts';
import Training from '../components/Aboutus/Training';
import Meet from '../components/Aboutus/Meet';
function AboutUs() {
 

  
  return (
    <>
      <Banner title="Über uns" />
      <AboutusProducts />
      <Training />
      <Meet />
     
    </>
  );
}

export default AboutUs;
