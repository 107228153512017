const translationAddressForm = {
    en: {
      streetPlaceholder: 'Street',
      cityPlaceholder: 'City',
      statePlaceholder: 'State',
      postalCodePlaceholder: 'Postal Code',
      countryPlaceholder: 'Country',
      saveAddressBtn: 'Save Address',
      cancelBtn: 'Cancel',
    },
    de: {
      streetPlaceholder: 'Straße',
      cityPlaceholder: 'Stadt',
      statePlaceholder: 'Bundesland',
      postalCodePlaceholder: 'Postleitzahl',
      countryPlaceholder: 'Land',
      saveAddressBtn: 'Adresse speichern',
      cancelBtn: 'Abbrechen',
    }
  };
  
  export default translationAddressForm;