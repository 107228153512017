import React, { useState, useEffect } from 'react';
import BoxFilterDesktop from './BoxFiltersDesktop';
import BoxFilterMobile from './BoxFiltersMobile';

const ShopFilters = ({ filters, handleApplyFilters }) => {
  const [localFilters, setLocalFilters] = useState({ ...filters });

  useEffect(() => {
    setLocalFilters({ ...filters }); 
  }, [filters]);
  
  const handleFilterChange = (newFilters) => {
    setLocalFilters(newFilters);
    handleApplyFilters(newFilters);  // Apply filters to the parent component
  };

  return (
    <div className="flex relative md:mt-[6rem] h-full">
      <BoxFilterMobile
        localFilters={localFilters}
        setLocalFilters={setLocalFilters}
        handleApplyFilters={handleFilterChange}
      />
      <BoxFilterDesktop
        localFilters={localFilters}
        setLocalFilters={setLocalFilters}
        handleApplyFilters={handleFilterChange}
      />
    </div>
  );
};

export default ShopFilters;
