import React, { useState, useEffect, useRef } from 'react';
import getToken from '../../utils/getToken';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { showInfo } from '../../utils/toastUtils';
import Loader from '../../components/Loader/Loader';
import { api } from '../../services/apiServices';

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const toast = useRef(null);

  useEffect(() => {
    const token = getToken();

    const getProduct = async () => {
      try {
        const res = await api.get(`products/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProduct(res.data);
        setLoading(true);
      } catch (err) {
      }
    };

    const fetchCategories = async () => {
      try {
        const res = await api.get('product_categories', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(res.data);
      } catch (err) {
      }
    };

    if (id) {
      getProduct();
      fetchCategories();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate negative values for mainPrice and stockQuantity
    if ((name === 'mainPrice' || name === 'stockQuantity') && value < 0) {
      setError(`${name === 'mainPrice' ? 'Price' : 'Stock Quantity'} cannot be negative.`);
      return;
    }

    // Validate discount percentage
    if (name === 'discountPercentage') {
      const discountValue = parseFloat(value);
      if (discountValue < 0 || discountValue > 100) {
        setError('Discount must be between 0 and 100.');
        return;
      } else {
        setError('');
      }

      // Update the discount flag based on discountPercentage
      setProduct((prev) => ({
        ...prev,
        [name]: value,
        discount: discountValue > 0,
      }));
      return;
    }

    setError(''); // Clear error if validation passes
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleTranslationChange = (lang, field, value) => {
    const updatedTranslations = product.translations.map((translation) =>
      translation.languageCode === lang ? { ...translation, [field]: value } : translation
    );
    setProduct((prev) => ({ ...prev, translations: updatedTranslations }));
  };

  const handleStatusChange = () => {
    setProduct((prev) => ({ ...prev, active: !prev.active }));
  };

  const handleImageUpload = async () => {
    if (images.length === 0) return [];
    const token = getToken();

    try {
      const uploadPromises = images.map(async (image) => {
        const formData = new FormData();
        formData.append('file', image);

        const res = await api.post(
          'upload_image',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        return res.data.imageUrl; // Return uploaded image URL
      });

      return await Promise.all(uploadPromises);
    } catch (err) {
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();

    try {
      // Upload images and get URLs
      const uploadedImageUrls = await handleImageUpload();

      const updatedProduct = {
        ...product,
        images: uploadedImageUrls, // Add uploaded image URLs to the product
      };

      await api.put(
        `products/${product.id}`,
        updatedProduct,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      showInfo(toast, 'success', 'Success', 'The product was successfully updated!');
      navigate(`/admin/items/edit-images/${product.id}`);
    } catch (err) {
    }
  };

  const handleFileChange = (e) => {
    setImages([...e.target.files]);
  };

  if (!loading) return <Loader />;

  return (
    <div className="w-full h-full">
      <div className="max-w-2xl text-base-accent-1 mx-auto p-4">
      <Toast  position="top-center" className='w-11/12 z-50  m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />

        <h5 className="text-2xl font-bold mb-4">Edit Product</h5>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Translation sections */}
          {product?.translations.map((translation) => (
            <div key={translation.languageCode}>
              <h5 className="text-lg font-semibold mt-4">
                {translation.languageCode === 'en' ? 'English' : 'German'} Translation
              </h5>
              <label className="block text-sm font-medium">Name</label>
              <input
                type="text"
                value={translation.name}
                onChange={(e) =>
                  handleTranslationChange(translation.languageCode, 'name', e.target.value)
                }
                className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2 px-4"
              />
              <label className="block text-sm font-medium">Description</label>
              <textarea
                value={translation.description}
                onChange={(e) =>
                  handleTranslationChange(translation.languageCode, 'description', e.target.value)
                }
                className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2 px-4"
              />
            </div>
          ))}

          {/* Price */}
          <div>
            <label className="block text-sm font-medium">Price</label>
            <input
              type="number"
              name="mainPrice"
              value={product.mainPrice}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2 px-4"
              required
            />
          </div>

          {/* Stock Quantity */}
          <div>
            <label className="block text-sm font-medium">Stock Quantity</label>
            <input
              type="number"
              name="stockQuantity"
              value={product.stockQuantity}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2 px-4"
              required
            />
          </div>

          {/* Discount Percentage */}
          <div>
            <label className="block text-sm font-medium">Discount Percentage</label>
            <input
              type="number"
              name="discountPercentage"
              value={product.discountPercentage}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2 px-4"
            />
          </div>

          {/* Error Message */}
          {error && <p className="text-red-500">{error}</p>}

          {/* Product Category */}
          <div>
            <label className="block text-sm font-medium">Product Category</label>
            <select
              name="productCategoryId"
              value={product.productCategoryId}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2"
              required
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          {/* Active Status */}
          <div>
            <label className="block text-sm font-medium">Active Status</label>
            <div className="flex items-center mt-1">
              <input
                type="checkbox"
                checked={product.active}
                onChange={handleStatusChange}
                className="mr-2"
              />
              <span>{product.active ? 'Active' : 'Inactive'}</span>
            </div>
          </div>

          <button
            type="submit"
            className={`mt-4 bg-base-text text-white p-2 px-4 rounded-[30px] ${
              error ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={!!error}
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
