
const translationsSuccess = {
    en: {
        PaymentSuccessful:"Payment Successful!",
        proccessOrder:'Processing your order, please wait...',
        proccessSuccessFully:'Your payment was processed successfully. Thank you for your purchase.',
        viewOrderPayment:'View Orders',
        goToHomePayment:'Go to Home',
     
  
    },
    de: {
        PaymentSuccessful:'Zahlung erfolgreich!',
        proccessOrder:"Ihre Bestellung wird bearbeitet, bitte warten …",
        proccessSuccessFully:"Ihre Zahlung wurde erfolgreich verarbeitet. Vielen Dank für Ihren Einkauf.",
        viewOrderPayment:'Bestellungen anzeigen',
        goToHomePayment:'Gehe zur Startseite',

    
    }
  };
  
  export default translationsSuccess;