import { useTranslation } from "react-i18next";

const AddToCartButton = ({product, handleAddToCart, isLoading, quantity }) => {
  const { i18n } = useTranslation();
  const { 
   
    SoldOutBtn,
    AddToCartBtn
  } = i18n.getDataByLanguage(i18n.language)
  if (quantity > 0) return null; 
  return (
  <>
  {product.active==false || product.stockQuantity==0?
    <button onClick={()=>handleAddToCart(product)} disabled={true} className="p-10 hover:bg-white disabled:bg-gray-300 disabled: hover:text-base-accent-1 bg-gray-300 text-white duration-300 py-2  cursor-not-allowed rounded-[30px]">
    {SoldOutBtn}
  </button>:
    <button onClick={()=>handleAddToCart(product)} disabled={isLoading} className="p-10 hover:bg-white hover:text-base-accent-1 bg-base-accent-1 text-white duration-300 py-2 border border-base-accent-1 rounded-[30px]">
    {isLoading ? "Adding..." : <p>{AddToCartBtn}</p>}
  </button>

  }
  </>
  );
};

export default AddToCartButton;
