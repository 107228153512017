import { useSelector } from "react-redux";
import Minus from "../../../assets/icons/Minus";
import Plus from "../../../assets/icons/Plus";

const QuantityControl = ({ product, quantity, handleIncrease, handleDecrease }) => {
  const isLoading = useSelector((state) => state.cart.isLoading);

  const handleIncreaseWithStockCheck = (product) => {
    // Allow increase only if the current quantity is less than the stock quantity
    if (quantity < product.stockQuantity) {
      handleIncrease(product);
    }
  };

  return (
    <div className="border w-fit border-base-accent-1 flex gap-5 items-center rounded-[30px] relative">
      <button
        onClick={() => handleDecrease(product)} // Pass the current product to the decrease handler
        disabled={isLoading} // Disable the button when loading
        className={`md:hover:bg-base-accent-1 p-1 px-3 md:p-3 w-full h-full rounded-l-[30px] duration-300 text-[30px] ${
          isLoading ? 'text-[rgba(255,255,255,0.5)] bg-base-accent-1/50 cursor-not-allowed' : ''
        }`}
      >
        <Minus />
      </button>
      <p className="py-1 md:py-2">{quantity}</p>
      <button
        onClick={() => handleIncreaseWithStockCheck(product)} // Pass the current product to the stock-checked increase handler
        disabled={
          isLoading || quantity >= product.stockQuantity
        } // Disable the button if loading or quantity exceeds stock
        className={`md:hover:bg-base-accent-1 p-1 px-3 md:p-3 w-full h-full rounded-r-[30px] duration-300 text-[30px] ${
          isLoading
            ? 'text-[rgba(255,255,255,0.5)] bg-base-accent-1/50 cursor-not-allowed'
            : ''
        } ${quantity >= product.stockQuantity ? 'bg-gray-300 p-3 cursor-not-allowed' : ''}`}
      >
        <Plus />
      </button>

      {isLoading && (
        // Display a loading spinner when isLoading is true
        <div className="absolute right-[-40px] animate-spin w-6 h-6 border-4 border-t-4 border-base-accent-1 rounded-full"></div>
      )}
    </div>
  );
};

export default QuantityControl;
