
import imageSlider1 from '../../../assets/images/DALL·E 2024-11-11 17.08.21 - An elegant and artistic scene featuring a handmade patchwork bag with colorful fabric pieces, including floral prints and delicate lace. The bag is pl.webp';
import imageSlider2 from '../../../assets/images/masooreh.slide2.webp';
import imageSlider3 from '../../../assets/images/masooreh.silder1.webp';
import bgimageSlider1 from '../../../assets/images/slider2.png';
import bgimageSlider2 from  '../../../assets/images/slider2.png';

const translations = {
  en: {
    slides: [
      {
        title: "Pot Making Relieve Your Pressure",
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.",
        button: "shop now",
        bgImage:bgimageSlider1,
        image:imageSlider1,

      },
      {
        title: "Embrace The Pottery With New Passion",
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.",
        button: "shop now",
        bgImage:bgimageSlider2,
        image:imageSlider2,
      },
      {
        title: "Round Shape And Hand Painted Pots",
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.",
        button: "shop now",
        bgImage:bgimageSlider1,
        image:imageSlider3,
      }
    ]
  },
  de: {
    slides: [
      {
        title: "Topfherstellung baut Druck ab",
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.",
        button: "Jetzt kaufen",
        bgImage:bgimageSlider1,
        image:imageSlider1,
      },
      {
        title: "Entdecken Sie die Töpferei mit neuer Leidenschaft",
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.",
        button: "Jetzt kaufen",
        bgImage:bgimageSlider2,
        image:imageSlider2,
      },
      {
        title: "Runde Form und handbemalte Töpfe",
        description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.",
        button: "Jetzt kaufen",
        bgImage:bgimageSlider2,
        image:imageSlider3,
      }
    ]
  }
};

export default translations;