import React from 'react';
import DesktopHeader from '../components/header/TopMenu/DesktopHeader';
import BottomMenu from '../components/header/BottomMenu';
import { useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const isAdminPanel = location.pathname.startsWith('/admin');

  return (
    <div className="relative bg-white">
      {isAdminPanel ? (
        <div className="flex">
          
          <div className="w-3/4">
            <DesktopHeader />
          </div>
        </div>
      ) : (
        <>
          <DesktopHeader />
          <BottomMenu />
        </>
      )}
    </div>
  );
}

export default Header;
