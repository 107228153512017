const translationsUserVerticalMenu = {
    en: {
        UserHomePage: "Home Page",
        UserAccount: "Account",
        UserOrders: "Orders",
    },
    de: {
        UserHomePage: "Startseite",
        UserAccount: "Konto",
        UserOrders: "Bestellungen",
    }
};

export default translationsUserVerticalMenu;
