const translationsImpressum = {
    en: {
      titleImpressum:"this is title"
    
    },
    de: {
        titleImpressum:"this is test"
      
    }
  };
  
  export default translationsImpressum;
  