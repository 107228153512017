const translations = {
    en: {
      timelessBeauty: "Timeless Beauty, Enduring Quality",
      classyPottery: "Traditional craftsmanship in fabric and yarn, combining timeless beauty and quality.",
      discountOffer: "",
    
    },
    de: {
      timelessBeauty: "Zeitlose Schönheit, Langanhaltende Qualität",
      classyPottery: "Traditionelles Handwerk aus Stoff und Garn, das zeitlose Schönheit und Qualität vereint",
      discountOffer: "",
      
    }
  };
  
  export default translations;
  