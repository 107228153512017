import React, { useState, useEffect } from 'react';
import getToken from '../../utils/getToken';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services/apiServices';

const AddProduct = () => {
  const [isPost,setIsPost]=useState(false)
  const [product, setProduct] = useState({
    id: 0,
    mainPrice: 0,
    stockQuantity: 0,
    productCategoryId: 0,
    images: [],
    active: true,
    translations: [
      { id: 1, languageCode: 'en', name: '', description: '' }, // English
      { id: 2, languageCode: 'de', name: '', description: '' }  // German
    ],
    discountPercentage: 0,
    discount: false, // Discount flag, initially set to false
  });

  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({ mainPrice: '', stockQuantity: '', discountPercentage: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const token = getToken();
      try {
        const response = await api.get('product_categories', {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setCategories(response.data);
      } catch (error) {
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => {
      const updatedProduct = { ...prev, [name]: value };

      // If the discountPercentage changes, check if it is greater than 0 and set discount to true
      if (name === 'discountPercentage') {
        updatedProduct.discount = parseFloat(value) > 0;
      }

      return updatedProduct;
    });

    handleValidation(name, parseFloat(value)); // Trigger validation on change
  };

  const handleValidation = (name, value) => {
    if (name === 'mainPrice' && value < 0) {
      setErrors((prevErrors) => ({ ...prevErrors, mainPrice: 'Price cannot be negative.' }));
    } else if (name === 'stockQuantity' && value < 0) {
      setErrors((prevErrors) => ({ ...prevErrors, stockQuantity: 'Stock quantity cannot be negative.' }));
    } else if (name === 'discountPercentage' && (value < 0 || value > 100)) {
      setErrors((prevErrors) => ({ ...prevErrors, discountPercentage: 'Discount must be between 0 and 100.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    handleValidation(name, parseFloat(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prevent submission if there are validation errors
    if (errors.mainPrice || errors.stockQuantity || errors.discountPercentage) {
      alert('Please fix the errors before submitting the form.');
      return;
    }

    const token = getToken();
    setIsPost(true)

    try {
      const res = await api.post('products', product, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setIsPost(false)
      navigate(`/admin/add-product/product-images/${res.data.id}`);
    } catch (err) {
      setIsPost(false)

    }
  };

  return (
    <div className='w-full h-full'>
      <div className="w-11/12 text-base-accent-1 mx-auto p-4">
        <h5 className="text-2xl font-semibold mb-4 text-center md:text-left">Add New Product</h5>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Translation sections */}
          {product.translations.map((translation) => (
            <div key={translation.languageCode}>
              <h5 className="text-lg font-semibold mt-4">{translation.languageCode === 'en' ? 'English' : 'German'} Translation</h5>
              <label className="block mt-5 text-sm font-medium">Name</label>
              <input
                type="text"
                value={translation.name}
                onChange={(e) =>
                  setProduct((prev) => ({
                    ...prev,
                    translations: prev.translations.map((t) =>
                      t.languageCode === translation.languageCode ? { ...t, name: e.target.value } : t
                    ),
                  }))
                }
                className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2"
              />
              <label className="block text-sm mt-4 font-medium">Description</label>
              <textarea
                value={translation.description}
                onChange={(e) =>
                  setProduct((prev) => ({
                    ...prev,
                    translations: prev.translations.map((t) =>
                      t.languageCode === translation.languageCode
                        ? { ...t, description: e.target.value }
                        : t
                    ),
                  }))
                }
                className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2"
              />
            </div>
          ))}

          {/* Price */}
          <div>
            <label className="block text-sm font-medium">Price</label>
            <input
              type="number"
              name="mainPrice"
              value={product.mainPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2"
              required
            />
            {errors.mainPrice && <span className="text-red-500 text-sm">{errors.mainPrice}</span>}
          </div>

          {/* Stock Quantity */}
          <div>
            <label className="block text-sm font-medium">Stock Quantity</label>
            <input
              type="number"
              name="stockQuantity"
              value={product.stockQuantity}
              onChange={handleChange}
              onBlur={handleBlur}
              className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2"
              required
            />
            {errors.stockQuantity && <span className="text-red-500 text-sm">{errors.stockQuantity}</span>}
          </div>

          {/* Discount Percentage */}
          <div>
            <label className="block text-sm font-medium">Discount Percentage</label>
            <input
              type="number"
              name="discountPercentage"
              value={product.discountPercentage}
              onChange={handleChange}
              onBlur={handleBlur}
              className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2"
            />
            {errors.discountPercentage && <span className="text-red-500 text-sm">{errors.discountPercentage}</span>}
          </div>

          {/* Product Category */}
          <div>
            <label className="block text-sm font-medium">Product Category</label>
            <select
              name="productCategoryId"
              value={product.productCategoryId}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-[30px] p-2"
              required
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.translations?.[0]?.name || category.name}
                </option>
              ))}
            </select>
          </div>

          {/* Active Status */}
          <div>
            <label className="block text-sm font-medium">Status</label>
            <div className="flex items-center mt-1">
              <input
                type="radio"
                name="active"
                value={true}
                checked={product.active === true}
                onChange={() => setProduct((prev) => ({ ...prev, active: true }))}
                className="mr-2"
              />
              <span>Active</span>
              <input
                type="radio"
                name="active"
                value={false}
                checked={product.active === false}
                onChange={() => setProduct((prev) => ({ ...prev, active: false }))}
                className="ml-4 mr-2"
              />
              <span>Inactive</span>
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 bg-base-text text-white py-2 px-4 rounded-[30px]"
            disabled={setIsPost||!!errors.mainPrice || !!errors.stockQuantity || !!errors.discountPercentage}
          >
            Save Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
