const addProductTranslations = (products, i18n) => {
    // Group translations by language
    const translations = products.reduce((acc, product) => {
     product.translations &&  product?.translations.forEach((translation) => {
        if (!acc[translation.languageCode]) {
          acc[translation.languageCode] = {};
        }
        // Add product-specific translations
        acc[translation.languageCode][`productName-${product.id}`] = translation.name;
        acc[translation.languageCode][`productDescription-${product.id}`] = translation.description;
      });
  
      return acc;
    }, {});
  
   
  
  
    // Add translations to i18n under the "product" namespace
    Object.keys(translations).forEach((lang) => {
      i18n.addResourceBundle(lang, "product", translations[lang], true, true);
    });
  };
  
  export default addProductTranslations;
  