
const translationShopNow = {
    en: {
      
    ProductCategory: "Product Category",
    stockMessage: "Hurry! Only {{stock}} left in stock",
    outOfStock: "Out of stock",
    share: "Share",
    copied: "Copied!",
    linkCopied: " Link copied to clipboard!",
    viewCartDetails:"view cart"
    },
    de: {
        
            
      ProductCategory: "Produktkategorie",
            stockMessage: "Beeilung! Nur noch {{stock}} auf Lager",
            outOfStock: "Nicht auf Lager",
            share: "Teilen",
            copied: "Kopiert!",
            linkCopied: "Link in die Zwischenablage kopiert!",
            viewCartDetails:"Warenkorb ansehen"

          }
      
    }

  
  export default translationShopNow;