
const translationViewCart = {
    en: {
      ViewCartBtn:'View Cart',
    },
    de: {
        ViewCartBtn:'Warenkorb anzeigen',
    }
  };
  
  export default translationViewCart;