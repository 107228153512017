import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_IS_OPEN } from "../../store/slices/CartOpenSlice";
import Xlg from "../../assets/icons/Xlg";
import Features from "./Features/Features";
import CartProducts from "./CartProducts";
import { useTranslation } from "react-i18next";

function Cart({ handleIncrease, handleDecrease, setIsClosing }) {
  const { i18n } = useTranslation();
  const products = useSelector((state) => state.cart);
  const isOpen = useSelector((state) => state.cartOpen.isOpen);
  const dispatch = useDispatch();

  const [isAnimating, setIsAnimating] = useState(false);

  const { shoppingCartTitle, cartEmptyMessage } = i18n.getDataByLanguage(i18n.language);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true); // Start opening animation
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsAnimating(false); // Start closing animation
    setTimeout(() => {
      setIsClosing(false);
      dispatch(SET_IS_OPEN(false)); // Actually close the cart
    }, 100); // Animation duration
  };

  return (
    <>
      <div
  className={`relative top-0 right-0 h-[200px] w-full max-w-sm bg-white transition-transform duration-300 ease-in-out transform ${
    isOpen && isAnimating ? "translate-x-0" : "translate-x-full"
  }`}
>
  <div className="flex relative shadow-md text-base-accent-1 justify-between py-8 px-5">
    <h6>{shoppingCartTitle || "Shopping Cart"}</h6>
    <span
      className="font-bold hover:cursor-pointer shadow-b-2"
      onClick={handleClose}
    >
      <Xlg />
    </span>
  </div>
  <div className="cart-container flex flex-col h-full">
    {/* Cart Products Section */}
    <div className="cart-products flex-1 overflow-y-auto px-5">
      {products.products.length === 0 ? (
        <p className="mt-10">{cartEmptyMessage || "Your cart is empty."}</p>
      ) : (
        <CartProducts
          products={products}
          handleIncrease={handleIncrease}
          handleDecrease={handleDecrease}
        />
      )}
    </div>
  </div>
  <div className="cart-features py-5  mt-auto">
      <Features />
    </div>
</div>


    </>
  );
}

export default Cart;
