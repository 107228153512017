
const translationsBanner = {
    en: {
    signIn:"Sign In",
    userNameInput:"username Or Email*",
    password:"password*",
    forgotPass:"Forgot your password? ",
    signUp:'Sign up',
    isNotActive:'Your account is not active.'

    },
    de: {
     signIn:"Anmelden",
     userNameInput:"Benutzername oder E-Mail*",
     password:"Passwort*",
     forgotPass:"Passwort vergessen?",
     signUp:"Melden Sie sich an",
     isNotActive:'Ihr Konto ist nicht aktiv.'

    }
  };
  
  export default translationsBanner;