const translationsSkill3 = {
    en: {
        skillTitle: 'Embroidering patterns',
        skillDescription: 'Intricate embroidery adds detailed patterns, making each piece unique.',
    },
    de: {
        skillTitle: 'Sticken von Mustern',
        skillDescription: 'Mit kunstvoller Stickerei werden detaillierte Muster hinzugefügt, die jedes Stück einzigartig machen.',
    }
};

export default translationsSkill3;