import React from 'react'

function DeleteModal({text='',confirmDelete,cancelDelete}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded shadow-md">
        <p className="text-lg font-semibold text-base-accent-1">Confirm Deletion</p>
        <p className='text-base-accent-1'>Are you sure you want to delete this {text}?</p>
        <div className="mt-4">
            <button onClick={confirmDelete} className="bg-red-500 text-white px-4 py-2 rounded-[30px] mr-2">Yes</button>
            <button onClick={cancelDelete} className="bg-gray-300 text-black px-4 py-2 rounded-[30px]">No</button>
        </div>
    </div>
</div>
  )
}

export default DeleteModal