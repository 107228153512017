import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Home from '../../assets/icons/Home'
import Shop from "../../assets/icons/Shop";
import Person from '../../assets/icons/Person';
import ProfileButton from "../Button/ProfileButton";
import Account from "../../assets/icons/Account";
import { useTranslation } from "react-i18next";
function BottomMenu() {
  const [shopOpen, setShopOpen] = useState(false);
  const isOpen = useSelector(state => state.cartOpen.isOpen);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated); // وضعیت لاگین
  const { user } = useSelector(state => state.auth); 
  const { i18n } = useTranslation();
  const navigate=useNavigate()


  const {
    buttomMenuHome,
    buttomMenuShop,

    buttomMenuLogin,
    buttomMenuAccount
  } = i18n.getDataByLanguage(i18n.language);
  const handleAccountClick=()=>{
    if (user) {
// Check user role and redirect to the relevant panel

    if (user.roles[0].roleName === 'ROLE_ADMIN') {
      navigate('/admin/dashboard');
    } else if (user.roles[0].roleName === 'ROLE_USER') {
      
      navigate('/user/dashboard');
    }
  } else {
// If the user is not logged in, redirect to the login page
    navigate('/account/login');
  }
  }

  return (
    <div className={`fixed ${isOpen ? 'z-49' : 'z-50'} bottom-0 left-0 right-0 bg-white border-t md:hidden w-full overflow-hidden`}>
      <div className="flex justify-around p-2">
        {/* Home */}
        <Link to="/" className="text-gray-700 flex flex-col items-center justify-center">
          <span className="text-gray-700"><Home /></span>
          <span className="text-[14px]">{buttomMenuHome}</span>
        </Link>

        {/* Shop */}
        <Link 
          to="/products" 
          className="text-gray-700 flex flex-col items-center justify-center" 
        >
          <span><Shop /></span>
          <span className="text-[14px]">{buttomMenuShop}</span>
        </Link>

        {/* Account or Login */}
        {isAuthenticated ? (
          <>
          <button onClick={handleAccountClick}  className="text-gray-700 flex flex-col items-center justify-center">
            <span><Account /></span>
            <span className="text-xs">{buttomMenuAccount}</span>
          </button>
          </>
        ) : (
          <Link to="/account/login" className="text-gray-700 flex flex-col items-center justify-center">
            <span><Person /></span>
            <span className="text-[14px]">{buttomMenuLogin}</span>
          </Link>
        )}
      </div>
    </div>
  );
}

export default BottomMenu;
