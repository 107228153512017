import React from 'react'
import { Link } from 'react-router-dom'
import banner from "../../assets/images/masooreh.home6.webp"
import { useTranslation } from 'react-i18next';
function BannerAccount({title}) {
    const {i18n} = useTranslation();

  const {home} = i18n.getDataByLanguage(i18n.language);

  return (
    <div style={{backgroundImage: `url(${banner})`}} className="h-[200px] md:h-[300px] bg-gray-300/65 bg-center bg-blend-overlay flex items-center justify-center flex-col bg-cover md:bg-top">
            <h4 className="text-base-accent-1 text-[18px] md:text-[30px]">{title}</h4>
            <div className="flex gap-10 text-[18px] md:text-[20px]">
                <Link to='/'>{home}</Link>
                <li className="text-white text-[16px] md:text-[24px]">{title}</li>
            </div>
    </div>
  )
}

export default BannerAccount