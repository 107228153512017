import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/masoorehLogo.jpeg';
import Add from '../../assets/icons/Add';
import ListItems from '../../assets/icons/ListItems';
import Users from '../../assets/icons/Users';
import Cart from '../../assets/icons/Cart';
import Home from '../../assets/icons/Home';
import Setting from '../../assets/icons/Setting';
import List from '../../assets/icons/List';
import Contact from '../../assets/icons/Contact';
import Exit from '../../assets/icons/Exit';
import { useDispatch } from 'react-redux';
import {logout} from '../../store/slices/authSlice';

export default function VerticalMenu() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch=useDispatch();
  const navigate=useNavigate();
  // active class for links
  const getActiveClass = (path) =>
    location.pathname === path
      ? 'bg-[rgba(16,84,77,0.5)] text-white font-bold'
      : 'hover:bg-[rgba(16,84,77,0.2)]';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      // Disable body scroll when menu is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable body scroll when menu is closed
      document.body.style.overflow = 'auto';
    }

    // Cleanup to reset overflow style when component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
  const exitHandler=()=>{
    dispatch(logout());
    navigate('/')
  }

  return (
    <div className="relative">
      {/* Overlay background when menu is open, but not on the menu itself */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={closeMenu}
        ></div>
      )}

      <div className={`sticky h-full bg-white  md:border-r md:w-[300px] z-20`}>
        {/* Logo and mobile menu toggle */}
        <div className={`flex ${isOpen && 'hidden'} justify-between items-center p-4`}>
          <button
            className="md:hidden text-lg"
            onClick={toggleMenu}
          >
            {/* Hamburger Icon */}
            <span className="fixed"><List /></span>
          </button>
        </div>

        {/* Sidebar links */}
        <div 
          className={`${
            isOpen ? 'translate-x-0   opacity-100' : '-translate-x-full md:translate-x-0 md:opacity-100 opacity-0'
          } md:block divide-y text-base-accent-1 pt-5  flex flex-col absolute md:static bg-white w-64 md:w-auto z-30 h-[100vh] transition-all duration-500 ease-in-out`}
        >
          {/* Close button for the mobile menu */}
          <div className="flex justify-end px-4 py-2">
            <img src={logo} className="w-[150px] m-auto md:w-[200px]" alt="masooreh logo" />
          </div>

          {/* Menu Items */}
          <div >
            <Link
              to="/"
              className={`flex text-[14px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/')}`}
              onClick={closeMenu} // Close menu when clicking on an item
            >
              <span>
                <Home />
              </span>
              Home Page
            </Link>
            <Link
              to="/admin/dashboard"
              className={`flex text-[14px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/admin/dashboard')}`}
              onClick={closeMenu} // Close menu when clicking on an item
            >
              <span>
                <Setting />
              </span>
              Dashboard
            </Link>
            <Link
              to="/admin/add-product"
              className={`flex text-[14px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/admin/add-product')}`}
              onClick={closeMenu} // Close menu when clicking on an item
            >
              <span>
                <Add />
              </span>
              Add Product
            </Link>
            <Link
              to="/admin/add-category"
              className={`flex text-[14px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/admin/add-category')}`}
              onClick={closeMenu} // Close menu when clicking on an item
            >
              <span>
                <Add />
              </span>
              Add Category
            </Link>
            <Link
              to="/admin/products"
              className={`flex text-[14px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/admin/products')}`}
              onClick={closeMenu} // Close menu when clicking on an item
            >
              <span>
                <ListItems />
              </span>
              List Products
            </Link>
            <Link
              to="/admin/users"
              className={`flex text-[14px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/admin/users')}`}
              onClick={closeMenu} // Close menu when clicking on an item
            >
              <span>
                <Users />
              </span>
              Users
            </Link>
            <Link
              to="/admin/orders"
              className={`flex text-[14px] md:text-[16px] px-1 py-4 md:px-5 gap-2 items-center duration-300 ${getActiveClass('/admin/orders')}`}
              onClick={closeMenu} // Close menu when clicking on an item
            >
              <span>
                <Cart />
              </span>
              Orders
            </Link>
            <Link
              to="/admin/contact-messages"
              className={`flex text-[14px] md:text-[16px] px-1 py-4 md:px-5 gap-2 items-center duration-300 ${getActiveClass('/admin/contact-messages')}`}
              onClick={closeMenu} 
            >
              <span>
                <Contact />
              </span>
              Contact Us Messages
            </Link>
            <p
              className={`flex text-[14px] md:text-[16px] px-1 py-4 md:px-5 gap-2 items-center duration-300 ${getActiveClass('/')}`}
              onClick={exitHandler} 
            >
              <span>
                <Exit />
              </span>
              Exit
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}