import React, { useEffect } from 'react';
import formatPrice from '../../utils/formatPrice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import addProductTranslations from '../../utils/convertProductToi18Next';
import { useDispatch, useSelector } from 'react-redux';
import { INCREASE, DECREASE } from '../../store/slices/cartSlice';
import { syncCartWithServer } from '../../store/slices/cartSlice';
import QuantityControl from '../shopping/ProductDetails/QuantityControl';

function ShoppingCartCard({ product }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // وضعیت لاگین

  useEffect(() => {
    if (product) {
      addProductTranslations([product], i18n);
    }
  }, [product, i18n]);

  // توابع افزایش و کاهش مستقیم
  const handleIncrease = () => {
    dispatch(INCREASE({ id: product.id }));
    if (isAuthenticated) {
      dispatch(syncCartWithServer());
    }
  };

  const handleDecrease = () => {
    dispatch(DECREASE({ id: product.id }));
    if (isAuthenticated) {
      dispatch(syncCartWithServer());
    }
  };

  const productNameKey = `productName-${product.id}`;
  const productName = t(`product:${productNameKey}`, {
    defaultValue: product.translations.length
      ? product.translations[0].name
      : 'Unknown Product',
  });

  return (
    <>
      {/* Display for larger screens */}
      <tr key={product.id} className="hidden md:table-row border-b">
        <td className="py-5">
          <span className="flex gap-3">
            <img
              className="w-[100px] h-[100px] rounded-[3px]"
              src={product.images.length && product.images[0].imageUrl}
              alt={productName}
            />
            <span className="flex flex-col justify-center text-base-accent-1">
              <Link to={`/products/product-details/${product.id}`}>
                <p>{productName}</p>
              </Link>
              <p>{formatPrice(product.price)}</p>
            </span>
          </span>
        </td>
        <td className="text-center">
         <QuantityControl product={product} quantity={product.quantity} handleIncrease={handleIncrease} handleDecrease={handleDecrease} />
        </td>
        <td>{formatPrice(product.price * product.quantity)}</td>
      </tr>

      {/* Display for smaller screens */}
      <div className="block w-11/12 m-auto md:hidden py-4">
        <div className="flex flex-col gap-3">
          <img
            className="w-8/12 m-auto rounded-[3px]"
            src={product.images.length && product.images[0].imageUrl}
            alt={productName}
          />
          <div className="flex gap-5 flex-col items-center text-base-accent-1">
            <Link to={`/products/product-details/${product.id}`}>
              <p className="font-medium">{productName}</p>
            </Link>
            <p>{formatPrice(product.price)}</p>
          </div>
        </div>
        <div className="flex flex-col gap-3 items-center text-base-accent-2">
          <p>
            {t('Quantity')}: {product.quantity}
          </p>
          <td className="text-center">
         <QuantityControl product={product} quantity={product.quantity} handleIncrease={handleIncrease} handleDecrease={handleDecrease} />
        </td>
          <p>
            {t('Total')}: {formatPrice(product.price * product.quantity)}
          </p>
        </div>
      </div>
    </>
  );
}

export default ShoppingCartCard;
