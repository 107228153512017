import React, { useEffect, useState } from 'react';
import getToken from '../../utils/getToken';
import { Link } from 'react-router-dom';
import { api } from '../../services/apiServices';
import Loader from '../../components/Loader/Loader';

function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // صفحه فعلی
  const [totalPages, setTotalPages] = useState(0); // تعداد صفحات کل
  const [totalUsers, setTotalUsers] = useState(0); // تعداد کل کاربران
  const [pageSize] = useState(10); // تعداد کاربران در هر صفحه
  const token = getToken();

  // Fetch Users from API
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await api.get(`auth/users?pageNo=${currentPage}&pageSize=${pageSize}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.content); // کاربران در صفحه فعلی
        setTotalPages(response.data.totalPages); // تعداد صفحات کل
        setTotalUsers(response.data.totalElements); // تعداد کل کاربران
      } catch (error) {
        alert('Failed to fetch users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [token, currentPage, pageSize]);

  // Handlers for pagination
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="p-4 mb-4 w-11/12 m-auto">
      <h5 className="text-xl text-base-accent-1 font-bold mb-4">User List</h5>
      <table className="min-w-full text-base-accent-1 bg-white border border-gray-200 rounded-md">
        <thead className=' text-[16px]'>
          <tr>
            <th className="border-b px-4 py-2 text-left">Name</th>
            <th className="border-b px-4 py-2 text-left">Email</th>
            <th className="border-b px-4 py-2 text-left">Status</th>
            <th className="border-b px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody className=' text-[14px]'>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="border-b px-4 py-6">{`${user.name} ${user.familyName}`}</td>
              <td className="border-b px-4 py-6">{user.email}</td>
              <td className={`border-b px-4 py-6 ${user.active ? 'text-green-500' : 'text-red-500'}`}>
                {user.active ? 'Active' : 'Inactive'}
              </td>
              <td className="border-b px-4 py-6">
                <Link
                  to={`/admin/users/user-info/${user.id}`}
                  className="text-blue-500 hover:underline"
                >
                  View Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-6">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
          className="px-4 py-2 bg-gray-200 disabled:text-gray-300 rounded-lg"
        >
          Previous
        </button>
        <span>
          Page {currentPage + 1} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages - 1}
          className="px-4 py-2 bg-gray-200 disabled:text-gray-300 rounded-lg"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Users;
