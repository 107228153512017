import { Link } from 'react-router-dom';
import formatPrice from '../../utils/formatPrice';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';

function ProductshoppingCard({ product, cols = 3 }) {
  const { i18n } = useTranslation(); // i18n instance to handle translations
  
  return (
    <Link to={`/products/product-details/${product.id}`}>
      <div className={`${cols === 2 && "md:w-10/12"} mt-8 md:mt-0 relative group overflow-hidden`}>
        <LazyLoad offset={20}>
          <div className="relative">
            {/* Discount Badge */}
            {product.discount && (
              <div className={`absolute top-3 ${cols === 2 ? 'right-3' : 'right-6'} bg-red-500 text-white text-[12px] z-50 px-2 py-1 rounded-md shadow-md`}>
                {product.discountPercentage}%
              </div>
            )}

            {/* Main Product Image */}
              <img
                src={product.images.length ? product.images[0].imageUrl : '/default-image.jpg'}
                alt={i18n.t(`product:productName-${product.id}`, { defaultValue: product.translations[0]?.name })}
                className={`w-full rounded-card   ${
                  cols === 2 ? "lg:w-[400px] lg:h-[400px]" : "lg:w-[277px] lg:h-[277px]"
                }`}
              />
            
          </div>
        </LazyLoad>

        <div className="text-center flex mt-4 flex-col gap-1">
          <p className="text-base-accent-1 text-[18px] hover:text-base-background-3 duration-500">
            {i18n.t(`product:productName-${product.id}`, { defaultValue: product.translations[0]?.name })}
          </p>
          
          {product.discount ? (
            <div className="flex flex-col items-center gap-1">
              <div className="flex gap-2">
                <p className="text-[12px] line-through text-gray-400">
                  {formatPrice(product?.mainPrice)}
                </p>
              </div>
              <p className="text-[15px] text-left">
                {formatPrice(product?.price)}
              </p>
            </div>
          ) : (
            <p className="text-[16px]">
              {formatPrice(product?.price)}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
}

export default ProductshoppingCard;
