const translationsUserCart = {
    en: {
      UserShoppingCart: "Shopping Cart",
     UserContinueShopping: "Continue Shopping",
      subTotal: "Sub Total:",
      UserProduct: "Product", 
      UserQuantity: "Quantity", 
      UserTotal: "Total", 
      removeCart:'Remove Cart'

    },
    de: {
      UserShoppingCart: "Einkaufswagen",
      UserContinueShopping: "Weiter Einkaufen",
      subTotal: "Zwischensumme:",
      UserProduct: "Produkt", 
      UserQuantity: "Menge", 
      UserTotal: "Gesamt",
      removeCart:'Entfernen'
    },
  };
  
  export default translationsUserCart;
  