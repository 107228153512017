import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { api } from '../../services/apiServices';
import getToken from '../../utils/getToken';
import FailedPayment from '../../assets/images/failedPayment.svg';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader/Loader';


export function PaymentFailed() {
  const orderId = localStorage.getItem('orderId'); 
  const [isLoadingFetch, setIsLoadingFetch] = useState(true);
  
  const {i18n}=useTranslation()
    const {  PaymentFailed,
      proccessFailed,
      goToHomePayment,
      }=i18n.getDataByLanguage(i18n.language);
      
  useEffect(() => {
    const token = getToken();
    setIsLoadingFetch(true);

    const faildOrder = async () => {
      if (!orderId) {
        return;
      }

      try {
        await api.put(
          `orders/${orderId}/status?status=CANCELLED`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsLoadingFetch(false);

        localStorage.removeItem('orderId');
      } catch (error) {
        setIsLoadingFetch(false);

      }
    };

    faildOrder();
  }, [orderId]);

  return (
  <> {!isLoadingFetch?<Loader />: <div className="min-h-screen py-10 flex flex-col items-center justify-center bg-red-50 text-red-600">
      <div className="w-full items-center md:w-10/12 flex flex-col md:flex-row justify-between p-5 md:p-10 bg-red-50 md:bg-white md:border md:border-1 rounded-sm">
        <div className="w-full md:w-1/2 text-left">
          <h6 className="text-2xl font-semibold text-red-600 mb-4">{PaymentFailed}</h6>
          <p className="text-red-700 mb-6">
{proccessFailed}          </p>
          <div className="flex flex-col md:flex-row gap-4">
            
             
            <Link
              to="/"
              className="px-3 py-3 w-full md:w-fit text-center bg-red-100 text-red-800 rounded-[30px] hover:bg-red-200 transition duration-300"
            >
            {goToHomePayment}
            </Link>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <img src={FailedPayment} className="text-right mt-10 md:mt-0" alt="Payment Failed" />
        </div>
      </div>
    </div>
   }
   </>
  );
}
