// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.accessToken; // save token
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 7); 
      const expiryTime = expiryDate.getTime();
      localStorage.setItem('authToken', JSON.stringify({ token: state.token, expiry: expiryTime }));
    },
    setUser: (state, action) => {
      state.user = action.payload; //save user info
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;

      localStorage.removeItem("authToken");
      localStorage.removeItem("cartId");
      
      
      
    },
  },
});

export const { login, setUser, logout } = authSlice.actions;
export default authSlice.reducer;