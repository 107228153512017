const translationsSkill2 = {
    en: {
        skillTitle: 'Dyeing fabrics',
        skillDescription: 'Fabrics are dyed to achieve vibrant, lasting colors that make each piece unique.',
    },
    de: {
        skillTitle: 'Färben von Stoffen',
        skillDescription: 'Stoffe werden gefärbt, um lebendige, dauerhafte Farben zu erzielen, die jedes Stück einzigartig machen.',
    }
};

export default translationsSkill2;