import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from '../../components/shopping/pagination/Pagination';
import ShopFilters from '../../components/shopping/shopFilters/ShopFlters';
import Loader from '../../components/Loader/Loader';
import GridLayout from '../../components/shopping/GridLayout/GridLayout';
import BannerAccount from '../../components/banner/BannerAccount';
import { useTranslation } from 'react-i18next';
import addTranslations from '../../utils/convertProductsToi18nFormat'; // اضافه کردن تابع addTranslations
import { api } from '../../services/apiServices';

const ShopPage = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true); // Start with loading true
  const [filters, setFilters] = useState({
    minPrice: '',
    maxPrice: '',
    sortOrder: 'desc',
    search: '',
  });
  const [pageSize, setPageSize] = useState(10); 
  const [currentPage, setCurrentPage] = useState(0);
  const [translationsLoaded, setTranslationsLoaded] = useState(false); // New state to track translations
  const {i18n} =useTranslation()
  const {
    noProductsFound,
    ShopProducts,
    
  } = i18n.getDataByLanguage(i18n.language);
  const fetchProducts = async () => {
    
      setLoading(true);
    try {
      const params = {
        pageNo: currentPage,
        pageSize,
        sortBy: 'id',
        sortDir: filters.sortOrder,
        ...(filters.minPrice && { minPrice: filters.minPrice }),
        ...(filters.maxPrice && { maxPrice: filters.maxPrice }),
        ...(filters.search && { search: filters.search }),
      };
  
      const queryString = new URLSearchParams(params).toString();
      const response = await api.get(`products/category/${id}?${queryString}`);
  
      const data = response.data;
      addTranslations(data.content, i18n, 'product');
  
      const translatedProducts = data.content.map((product) => ({
        ...product,
        productName: i18n.t(`product:productName-${product.id}`, {
          defaultValue: product.name,
        }),
      }));
  
      setProducts(translatedProducts);
      setTotalPages(data.totalPages);
      setTranslationsLoaded(true); // Set translations as loaded
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // مدیریت تغییر صفحه
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // مدیریت اعمال فیلترها
  const handleApplyFilters = (newFilters) => {
    setFilters((prev) => ({
      ...prev, // حفظ فیلترهای قبلی
      ...newFilters, // اعمال فیلترهای جدید
    }));
    setCurrentPage(0); // بازگشت به صفحه اول
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage, filters, id, i18n.language]);

  // Show loading state if translations are not loaded yet
  if (loading || !translationsLoaded) {
    return <Loader />;
  }


  return (
    <div className="mb-16">
      <BannerAccount title={ShopProducts} />
      <div className="w-11/12 flex flex-col lg:flex-row gap-10 m-auto my-16">
        <div className="w-2/3 lg:w-1/4">
          <ShopFilters 
            filters={filters} 
            handleApplyFilters={handleApplyFilters} 
          />
        </div>

        {products.length ? (
          <div className="w-full lg:w-3/4">
            <GridLayout products={products} />
          </div>
        ) : (
          <div className="flex items-center w-full justify-center text-[20px] text-gray-400">
            <p>{noProductsFound}</p>
          </div>
        )}
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default ShopPage;
