
const translationsFooter = {
    en: {
      footerAccout:'Your Account',
      FooterImpressum:"Impressum",
      footerSupport:'Support',
      FooterAboutUs:"About us",
      FooterContactUs:'Contact us',
      footerText:'Duis aute irure dolor reprehenderit voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.'
  
    },
    de: {
      footerAccout:'your ',
        footerSupport:'Unterstützung',
        FooterImpressum:'Impressum',
        FooterAboutUs:"Über uns",
        FooterContactUs:'Kontakt',
        footerText:'Duis aute irure dolor reprehenderit voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.'
    
    }
  };
  
  export default translationsFooter;