const translationsFeatures = {
    en: {
      shipping: "Shipping",
      note: "Note",
      discount: "Discount",
      enterShippingAddress: "Enter shipping address",
      addANote: "Add a note",
      enterDiscountCode: "Enter discount code",
      subtotal: "Subtotal",
    },
    de: {
      shipping: "Versand",
      note: "Notiz",
      discount: "Rabatt",
      enterShippingAddress: "Geben Sie die Versandadresse ein",
      addANote: "Notiz hinzufügen",
      enterDiscountCode: "Geben Sie den Rabattcode ein",
      subtotal: "Zwischensumme",
    },
  };
    
  export default translationsFeatures;
  