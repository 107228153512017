
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import formatPrice from '../../../utils/formatPrice';
import Note from '../../../assets/icons/Note';
import Truck from '../../../assets/icons/Truck';
import Gift from '../../../assets/icons/Gift';
import ViewCartBtn from '../../cart/Buttons/ViewCartBtn';
import CheckOutBtn from '../../cart/Buttons/CheckOutBtn';
function Features() {
  const { totalCost } = useSelector((state) => state.cart);
  const { i18n } = useTranslation();
  
  
  const {
    shipping,
    note,
    discount,
    enterShippingAddress,
    addANote,
    enterDiscountCode,
    subtotal,
  } = i18n.getDataByLanguage(i18n.language);

  const [activeTab, setActiveTab] = useState(null);

  const handleTabChange = (tab) => {
    if (activeTab === tab) {
      setActiveTab(null);
    } else {
      setActiveTab(tab);
    }
  };

  const closeTab = () => {
    setActiveTab(null);
  };

  return (
    <div
      className={`relative w-full bg-white border-t p-5 ${
        activeTab ? "relative" : ""
      }`}
      style={{ zIndex: 999 }}
    >
      {/* Overlay */}
      {activeTab && (
        <div
          className="fixed inset-0 bg-white opacity-70 z-[998]"
          onClick={closeTab} 
        ></div>
      )}

      {/* TabBar */}
      <div className="flex items-center justify-between border-b mb-4 bg-white z-[999] relative">
        <div className="flex h-full gap-1 text-gray-500 flex-col items-center">
          <span className="pb-2"><Truck /></span>
          <button
            onClick={() => handleTabChange("shipping")}
            className={`text-center ${
              activeTab === "shipping"
                ? "border-b-2 border-base-accent-1 text-base-accent-1"
                : "text-gray-500"
            }`}
          >
            {shipping}
          </button>
        </div>
        <div className="flex gap-1 text-gray-500 flex-col items-center">
          <Note />
          <button
            onClick={() => handleTabChange("note")}
            className={`text-center py-2 ${
              activeTab === "note"
                ? "border-b-2 border-base-accent-1 text-base-accent-1"
                : "text-gray-500"
            }`}
          >
            {note}
          </button>
        </div>
        <div className="flex gap-1 text-gray-500 flex-col items-center">
          <Gift />
          <button
            onClick={() => handleTabChange("discount")}
            className={`text-center py-2 ${
              activeTab === "discount"
                ? "border-b-2 border-base-accent-1 text-base-accent-1"
                : "text-gray-500"
            }`}
          >
            {discount}
          </button>
        </div>
      </div>

      
      <div className="relative z-[999] transition-all duration-500 ease-in-out">
        {activeTab === "shipping" && (
          <div className="max-h-40 overflow-hidden p-3 border rounded-md mt-2 bg-white shadow-sm transition-all duration-500 ease-in-out">
            <div className="flex justify-between items-center">
              <button onClick={closeTab} className="text-gray-600">
                X
              </button>
            </div>
            <input
              type="text"
              placeholder={enterShippingAddress}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        )}

        {activeTab === "note" && (
          <div className="max-h-40 overflow-hidden p-3 border rounded-md mt-2 bg-white shadow-sm transition-all duration-500 ease-in-out">
            <div className="flex justify-between items-center">
              <button onClick={closeTab} className="text-gray-600">
                X
              </button>
            </div>
            <textarea
              placeholder={addANote}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        )}

        {activeTab === "discount" && (
          <div className="max-h-40 overflow-hidden p-3 border rounded-md mt-2 bg-white shadow-sm transition-all duration-500 ease-in-out">
            <div className="flex justify-between items-center">
              <button onClick={closeTab} className="text-gray-600">
                X
              </button>
            </div>
            <input
              type="text"
              placeholder={enterDiscountCode}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        )}
      </div>

      {/*  total price */}
      <div
        className={`flex justify-between items-center mt-4 text-base-accent-1 bg-white ${
          activeTab ? "opacity-50 pointer-events-none" : ""
        }`}
      >
        <h4 className="font-semibold">{subtotal}</h4>
        <p className=""> {formatPrice(totalCost)}</p>
      </div>
      <ViewCartBtn />
      <CheckOutBtn />
    </div>
  );
}

export default Features;
