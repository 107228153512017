import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { api } from "../services/apiServices";

function ActivateAccount() {
  const { i18n } = useTranslation();
  const toast = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate for page redirection

  const {
    successActivation,
    errorActivation,
    invalidTokenMessage,
    activatingMessage,
    loginRedirectMessage, // Message for redirection to login page
  } = i18n.getDataByLanguage(i18n.language);

  const [isLoading, setIsLoading] = useState(true); // Loading state for activation process

  useEffect(() => {
    const activateAccount = async () => {
      const token = new URLSearchParams(window.location.search).get("token"); // Extract token from URL
      if (!token) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: invalidTokenMessage || "Invalid or missing activation token.",
          life: 3000,
        });
        setIsLoading(false);
        return;
      }

      try {
        // Send GET request to API for activation
        await api.get(`auth/activate/${token}`);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: successActivation || "Your account has been successfully activated.",
          life: 3000,
        });

        // Redirect user to the login page after a few seconds
        setTimeout(() => {
          navigate("/account/login");
        }, 3000); // Redirect after 3 seconds
      } catch (error) {
        const error_ =
          error.response?.data?.message || errorActivation || "An error occurred during activation.";
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error_,
          life: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    activateAccount(); // Call the activation function on component mount
  }, [navigate]); // Add navigate as a dependency

  return (
    <div className="text-green-600 w-11/12 md:3/4 lg:w-1/2 m-auto flex-flex-col gap-5 items-center text-center my-20 justify-center">
                        <Toast  position="top-center" className='w-11/12 z-50  m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />
                        {isLoading ? (
        <h6 className="text-[20px] md:text-[30px] font-semibold">
          {activatingMessage || "Activating your account, please wait..."}
        </h6>
      ) : (
        <h6 className="text-[20px] md:text-[30px] font-semibold">
          {successActivation || "Your account has been activated successfully."}
        </h6>
      )}
      {!isLoading && (
        <p className="text-gray-500 mt-4">
          {loginRedirectMessage || "You will be redirected to the login page shortly."}
        </p>
      )}
    </div>
  );
}

export default ActivateAccount;
