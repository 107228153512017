
const translationViewAll = {
    en: {
      ViewAllBtn:'View All',
    },
    de: {
        ViewAllBtn:'Alle anzeigen',
    }
  };
  
  export default translationViewAll;