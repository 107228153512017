import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductDetails from "./ProductDetailSection";
import AddToCartButton from "./AddToCartButton";
import QuantityControl from "./QuantityControl";
import Cart from "../../cart/Cart";
import { SET_IS_OPEN } from "../../../store/slices/CartOpenSlice";
import {
  ADD_TO_CART,
  INCREASE,
  DECREASE,
  REMOVE_ITEM,
  syncCartWithServer,
} from "../../../store/slices/cartSlice";
import { Link } from "react-router-dom";

const ProductDetailsSection = ({isCopied, productCategory,handleCopyLink,product }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.cartOpen);
  const products = useSelector((state) => state.cart.products);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [shareLink, setShareLink] = useState(''); // State to store the shareable link

  const quantity = products.find((p) => p.id === product.id)?.quantity || 0;
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);// Enable closing animation
    setTimeout(() => {
      dispatch(SET_IS_OPEN(false)); // Toggle open/closed state
      setIsClosing(false); // Reset status
    }, 500); 
  };

  const handleAddToCart = (product) => {
    dispatch(SET_IS_OPEN(true));
    dispatch(ADD_TO_CART(product));
    if (isAuthenticated) {
      dispatch(syncCartWithServer());
    }
  };

  const handleIncrease = (product) => {
    dispatch(INCREASE({ id: product.id })); 
    if (isAuthenticated) {
      dispatch(syncCartWithServer());
    }
  };
  
  const handleDecrease = (product) => {
    dispatch(DECREASE({ id: product.id })); 
    if (isAuthenticated) {
      dispatch(syncCartWithServer());
    }
  };
  
  
  
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div className="flex flex-col gap-5 w-full md:w-1/2 lg:w-7/12 lg:pr-10">
      <ProductDetails  isCopied={isCopied} productCategory={productCategory} handleCopyLink={handleCopyLink} product={product}  />
      <div className="flex md:flex-col lg:flex-row gap-5">
        {quantity > 0 ? (
          <QuantityControl
            product={product}
            quantity={quantity}
            handleIncrease={handleIncrease}
            handleDecrease={handleDecrease}
          />
        ) : (
          <AddToCartButton product={product} handleAddToCart={handleAddToCart} />
        )}
      </div>
     {isOpen && (
          <>
            {/* Overlay for dark background */}
            <div
              className="fixed inset-0 bg-[rgba(0,0,0,0.3)]  bg-opacity-50 z-10"
              onClick={handleClose} // This will allow the user to close the cart by clicking on the background
            ></div>
            {/* Sliding Cart */}
            <div
              className={`fixed top-0 right-0 h-full w-10/12 md:w-3/12 bg-white shadow-lg transition-transform duration-300 ease-in-out transform z-20 ${
                isClosing ? "translate-x-full" : "translate-x-0"
              }`}
            >
            <Cart
  products={products}
  handleIncrease={handleIncrease} 
  handleDecrease={handleDecrease} 
  setIsClosing={setIsClosing} 
  onClose={handleClose} 
/>

            </div>
          
          </>
        )}
     {/* <CheckoutButton />*/}
    </div>
  );
};

export default ProductDetailsSection;
