import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import getToken from '../../utils/getToken';
import formatPrice from '../../utils/formatPrice';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import {api} from "../../services/apiServices";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize] = useState(10);

  // Filters and sorting
  const [searchInput, setSearchInput] = useState('');
  const [minPriceInput, setMinPriceInput] = useState('');
  const [maxPriceInput, setMaxPriceInput] = useState('');
  const [filters, setFilters] = useState({ search: '', minPrice: '', maxPrice: '' });
  const [sortBy, setSortBy] = useState('id'); // Default sort field
  const [sortDir, setSortDir] = useState('asc'); // Default sort direction

  const navigate = useNavigate();
  const token = getToken();
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchOrders = async () => {
      try {
        const { search, minPrice, maxPrice } = filters;
        const response = await api.get(
          `orders?pageNo=${currentPage}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOrders(response.data.content);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        setError('Failed to fetch orders');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [currentPage, filters, sortBy, sortDir, token, pageSize]);

  const handleOrderClick = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  const applyFilters = () => {
    setFilters({
      search: searchInput,
      minPrice: minPriceInput,
      maxPrice: maxPriceInput,
    });
    setCurrentPage(0); // Reset to first page
  };

  const toggleSortDir = () => {
    setSortDir((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  if (loading) return <Loader />;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="w-11/12 m-auto">
      <h5 className="text-xl md:text-2xl text-base-accent-1 my-8 font-semibold text-center md:text-left mb-4">Orders</h5>

      {/* Filter Section */}
      <div className="mb-4 flex flex-col md:flex-row flex-wrap gap-4">
        <input
          type="text"
          placeholder="Search orders..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="border p-2 w-fit  rounded-[30px] text-[14px]"
        />
        <input
          type="text"
          placeholder="Min Price"
          value={minPriceInput}
          onChange={(e) => setMinPriceInput(e.target.value)}
          className="border p-2 w-fit rounded-[30px] text-[14px]"
        />
        <input
          type="text"
          placeholder="Max Price"
          value={maxPriceInput}
          onChange={(e) => setMaxPriceInput(e.target.value)}
          className="border p-2 w-fit rounded-[30px] text-[14px]"
        />
        
        <button
          onClick={applyFilters}
          className="bg-base-accent-1 w-fit text-white px-4 py-2 rounded-[30px] text-[14px]"
        >
          Apply Filters
        </button>
      </div>

      {/* Sorting Section */}
      <div className="my-8 flex  gap-4 w-fit  items-center">
        <label htmlFor="sortBy" className="text-[14px]">Sort by:</label>
        <select
          id="sortBy"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="border p-2 rounded-[30px] text-[14px]"
        >
          <option value="id">Order ID</option>
          <option value="totalPrice">Total Price</option>
          <option value="createdAt">Date</option>
        </select>
        <button
          onClick={toggleSortDir}
          className={`px-4 py-2 rounded-[30px] text-[14px] ${
            sortDir === 'asc' ? 'bg-base-accent-1 text-white' : 'bg-gray-600 text-white'
          }`}
        >
          {sortDir === 'asc' ? 'Ascending' : 'Descending'}
        </button>
      </div>

      {/* Table Section */}
      <div className="overflow-x-scroll">
        <table className="min-w-full text-[14px] md:text-[16px] bg-white border border-gray-200 rounded-md">
          <thead className="text-base-accent-1">
            <tr>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Order ID</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Date</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">First Order</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Total Price</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  <button
                    className="text-blue-500 hover:underline"
                    onClick={() => handleOrderClick(order.id)}
                  >
                    {order.id}
                  </button>
                </td>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  {moment(order.createdAt).format('YYYY-MM-DD HH:mm')}
                </td>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  {order.orderItems.length > 0 && (
                    <img
                      src={order.orderItems[0].imageUrl}
                      alt="First Product"
                      className="w-16 h-16 object-cover rounded-md"
                    />
                  )}
                </td>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">{formatPrice(order.totalPrice)}</td>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  <button
                    className="  text-[14px] text-base-accent-1   rounded-[30px] mr-2"
                    onClick={() => handleOrderClick(order.id)}
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center my-6 text-base md:text-lg">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
          className="px-4 py-2 bg-gray-200 rounded-lg"
        >
          Previous
        </button>
        <span>
          Page {currentPage + 1} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages - 1}
          className="px-4 py-2 bg-gray-200 rounded-lg"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default OrdersPage;
