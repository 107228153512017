import { createSlice } from "@reduxjs/toolkit";
import getCount from "../../utils/getCount";
import getTotalCost from "../../utils/getTotalCost";
import getToken from "../../utils/getToken";
import SetToStorage from "../../utils/SetToLocalStorage";
import { api } from "../../services/apiServices";

const initialState = {
  products: JSON.parse(localStorage.getItem("products") || "[]"),
  count: +JSON.parse(localStorage.getItem("count") || "[]"),
  totalCost: +JSON.parse(localStorage.getItem("totalCost") || "[]"),
  cartId: localStorage.getItem("cartId") || null,
  isLoading: false, // اضافه کردن وضعیت بارگذاری
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // شروع عملیات لودینگ
    SET_LOADING(state, action) {
      state.isLoading = action.payload;
    },
    ADD_TO_CART(state, action) {
      const existingProduct = state.products.find((p) => p.id === action.payload.id);
      if (existingProduct) {
        existingProduct.quantity++;
      } else {
        state.products.push({ ...action.payload, quantity: 1 });
      }
      state.count = getCount(state.products);
      state.totalCost = getTotalCost(state.products);
      SetToStorage(state.count, state.totalCost, state.products);
    },
    INCREASE(state, action) {
      const { id } = action.payload;
      const product = state.products.find((p) => p.id === id);
      if (product) {
        product.quantity++;
        state.count = getCount(state.products);
        state.totalCost = getTotalCost(state.products);
        SetToStorage(state.count, state.totalCost, state.products);
      }
    },
    DECREASE(state, action) {
      const { id } = action.payload; // دریافت id
      const productIndex = state.products.findIndex((p) => p.id === id);
      if (productIndex !== -1) {
        const product = state.products[productIndex];
        if (product.quantity > 1) {
          product.quantity--;
        } else {
          state.products.splice(productIndex, 1);
        }
        state.count = getCount(state.products);
        state.totalCost = getTotalCost(state.products);
        SetToStorage(state.count, state.totalCost, state.products);
      }
    },
    REMOVE_ITEM(state, action) {
      state.products = state.products.filter((p) => p.id !== action.payload.id);
      state.count = getCount(state.products);
      state.totalCost = getTotalCost(state.products);
      SetToStorage(state.count, state.totalCost, state.products);
    },
    SET_CART_ID(state, action) {
      state.cartId = action.payload;
      localStorage.setItem("cartId", action.payload);
    },
    RESET_CART(state) {
      state.products = [];
      state.count = 0;
      state.totalCost = 0;
      state.cartId = null;
      localStorage.removeItem("products");
      localStorage.removeItem("cartId");
      localStorage.removeItem("count");
      localStorage.removeItem("totalCost");
    },
    ADD_PRODUCTS_FROM_SERVER(state, action) {
      state.products = action.payload;
      state.count = getCount(state.products);
      state.totalCost = getTotalCost(state.products);
      SetToStorage(state.count, state.totalCost, state.products);
    },
  },
});

export const {
  ADD_TO_CART,
  INCREASE,
  DECREASE,
  REMOVE_ITEM,
  RESET_CART,
  SET_CART_ID,
  SET_LOADING,
  ADD_PRODUCTS_FROM_SERVER,
} = cartSlice.actions;

export const syncCartWithServer = () => async (dispatch, getState) => {
  const { products: localProducts, cartId } = getState().cart;
  const { isAuthenticated, user } = getState().auth; // وضعیت لاگین
  const token = getToken();

  if (isAuthenticated && user) {
    try {
      let currentCartId = cartId || localStorage.getItem("cartId") || null;

      if (!currentCartId && user.id)
        try {
          const getResponse = await api.get(`cart/user/${user.id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (getResponse.data?.id) {
            currentCartId = getResponse.data.id;
            dispatch(SET_CART_ID(currentCartId));
            localStorage.setItem("cartId", currentCartId);
            const serverCartItems = getResponse.data.cartItems || [];
            const serverProducts = serverCartItems.map((item) => ({
              id: item.product.id,
              name: item.product.name,
              description: item.product.description,
              price: item.product.price,
              mainPrice:item.product.mainPrice,
              stockQuantity: item.product.stockQuantity,
              productCategoryId: item.product.productCategoryId,
              productCategoryName: item.product.productCategoryName,
              images: item.product.images,
              translations: item.product.translations,
              active: item.product.active,
              quantity: item.quantity,
              discountPercentage: item.product.discountPercentage,
              discount: item.product.discount
            }));

            const combinedProducts = mergeProducts(localProducts, serverProducts);

            dispatch(ADD_PRODUCTS_FROM_SERVER(combinedProducts));
            SetToStorage(
              getCount(combinedProducts),
              getTotalCost(combinedProducts),
              combinedProducts
            );
          }
        } catch (error) {
          console.log(error)
          if (error.response && error.response.status === 404) {
            const defaultCart = {
              id: 0,
              product: { id: 0, name: "Default Product" },
              quantity: 0,
              price: 0,
            };
          
            try {
              const postResponse = await api.post(
                `cart/user/${user.id}`,
                defaultCart,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              console.log(postResponse)
          
              if (postResponse.data?.id) {
                currentCartId = postResponse.data.id;
                dispatch(SET_CART_ID(currentCartId));
                localStorage.setItem("cartId", currentCartId);
          
                if (localProducts.length > 0) {
                  await sendCartToServer(currentCartId, user.id, localProducts, token);
                }
              }
            } catch (postError) {
            }
          }
          
        }
      else {
        if (currentCartId)
          await sendCartToServer(currentCartId, user.id, localProducts, token);
      }
      dispatch(SET_LOADING(false));
    } catch (error) {
      dispatch(SET_LOADING(false));
    }
  }
};

const mergeProducts = (localProducts, serverProducts) => {
  const combinedProducts = [...localProducts];

  serverProducts.forEach((serverProduct) => {
    const existingProduct = combinedProducts.find(
      (localProduct) => localProduct.id === serverProduct.id
    );

    if (existingProduct) {
      existingProduct.quantity = Math.max(
        existingProduct.quantity,
        serverProduct.quantity
      );
    } else {
      combinedProducts.push(serverProduct);
    }
  });

  return combinedProducts;
};

const sendCartToServer = async (cartId, userId, products, token) => {
  if (userId) {
    try {
      const formattedCartItems = products.map((item) => ({
        id: item.id, 
        product: {
          id: item.id,
          name: item.name || "Product Name",
          description: item.description || "Product Description",
          mainPrice: item.mainPrice || 0, 
          price: item.price || 0,  
          stockQuantity: item.stockQuantity || 0,
          productCategoryId: item.productCategoryId || 0,
          productCategoryName: item.productCategoryName || "Category Name",
          images: item.images || [],
          translations: item.translations || [],
          active: item.active !== undefined ? item.active : true,
          discountPercentage: item.discountPercentage || 0,
          discount: item.discount !== undefined ? item.discount : false,
        },
        quantity: item.quantity || 0, // مقدار quantity از محصول.
        price:  item.price
      }));
      console.log(formattedCartItems)




      const body = {
        id: cartId,
        userId,
        cartItems: formattedCartItems,
      };

      const response = await api.put(
        `cart/user/${userId}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );

    } catch (error) {
      throw error;
    }
  }
};

export default cartSlice.reducer;
