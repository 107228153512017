import { useTranslation } from 'react-i18next';
import AddAddress from '../../components/checkOut/AddAddress'
import { useSelector } from "react-redux";

function UserPannel() {
  const { user } = useSelector((state) => state.auth);
  const { i18n } = useTranslation();
    const {accountDetails,userName,UserfamilyName,UserEmail,UserYourAddress}=i18n.getDataByLanguage(i18n.language); 

  return (
   <div> <div className="flex   pt-16 pb-8  ">
      <form className=" bg-white w-11/12 m-auto">
        <h6 className="text-[18px] md:text-[25px]  border-l-[10px] border-base-accent-1 text-base-accent-1 bg-gray-100 mb-4 p-4 ">
        {accountDetails}
        </h6>

       <div className="flex w-full flex-col  md:gap-5  mt-10">
          <div className="mb-4">
          
          
            <p className='text-gray-400 text-[14px] md:text-[16px]' ><strong  className='text-gray-700'>{userName} :  </strong>{user?.name || ""}</p>
            
        </div>

        <div className="mb-4">
          
        <p className='text-gray-400  text-[14px] md:text-[16px]'><strong className='text-gray-700' >{UserfamilyName} :  </strong>{user?.familyName || ""}</p>

          
            
        </div>
        <div className="mb-4">
        <p className='text-gray-400  text-[14px] md:text-[16px]'><strong className='text-gray-700' >{UserEmail} :  </strong>{ user?.email || ""}</p>

       
           
          
        </div>
        </div>

       

     
      </form>
      
      
      </div>
      <p className=' m-auto text-[16px] md:text-[20px] pt-10  text-base-accent-1 w-11/12 font-semibold'>{UserYourAddress} </p>
      <AddAddress type="pannel" />
    </div>
   
  );
}

export default UserPannel;
