
const translationsCategoriesSections = {
    en: {
      categoriesTitle:'Feel Empowered To Forge Your Unique Style And Establish Your Own Principles In Pottery'
  
    },
    de: {
        categoriesTitle:'Fühlen Sie sich ermutigt, Ihren einzigartigen Stil zu entwickeln und Ihre eigenen Prinzipien in der Töpferei zu etablieren'
    
    }
  };
  
  export default translationsCategoriesSections;