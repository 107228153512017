import React from 'react'
import { useTranslation } from 'react-i18next';

function Impressum() {
   const { i18n } = useTranslation();
  
    const {
      titleImpressum
    } = i18n.getDataByLanguage(i18n.language);
  
  return (
    <div className='w-11/12 m-auto py-10'>
        <p className='text-[14px] md:text-[16px] text-base-accent-1'>{titleImpressum}</p>
    </div>
  )
}

export default Impressum