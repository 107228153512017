const translationsCart = {
    en: {
      shoppingCartTitle: "Shopping Cart",
      cartEmptyMessage: "Your cart is empty.",
      
    },
    de: {
      shoppingCartTitle: "Einkaufswagen",
      cartEmptyMessage: "Ihr Einkaufswagen ist leer.",
   
    },
  };
  
  export default translationsCart;
  