import React from 'react'
import formatPrice from '../../utils/formatPrice'

function CheckOutProductBox({product}) {
  return (
    <div className='flex  justify-between items-center'>
                <div className='flex  items-center gap-3'>
                    <div className='relative w-fit'>
                    <img className='w-[70px]  rounded-[3px] h-[70px]' src={product.images.length && product.images[0].imageUrl} />
                    <span className='bg-gray-700 flex items-center justify-center w-[20px] h-[20px] absolute top-[-10px]  right-[-5px]  rounded-[50%] text-white '>{product.quantity}</span>
                    
                    </div>
                    <p className='text-[14px] md:text-[16px]'>{product.translations.length && product.translations[0].name}</p>
                </div>
                <p className='text-[14px] md:text-[16px]'>{formatPrice(product.price)}</p>
            </div>
  )
}

export default CheckOutProductBox