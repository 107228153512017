import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Filter from '../../../assets/icons/Filter';
import { useLocation, useNavigate } from 'react-router-dom';

const BoxFilterMobile = ({ filters = {}, handleApplyFilters }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // گرفتن فیلترها از URL
  const getFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      minPrice: searchParams.get('minPrice') || filters.minPrice || '',
      maxPrice: searchParams.get('maxPrice') || filters.maxPrice || '',
      sortOrder: searchParams.get('sortOrder') || filters.sortOrder || 'asc',
      search: searchParams.get('search') || filters.search || '',
    };
  };

  const [localFilters, setLocalFilters] = useState(getFiltersFromURL);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  // Loading state

  // Destructure translations from i18n
  const {
    filtersTitle,
    priceLabel,
    sortLabel,
    ascending,
    descending,
    searchPlaceholder,
    applyButton,
  } = i18n.getDataByLanguage(i18n.language);

  useEffect(() => {
    setLocalFilters(getFiltersFromURL());
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(localFilters));
  }, [localFilters]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // به‌روزرسانی پارامترهای URL
  const updateURL = (filters) => {
    const searchParams = new URLSearchParams();

    if (filters.minPrice) searchParams.set('minPrice', filters.minPrice);
    if (filters.maxPrice) searchParams.set('maxPrice', filters.maxPrice);
    if (filters.sortOrder) searchParams.set('sortOrder', filters.sortOrder);
    if (filters.search) searchParams.set('search', filters.search);

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  // مدیریت وضعیت بارگذاری
  useEffect(() => {
    if (i18n.isInitialized) {
      setIsLoading(false);  // Disable loading after translations are loaded
    }
  }, [i18n.isInitialized]);

  return (
    <div className="lg:hidden">
      {/* دکمه فیلتر */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex gap-2 items-center text-base-accent-1 py-2 relative"
      >
        <Filter />
        <span>{filtersTitle}</span>
      </button>
      <hr className="border-gray-300 my-2" />

      {/* نمایش بارگذاری */}
      {isLoading && (
        <div className="fixed inset-0 bg-white flex items-center justify-center z-50">
          <span>Loading...</span>
        </div>
      )}

      {/* پوشش (Overlay) */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsOpen(false)}
        ></div>
      )}

      {/* کشویی فیلتر (Filter Drawer) */}
      <div
        className={`fixed bg-white inset-y-0 right-0 transform transition-transform duration-300 z-50 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } w-3/4 md:w-1/2 p-4 shadow-md`}
      >
        <button
          onClick={() => setIsOpen(false)}
          className="text-base-accent-1 text-sm font-medium mb-4"
        >
          X
        </button>
        <h3 className="text-lg font-semibold mb-4">{filtersTitle}</h3>

        {/* فیلتر قیمت */}
        <div className="mb-6">
          <span className="text-sm font-medium">{priceLabel}</span>
          <div className="mt-2">
            <div className="flex space-x-2">
              <input
                type="number"
                name="minPrice"
                placeholder="Min"
                min="0"
                inputMode="numeric"
                value={localFilters.minPrice}
                onChange={handleInputChange}
                className="border outline-none border-gray-300 rounded p-2 w-full"
              />
              <input
                type="number"
                name="maxPrice"
                placeholder="Max"
                min="0"
                inputMode="numeric"
                value={localFilters.maxPrice}
                onChange={handleInputChange}
                className="border outline-none rounded p-2 w-full"
              />
            </div>
          </div>
        </div>

        {/* فیلتر مرتب‌سازی */}
        <div className="mb-6">
          <span className="text-sm font-medium">{sortLabel}</span>
          <div className="mt-2">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                name="sortOrder"
                value="asc"
                checked={localFilters.sortOrder === 'asc'}
                onChange={handleInputChange}
                className="cursor-pointer outline-none"
              />
              <span>{ascending}</span>
            </label>
            <label className="flex items-center space-x-2 mt-2">
              <input
                type="radio"
                name="sortOrder"
                value="desc"
                checked={localFilters.sortOrder === 'desc'}
                onChange={handleInputChange}
                className="cursor-pointer outline-none"
              />
              <span>{descending}</span>
            </label>
          </div>
        </div>

        {/* ورودی جستجو */}
        <div className="mb-6">
          <input
            type="text"
            name="search"
            placeholder={searchPlaceholder}
            value={localFilters.search}
            onChange={handleInputChange}
            className="border border-gray-300 outline-none rounded p-2 w-full"
          />
        </div>

        <button
          onClick={() => {
            handleApplyFilters(localFilters);
            setIsOpen(false);
            updateURL(localFilters); // Update the URL with current filters
          }}
          className="bg-base-accent-1 text-white w-fit py-2 px-4 rounded"
        >
          {applyButton}
        </button>
      </div>
    </div>
  );
};

export default BoxFilterMobile;
