import React from 'react';
import X_lg from '../../assets/icons/Xlg';
import { useTranslation } from 'react-i18next';

const AddressForm = ({ newAddress, onChange, onSave, onCancel }) => {
  const { i18n } = useTranslation();
    const {
      streetPlaceholder,
      cityPlaceholder,
      statePlaceholder,postalCodePlaceholder,
      countryPlaceholder,
      saveAddressBtn,cancelBtn
    } = i18n.getDataByLanguage(i18n.language);
  
  return (
    <div className="space-y-4 border p-4 rounded-md  mt-6">
      <span onClick={onCancel}>
        <X_lg />
      </span>
      <input
        type="text"
        name="street"
        value={newAddress.street}
        onChange={onChange}
        placeholder={streetPlaceholder}
        className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-base-accent-1/40"
      />
      <input
        type="text"
        name="city"
        value={newAddress.city}
        onChange={onChange}
        placeholder={cityPlaceholder}
        className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-base-accent-1/40"
      />
      <input
        type="text"
        name="state"
        value={newAddress.state}
        onChange={onChange}
        placeholder={statePlaceholder}
        className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-base-accent-1/40"
      />
      <input
        type="text"
        name="postalCode"
        value={newAddress.postalCode}
        onChange={onChange}
        placeholder={postalCodePlaceholder}
        className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-base-accent-1/40"
      />
      <input
        type="text"
        name="country"
        value={newAddress.country}
        onChange={onChange}
        placeholder={countryPlaceholder}
        className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-base-accent-1/40"
      />
      <button
        onClick={onSave}
        className="bg-base-accent-1 text-white px-4 py-2 rounded-md hover:bg-base-accent-1"
      >
       {saveAddressBtn}
      </button>
    </div>
  );
};

export default AddressForm;
