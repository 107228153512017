const translationsSkill = {
    en: {
      skillTitle: 'Weaving yarn',
      skillDescription: 'Threads are woven with precision to create unique designs that are both functional and stylish.',
    },
    de: {
      skillTitle: 'Weben von Garn',
      skillDescription: 'Fäden werden mit Präzision gewebt, um einzigartige Designs zu schaffen, die funktional und stilvoll sind.',
    }
  };
  
  export default translationsSkill;
  