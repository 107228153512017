const translationsTraining = {
    en: {
      trainingTitle: "Creative techniques of handcraft",
      trainingDescription: "Discover the craftsmanship behind our handcrafted bags, purses, and jewelry. Using precise techniques and high-quality materials, we create unique masterpieces that stand for quality and style.",
      trainingShopNow: "Shop Now",
    },
    de: {
      trainingTitle: "Kreative Techniken der Handarbeit",
      trainingDescription: "Entdecken Sie die Kunstfertigkeit hinter unseren handgefertigten Taschen, Geldbörsen und Schmuckstücken. Mit präzisen Techniken und hochwertigen Materialien schaffen wir einzigartige Meisterwerke, die für Qualität und Stil stehen.",
      trainingShopNow: "Jetzt einkaufen",
    },
  };
  
  export default translationsTraining;
  