const translationOrderDetails = {
    en: {
      title: 'Order Details',
      orderId: 'Order ID:',
      totalPrice: 'Total Price:',
      deliveryStatus: 'Delivery Status:',
      paymentStatus: 'Payment Status:',
      orderCreatedAt: 'Order Created At:',
      lastUpdatedAt: 'Last Updated At:',
      downloadInvoice: 'Download Invoice (PDF)',
      productId: 'Product ID',
      quantity: 'Quantity',
      price: 'Price',
      image: 'Image',
      changeDeliveryStatus: 'Change Delivery Status:',
      changePaymentStatus: 'Change Payment Status:',
      updateDeliveryStatus: 'Update Delivery Status',
      updatePaymentStatus: 'Update Payment Status',
      pending: 'PENDING',
      shipped: 'SHIPPED',
      inTransit: 'IN_TRANSIT',
      delivered: 'DELIVERED',
      failed: 'FAILED',
      completed: 'COMPLETED',
      cancelled: 'CANCELLED',
      fetchError: 'Failed to fetch order details',
      updateSuccess: 'Order status updated successfully',
      updateError: 'Failed to update order status',
    },
    de: {
      title: 'Bestelldetails',
      orderId: 'Bestellnummer:',
      totalPrice: 'Gesamtpreis:',
      deliveryStatus: 'Lieferstatus:',
      paymentStatus: 'Zahlungsstatus:',
      orderCreatedAt: 'Bestellung erstellt am:',
      lastUpdatedAt: 'Zuletzt aktualisiert am:',
      downloadInvoice: 'Rechnung herunterladen (PDF)',
      productId: 'Produkt-ID',
      quantity: 'Menge',
      price: 'Preis',
      image: 'Bild',
      changeDeliveryStatus: 'Lieferstatus ändern:',
      changePaymentStatus: 'Zahlungsstatus ändern:',
      updateDeliveryStatus: 'Lieferstatus aktualisieren',
      updatePaymentStatus: 'Zahlungsstatus aktualisieren',
      pending: 'WARTEND',
      shipped: 'VERSANDT',
      inTransit: 'UNTERWEGS',
      delivered: 'ZUGESTELLT',
      failed: 'FEHLGESCHLAGEN',
      completed: 'ABGESCHLOSSEN',
      cancelled: 'STORNIERT',
      fetchError: 'Fehler beim Abrufen der Bestelldetails',
      updateSuccess: 'Bestellstatus erfolgreich aktualisiert',
      updateError: 'Fehler beim Aktualisieren des Bestellstatus',
    }
  };
  
  export default translationOrderDetails;