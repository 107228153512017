const translationsAddressList = {
    en: {
      noAddress: "You don't have an address yet! Enter your address to place an order."
     
    },
    de: {
        noAddress: "Sie haben noch keine Adresse! Geben Sie Ihre Adresse ein um eine Bestellung aufzugeben.",
      
    }
  };
  
  export default translationsAddressList;