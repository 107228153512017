import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '../translations/mainpage/translationsSlides/sliderTranslations';
import translationHandmade from "../translations/mainpage/translationHandmade";
import translationsGridSlider from '../translations/mainpage/translatinGridSlider';
import translationsBanner from '../translations/Account/translationBanner';
import translationLogin from "../translations/Account/translationsLogin"
import translationsRegister from '../translations/Account/translationRegister';
import translationsForgotPassword from '../translations/Account/translationsForgotPassword';
import translationsResetPassword from '../translations/Account/translationsResetPssword';
import translationCaptch from '../translations/Account/translationCaptch';
import translationsCategoriesPage from '../translations/categories/translationCategoriesPage';
import translationsHeader from '../translations/Layout/translationsHeader';
import translationShopNow from '../translations/Buttons/translationShopNow'
import translationTopProducts from '../translations/mainpage/translationsTopProduct';
import translationsCategoriesSections from '../translations/mainpage/translationsSlides/translationsCategoriesSections';
import translationViewAll from '../translations/Buttons/translationViewAll';
import translationsServices from '../translations/mainpage/translationsServices';
import translationsSkill from '../translations/mainpage/translationsSkill';
import translationsFirstOrder from '../translations/mainpage/tramnslationsFirstOrderDiscount'
import translationsFilters from '../translations/shop/filters/translationsFilters';
import translationsProductDetails from '../translations/shop/productDetails/translationsProductDetails';
import translationSoldOut from '../translations/Buttons/translatinSoldOut';
import translationAddToCart from '../translations/Buttons/translationAddToCart';
import translationsCart from '../translations/cart/translationCart';
import translationsFeatures from '../translations/cart/translationFeatures';
import translationViewCart from '../translations/Buttons/translationViewCart';
import translationCheckOut from '../translations/Buttons/translationCheckOut';
import translationsUserCart from '../translations/cart/translationsUserCart';
import translationsTraining from '../translations/aboutUs/translationsTraining';
import translationsMeet from '../translations/aboutUs/translationsMeet';
import translationsAboutusProducts from '../translations/aboutUs/translationsProducts';
import translationsContactUs from '../translations/contactUs/translationsContactUs';
import translationsProfileButtons from '../translations/Account/transltionsProfileButton';
import translationsUserPannel from '../translations/userPannel/translationsUserPannel';
import translationsUserVerticalMenu from '../translations/userPannel/tranaltionsUserVerticalMenu';
import translationAddressForm from '../translations/userPannel/translationsAddressForm';
import translationsAddressList from '../translations/userPannel/translationsAddressList';
import translationUserOrders from '../translations/userPannel/translationsUserOrders';
import translationOrderDetails from '../translations/userPannel/translationsOrderDetails';
import translationsShopPage from '../translations/shop/translationsShopPage';
import translationsImpressum from '../translations/Impressum/Impressum';
import translationsFooter from '../translations/Layout/translatinsFooter';
import translationsSuccess from '../translations/payment/success';
import translationsbuttomMenu from '../translations/Layout/buttomMenu';
import translationsFailed from '../translations/payment/failed';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en:{
        ...translations.en,
        ...translationHandmade.en,
        ...translationsGridSlider.en,
        ...translationsBanner.en,
        ...translationLogin.en,
        ...translationsRegister.en,
        ...translationsForgotPassword.en,
        ...translationsResetPassword.en,
        ...translationsCategoriesPage.en,
        ...translationsHeader.en,
        ...translationCaptch.en,
        ...translationShopNow.en,
        ...translationTopProducts.en,
        ...translationsCategoriesSections.en,
        ...translationViewAll.en,
       ...translationsServices.en,
       ...translationsSkill.en,
       ...translationsFirstOrder.en,
       ...translationsFilters.en,
       ...translationsProductDetails.en,
       ...translationSoldOut.en,
       ...translationAddToCart.en,
       ...translationsCart.en,
       ...translationsFeatures.en,
       ...translationViewCart.en,
       ...translationCheckOut.en,
       ...translationsUserCart.en,
       ...translationsTraining.en,
       ...translationsMeet.en,
       ...translationsAboutusProducts.en,
       ...translationsContactUs.en,
       ...translationsProfileButtons.en,
       ...translationsUserPannel.en,
       ...translationsUserVerticalMenu.en,
       ...translationAddressForm.en,
       ...translationsAddressList.en,
       ...translationUserOrders.en,
       ...translationOrderDetails.en,
       ...translationsShopPage.en,
       ...translationsImpressum.en,
       ...translationsFooter.en,
       ...translationsSuccess.en,
       ...translationsbuttomMenu.en,
       ...translationsFailed.en
      },
      de:{
        ...translations.de,
        ...translationHandmade.de,
        ...translationsGridSlider.de,
        ...translationsBanner.de,
        ...translationLogin.de,
        ...translationsRegister.de,
        ...translationsForgotPassword.de,
        ...translationsResetPassword.de,
        ...translationsCategoriesPage.de,
        ...translationsHeader.de,
        ...translationCaptch.de,
        ...translationShopNow.de,
        ...translationTopProducts.de,
        ...translationsCategoriesSections.de,
        ...translationViewAll.de,
        ...translationsServices.de,
        ...translationsSkill.de,
        ...translationsFirstOrder.de,
        ...translationsFilters.de,
        ...translationsProductDetails.de,
        ...translationSoldOut.de,
        ...translationAddToCart.de,
        ...translationsCart.de,
        ...translationsFeatures.de,
        ...translationViewCart.de,
        ...translationCheckOut.de,
        ...translationsUserCart.de,
        ...translationsTraining.de,
        ...translationsMeet.de,
        ...translationsAboutusProducts.de,
        ...translationsContactUs.de,
        ...translationsProfileButtons.de,
        ...translationsUserPannel.de,
        ...translationsUserVerticalMenu.de,
        ...translationAddressForm.de,
        ...translationsAddressList.de,
        ...translationUserOrders.de,
        ...translationOrderDetails.de,
        ...translationsShopPage.de,
        ...translationsImpressum.de,
        ...translationsFooter.de,
        ...translationsSuccess.de,
        ...translationsbuttomMenu.de,
        ...translationsFailed.de
      }
    }
    ,
    lng: 'de', ///Default language
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
  }, (err, t) => {
    if (err) {
    } else {
    }
  });

export default i18n;