import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowRight from '../../../assets/icons/ArrowRight';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function CheckOutBtn() {
  const { i18n } = useTranslation();
  const { CheckOutBtn } = i18n.getDataByLanguage(i18n.language);

  const products = useSelector(state => state.cart.products);
  const { isAuthenticated } = useSelector(state => state.auth);
  const navigate = useNavigate();

  const clickHandler = () => {
    if (products.length === 0) {
      alert('Your cart is empty!');
    } else {
      if (isAuthenticated) {
        navigate('/checkOut');
      } else {
        navigate('/account/login', { state: { from: '/checkOut' } });
      }
    }
  };
  

  return (
    <button
      onClick={clickHandler}
      disabled={products.length === 0} 
      className={`text-base-accent-1 m-auto mt-7 bg-white 
      ${products.length === 0 ? 'cursor-not-allowed opacity-50' : 'hover:text-white hover:bg-base-accent-1'} 
      tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[1rem] py-[0.7rem] w-full text-center border border-1 border-base-accent-2`}
    >
      {CheckOutBtn}
      <span>
        <ArrowRight />
      </span>
    </button>
  );
}

export default CheckOutBtn;
