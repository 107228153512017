import React from 'react';
import discountBgColor from "../../assets/images/masooreh.home5.webp";
import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/icons/ArrowRight';
import { useTranslation } from 'react-i18next';

function FirstOrderDiscount() {
  const { i18n } = useTranslation();
  
 
  const {
    timelessBeauty,
    classyPottery,
    discountOffer,
    ShopNowBtn
  } = i18n.getDataByLanguage(i18n.language);

  return (
    <div className='w-11/12 my-16 text-white text-center rounded-card flex flex-col items-center justify-center m-auto h-[390px] bg-center bg-cover bg-no-repeat bg-black/30 bg-blend-overlay ' style={{backgroundImage:`url(${discountBgColor})`}} >
        <p className='text-[17px]'>{timelessBeauty}</p>
        <h5 className='text-[22px] md:text-[30px] mt-6'>{classyPottery}</h5>
        <h6 className='text-[18px] md:text-[25px] mt-2'>{discountOffer}</h6>

        <div className='mt-5'>
            <Link to='/products' className={`text-white m-auto bg-base-accent-1 hover:text-base-accent-1 hover:bg-[rgba(0,0,0,0)] tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[220px] text-center border border-1 border-base-accent-2`}>
                {ShopNowBtn}
                <span>
                    <ArrowRight />
                </span>
            </Link>
        </div>
    </div>
  );
}

export default FirstOrderDiscount;
