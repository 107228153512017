
const translationAddToCart= {
    en: {
     AddToCartBtn:'Add To Cart',
    },
    de: {
        AddToCartBtn:'In den Warenkorb legen',
    }
  };
  
  export default translationAddToCart;