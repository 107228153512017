const translationsServices = {
    en: {
      titleServices: 'Summer Clay Art Workshop',
      descriptionServices: "Pretium nibh ipsum consequat nisl vel pretium. Phasellus egestas tellus rutrum tellus pellentesque eu. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel.",
      uniqueDesignsServices: "Unique Designs",
      basicTechniquesServices: "Basic Techniques",
      wheelThrowingServices: "Wheel Throwing",
      decoratingAndGlazingServices: "Decorating and Glazing",
      firingAndFinishingServices: "Firing and Finishing",
    },
    de: {
      titleServices: 'Einzigartige Designs für Handgefertigte Taschen',
      descriptionServices: "Unsere handgefertigten Taschen aus Stoff und Garn zeichnen sich durch hochwertige Materialien und individuelle Designs aus. Jedes Stück wird mit viel Liebe und Handarbeit gefertigt.",
      uniqueDesignsServices: "Einzigartige Designs",
      basicTechniquesServices: "Qualität der Materialien",
      wheelThrowingServices: "Farbvielfalt und Muster",
      decoratingAndGlazingServices: "Nachhaltigkeit und Verantwortung",
      firingAndFinishingServices: "Handgefertigte Verarbeitung",
    }
  };
  
  export default translationsServices;
  