const translationsShopPage = {
    en: {
        noProductsFound: 'no Products Found',
        ShopProducts:"Shop Products"
     
    },
    de: {
        noProductsFound:'Keine Produkte gefunden',
        ShopProducts:"Produkte kaufen"

      
    }
  };
  
  export default translationsShopPage;