import React, { useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { showInfo } from "../utils/toastUtils";  
import { useTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google';
import Captcha from '../components/captch/GoogleRecaptch'; // Import Captcha
import PhoneNumberDropdown from '../components/login/PhoneNumber';
import { api } from '../services/apiServices';
import { useDispatch } from 'react-redux';

import FaEye from '../assets/icons/FaEye';
import FaEyeSlase from '../assets/icons/EyeSlash' 

function SignUp() {
    const toast = useRef(null); // Toast reference
    const { i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); 

    const {
        title_signUp,
        alreadyHaveAccount,
        signIn,
        placeholders: {
            firstName,
            familyName,
            email,
            password,
            street,
            city,
            state,
            postalCode,
            country
        },
        buttonCreate,
        errorMessageRegister,
        successMessageRegister,
        confirmNewPassword,
        errorPasswordMismatch
    } = i18n.getDataByLanguage(i18n.language);

    const [isAddAddress, setAddAddress] = useState(false);
    const [isVerified, setIsVerified] = useState(false); // CAPTCHA verification state
    const [registerForm, setRegisterForm] = useState({
        name: "",
        familyName: "",
        email: "",
        password: "",
        addresses: [
            {
                street: "",
                city: "",
                state: "",
                postalCode: "",
                country: ""
            }
        ]
    });
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errors, setErrors] = useState({}); // State for form errors
    const [passwordVisible, setPasswordVisible] = useState(false);  // State to manage password visibility
    const [repeatPasswordVisible, setRepeatPasswordVisible] = useState(false);  // State to manage repeat password visibility

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name.includes("addresses")) {
            const [addressIndex, field] = name.split(".");
            const index = parseInt(addressIndex.replace('addresses[', '').replace(']', ''));
            setRegisterForm(prevState => {
                const newState = { ...prevState };
                newState.addresses[index][field] = value;
                return newState;
            });
        } else {
            setRegisterForm(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!registerForm.name) newErrors.name = "First name is required.";
        if (!registerForm.familyName) newErrors.familyName = "Last name is required.";
        if (!registerForm.email) newErrors.email = "Email is required.";
        if (registerForm.password !== repeatPassword)
            newErrors.repeatPass = errorPasswordMismatch;

        if (!registerForm.password) {
            newErrors.password = "Password is required.";
        } else if (registerForm.password.trim().length < 8) {
            newErrors.password = "Password must be at least 8 characters";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validatePostalCode = async (postalCode) => {
        try {
            const response = await api.get(`locations/${postalCode}`);
            return true;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                showInfo(toast, 'error', 'Error', 'Invalid postal code. Please enter a valid one.');
                return false;
            } else {
                showInfo(toast, 'error', 'Error', 'An error occurred while validating the postal code. Please try again.');
                return false;
            }
        }
    };

    const sendRegisterForm = async (event) => {
        event.preventDefault();

        if (registerForm.addresses[0].postalCode.length > 0) {
            const isPostalValid = await validatePostalCode(registerForm.addresses[0].postalCode);
            if (!isPostalValid) {
                return;
            }
        }

        if (!isVerified) {
            showInfo(toast, "error", "Error", "Please complete the CAPTCHA.");
            return;
        }

        if (!validateForm()) {
            showInfo(toast, "error", "Error", "Please fill in all required fields.");
            return;
        }

        setIsLoading(true);

        try {
            const res = await api.post("auth/register", registerForm);
            
            setIsLoading(false);
            showInfo(toast, "success", "Success", successMessageRegister);

            const from = location.state?.from || "/";
            setTimeout(() => {
                navigate(from);
            }, 2000);
        } catch (error) {
           
            setIsLoading(false);
            const error_ = error.response?.data?.message || errorMessageRegister;
            showInfo(toast, "error", "Error", error_);
        }
    };

    const handleAddAddress = (e) => {
        const isChecked = e.target.checked;
        setAddAddress(isChecked);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(prevState => !prevState);
    };

    const toggleRepeatPasswordVisibility = () => {
        setRepeatPasswordVisible(prevState => !prevState);
    };

    return (
        <div className='text-base-accent-1 w-11/12 md:3/4 lg:w-1/2 m-auto flex flex-col gap-5 items-center text-center my-20 justify-center'>
            <Toast position="top-center" className='w-11/12 z-50 m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />
            <h5 className='text-[30px]'>{title_signUp}</h5>
            <p>{alreadyHaveAccount} <Link to="/account/login" className='underline'>{signIn}</Link></p>
            <form className='flex flex-col w-full m-auto md:w-11/12 gap-5 mt-6 text-[14px]' onSubmit={sendRegisterForm}>
                {/* Name Input */}
                <div className="input-container">
                    <input
                        type="text"
                        value={registerForm.name}
                        name="name"
                        onChange={handleChange}
                        id="name"
                        className={`py-3 outline-none px-6 rounded-[25px] border ${errors.name ? 'border-red-500' : 'border-base-second_border'}`}
                    />
                    <label htmlFor="name">{firstName}</label>
                </div>
                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

                {/* Family Name Input */}
                <div className="input-container">
                    <input
                        type="text"
                        value={registerForm.familyName}
                        name="familyName"
                        onChange={handleChange}
                        id="familyName"
                        className={`py-3 outline-none px-6 rounded-[25px] border ${errors.familyName ? 'border-red-500' : 'border-base-second_border'}`}
                    />
                    <label htmlFor="familyName">{familyName}</label>
                </div>
                {errors.familyName && <p className="text-red-500 text-sm">{errors.familyName}</p>}

                {/* Email Input */}
                <div className="input-container">
                    <input
                        type="email"
                        value={registerForm.email}
                        name="email"
                        onChange={handleChange}
                        id="email"
                        className={`py-3 outline-none px-6 rounded-[25px] border ${errors.email ? 'border-red-500' : 'border-base-second_border'}`}
                    />
                    <label htmlFor="email">{email}</label>
                </div>
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

                {/* Password Input */}
                <div className="input-container">
                    <input
                        type={passwordVisible ? "text" : "password"}
                        value={registerForm.password}
                        name="password"
                        onChange={handleChange}
                        id="password"
                        className={`py-3 outline-none px-6 rounded-[25px] border ${errors.password ? 'border-red-500' : 'border-base-second_border'}`}
                    />
                    <label htmlFor="password">{password}</label>
                    <span
                        onClick={togglePasswordVisibility}
                        className="absolute right-6 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    >
                        {passwordVisible ? <FaEyeSlase size={20} /> : <FaEye size={20} />}
                    </span>
                </div>
                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}

                {/* Repeat Password Input */}
                <div className="input-container">
                    <input
                        type={repeatPasswordVisible ? "text" : "password"}
                        value={repeatPassword}
                        name="repeatPassword"
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        id="repeatPassword"
                        className={`py-3 outline-none px-6 rounded-[25px] border ${errors.repeatPass ? 'border-red-500' : 'border-base-second_border'}`}
                    />
                    <label htmlFor="repeatPassword">{confirmNewPassword}</label>
                    <span
                        onClick={toggleRepeatPasswordVisibility}
                        className="absolute right-6 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    >
                        {repeatPasswordVisible ? <FaEyeSlase size={20} /> : <FaEye size={20} />}
                    </span>
                </div>
                {errors.repeatPass && <p className="text-red-500 text-sm">{errors.repeatPass}</p>}

                {/* Address Fields */}
                <div className='flex items-center gap-2'>
                    <input
                        type="checkbox"
                        checked={isAddAddress}
                        onChange={handleAddAddress}
                        id="helper-checkbox"
                        aria-describedby="helper-checkbox-text"
                        className="w-3 h-3 text-base-accent-1 bg-gray-100 border-gray-300 rounded focus:text-base-accent-1 focus:ring-2"
                    />
                    <p htmlFor="helper-checkbox" className="text-sm">
                        <span>Add Address</span>
                    </p>
                </div>
                {isAddAddress && (
                    <>
                        <PhoneNumberDropdown />
                        <div className="input-container">
                            <input
                                type="text"
                                value={registerForm.addresses[0].street}
                                name="addresses[0].street"
                                onChange={handleChange}
                                id="street"
                                className="py-3 outline-none px-6 rounded-[25px] border border-base-second_border"
                            />
                            <label htmlFor="street">{street}</label>
                        </div>

                        <div className="input-container">
                            <input
                                type="text"
                                value={registerForm.addresses[0].city}
                                name="addresses[0].city"
                                onChange={handleChange}
                                id="city"
                                className="py-3 outline-none px-6 rounded-[25px] border border-base-second_border"
                            />
                            <label htmlFor="city">{city}</label>
                        </div>

                        <div className="input-container">
                            <input
                                type="text"
                                value={registerForm.addresses[0].postalCode}
                                name="addresses[0].postalCode"
                                onChange={handleChange}
                                id="postalCode"
                                className="py-3 outline-none px-6 rounded-[25px] border border-base-second_border"
                            />
                            <label htmlFor="postalCode">{postalCode}</label>
                        </div>

                        <div className="input-container">
                            <input
                                type="text"
                                value={registerForm.addresses[0].country}
                                name="addresses[0].country"
                                onChange={handleChange}
                                id="country"
                                className="py-3 outline-none px-6 rounded-[25px] border border-base-second_border"
                            />
                            <label htmlFor="country">{country}</label>
                        </div>
                    </>
                )}

                {/* Captcha */}
                <Captcha onVerified={setIsVerified} />
                <GoogleLogin />

                <div className="mx-auto w-full">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="text-base-accent-1 disabled:bg-gray-300 disabled:text-white disabled:border-gray-300 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[100%] md:w-[130px] md:mx-auto text-center border border-1 border-base-accent-2"
                    >
                        {buttonCreate}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default SignUp;
