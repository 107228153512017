const translationsRegister = {
    en: {
        title_signUp: "Sign Up",
        alreadyHaveAccount: "Already have an account?",
        signIn: "Sign In",
        placeholders: {
            firstName: "First Name",
            familyName: "Family Name",
            email: "Email",
            password: "Password",
            id: "ID",
            street: "Street",
            city: "City",
            state: "State",
            postalCode: "Postal Code",
            country: "Country"
        },
        buttonCreate: "Create",
        successMessageRegister: "Your registration was successful. Please check your email.",
        errorMessageRegister: "Registration failed"
    },
    de: {
        title_signUp: "Registrieren",
        alreadyHaveAccount: "Haben Sie schon ein Konto?",
        signIn: "Anmelden",
        placeholders: {
            firstName: "Vorname",
            familyName: "Nachname",
            email: "E-Mail",
            password: "Passwort",
            id: "ID",
            street: "Straße",
            city: "Stadt",
            state: "Bundesland",
            postalCode: "Postleitzahl",
            country: "Land"
        },
        buttonCreate: "Erstellen",
        successMessageRegister: "Ihre Registrierung war erfolgreich, bitte überprüfen Sie Ihre E-Mails",
        errorMessageRegister: "Registrierung fehlgeschlagen"
    }
};

export default translationsRegister;
