import React from 'react';
import SkillBox from './SkillBox';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

// Import Swiper modules
import { Autoplay, EffectSlide } from 'swiper/modules';
import translationsSkill from "../../translations/mainpage/translationsSkill";
import translationsSkill2 from "../../translations/mainpage/translationsSkill2";
import translationsSkill3 from "../../translations/mainpage/translationsSkill3";
import {useTranslation} from "react-i18next";

function Skills() {

    const { i18n } = useTranslation(); // Get the i18n instance to access current language

    // Get translation data dynamically based on current language
    const { skillTitle: skillTitle1, skillDescription: skillDescription1 } = i18n.getDataByLanguage(i18n.language);
    const { skillTitle: skillTitle2, skillDescription: skillDescription2 } = i18n.getDataByLanguage(i18n.language);
    const { skillTitle: skillTitle3, skillDescription: skillDescription3 } = i18n.getDataByLanguage(i18n.language);

    const skillsData = [translationsSkill, translationsSkill2, translationsSkill3];
  return (
    <div className='w-11/12 m-auto my-24'>
      <Swiper
        slidesPerView={3} // Number of slides visible at a time
        spaceBetween={0} // Space between slides
        pagination={{ clickable: true }} // Enable clickable pagination
        breakpoints={{
          0: {
            slidesPerView: 1, // For small screens, show 1 slide
          },
          768: {
            slidesPerView: 2, // For medium screens, show 2 slides
          },
          998: {
            slidesPerView: 3, // For larger screens, show 3 slides
          },
        }}
        modules={[Autoplay]} // Adding Autoplay module
        autoplay={{
          delay: 5000, // Time between each slide in milliseconds
          disableOnInteraction: false, // Prevent autoplay from stopping when user interacts
        }}
        speed={1200} // Set transition speed (in milliseconds)
        loop={true} // Enable continuous loop
      >
          {skillsData.map((translations, index) => {
              // Get the correct translation for the current index
              const { skillTitle, skillDescription } = translations[i18n.language];

              return (
                  <SwiperSlide key={index}>
                      <SkillBox
                          title={skillTitle} // Using dynamic translation for skill title
                          description={skillDescription} // Using dynamic translation for skill description
                          image={`image${index + 1}.png`} // Dynamically set the image based on the index
                      />
                  </SwiperSlide>
              );
          })}
      </Swiper>
    </div>
  );
}

export default Skills;
