import { useDispatch, useSelector } from 'react-redux';
import BannerShop from '../../components/banner/bannerShop';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { fetchCategories } from '../../store/slices/categoriesSlice';
import CategoryBox from '../../components/mainpage/Categories/CategoryBox';
import Loader from '../../components/Loader/Loader';
import { Link } from "react-router-dom";
import addTranslations from '../../utils/convertProductsToi18nFormat';  // Import the addTranslations function

function Categories() {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.items);
  const status = useSelector((state) => state.categories.status);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCategories());
    }
  }, [status, dispatch]);

  const { i18n } = useTranslation();
  const { titlecategories } = i18n.getDataByLanguage(i18n.language);

  // Add translations for categories
  useEffect(() => {
    if (categories.length > 0) {
      // Add translations using the addTranslations function
      addTranslations(categories, i18n);
    }
  }, [categories, i18n]);

  if (status === 'loading') return <Loader />;

  return (
    <>
      <div>
        <BannerShop title={titlecategories} />
        <div className="w-11/12 m-auto grid grid-cols-1 gap-8 my-16 md:grid-cols-2 lg:grid-cols-4">
          {categories.length > 0 && categories?.map((category) => (
            <Link key={category.id} to={`/categories/${category.id}`}>
              <CategoryBox
                src={category.categoryImage && category.categoryImage.imageUrl}
                name={i18n.t(`product:productName-${category.id}`, { defaultValue: category.translations[0]?.name })}
              />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default Categories;
