import React from 'react';
import icon from '../assets/images/Masorelogo.png';
import Accordion from './FooterAccordion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Insta from '../assets/icons/Insta';
import Telegram from '../assets/icons/Telegram';

function Footer() {
  const {i18n}=useTranslation()
  const { footerAccout,
    FooterImpressum,
    footerSupport,
    FooterAboutUs,
    FooterContactUs,
    footerText}=i18n.getDataByLanguage(i18n.language);
  return (
    <div className="bg-base-accent-1">
      <div className="  py-16 text-white">
        {/* main section */}
        <div className="flex flex-wrap w-11/12 m-auto gap-10">
          {/*  form */}
           {/*<div className="w-full md:w-5/12">
           <h5 className="text-[25px]">Join Our Mailing List</h5>
            <input
              className="w-full border border-1 text-white border-white rounded-[17px] mt-5 p-4 bg-[rgba(0,0,0,0)]"
              type="email"
              placeholder="Email here"
            />
          </div>
*/}
        

        {/*<Accordion title="Quick Link">
          <p>Help Center</p>
          <p>Customer Service</p>
        </Accordion>*/}

        <Accordion title="Support">
          <div className='flex flex-col gap-4'>
            <Link to='/user/dashboard'>{footerAccout}</Link>
          <Link to='/about-us'>{FooterAboutUs}</Link>

          <Link to='/contact'>{FooterContactUs}</Link>
          <Link  to='/Impressum'>{FooterImpressum}</Link>

          </div>

        </Accordion>
        </div>

        {/*bottom section*/}
        <div className="flex flex-wrap gap-10 mt-10 items-center">
          {/* logo */}
          <div className="w-full md:w-5/12">
            <img src={icon} alt="Footer Icon" />
          </div>

          {/* description */}
          <div className=" md:w-3/12  w-11/12 m-auto mt-5 md:mt-10">
            <p>
              {footerText}
            </p>
          </div>

          {/*icons*/}
          <div className="w-full md:w-3/12 flex gap-4 justify-center md:justify-start  mt-5 md:mt-0">
          <a href='https://www.instagram.com/masooreh.de' target="_blank" rel="noopener noreferrer">
                <span className=" flex items-center justify-center  ">
                  <Insta />
                </span>
              </a>

              <a href='https://t.me/masooreh' target="_blank" rel="noopener noreferrer">
                <span className=" flex items-center justify-center   ">
                  <Telegram />
                </span>
              </a>
            </div>
        </div>
        
      </div>
      <hr/>
        <p className='text-center text-gray-400 py-4 '>© 2025 MASORREH SE & Co. KG</p>

    </div>
  );
}

export default Footer;
