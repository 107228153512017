const translationsContactUs = {
  en: {
    formTitle: "Reach-out form",
    formSubtitle: "Please fill out the form below to get in touch with us.",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    mobileNumber: "Mobile Number",
    message: "Message",
    required: "is required",
    sendMessage: "Send message",
    formSubmitted: "Your message has been sent successfully.",
    captchaError: "Captcha verification failed. Please try again.",
    submissionError: "There was an error while submitting the form.",
    errorSendMessage: "Your message was not sent.",
    invalidEmail: "Invalid email format.",
    invalidPhone: "Phone number must contain only digits.",
    captchaRequired: "Captcha is required.",
    formError: "Please fill all required fields correctly.",
    submitting: "Submitting...",
  },
  de: {
    formTitle: "Kontaktformular",
    formSubtitle: "Bitte füllen Sie das folgende Formular aus, um uns zu kontaktieren.",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    mobileNumber: "Handynummer",
    message: "Nachricht",
    required: "ist erforderlich",
    sendMessage: "Nachricht senden",
    formSubmitted: "Ihre Nachricht wurde erfolgreich gesendet.",
    captchaError: "Captcha-Überprüfung fehlgeschlagen. Bitte versuchen Sie es erneut.",
    submissionError: "Beim Absenden des Formulars ist ein Fehler aufgetreten.",
    errorSendMessage: "Ihre Nachricht wurde nicht gesendet.",
    invalidEmail: "Ungültiges E-Mail-Format.",
    invalidPhone: "Die Telefonnummer darf nur Ziffern enthalten.",
    captchaRequired: "Captcha ist erforderlich.",
    formError: "Bitte füllen Sie alle erforderlichen Felder korrekt aus.",
    submitting: "Senden...",
  },
};

export default translationsContactUs;
