import React from 'react'

function AddCategoryImages({uploadSuccess,handleUploadImage,handleImageChange}) {
  return (
    
        <div className="mt-10">
            <p className="font-semibold">Upload Image for Category</p>
            <form onSubmit={handleUploadImage} className="space-y-4">
                <div>
                   
                    <input
                        type="file"
                        onChange={handleImageChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        required
                    />
                </div>
                <button type="submit" className="mt-4 hover:border-base-accent-1 hover:text-base-accent-1 hover:bg-white bg-base-accent-1 text-white py-2 px-4 rounded-[30px]">
                    Upload Image
                </button>
            </form>

            {uploadSuccess && (
                <p className="text-green-600 mt-2">Image uploaded successfully!</p>
            )}
        </div>

  )
}

export default AddCategoryImages