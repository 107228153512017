// utils/checkAuth.js
import getToken from "./getToken"; 
import { logout } from "../store/slices/authSlice";

export default function checkAuthStatus(dispatch) {
  try {
    const token = getToken();

    if (!token) {
      dispatch(logout());
      return;
    }

  } catch (error) {
    dispatch(logout());
  }
}
