import vector from '../../assets/images/Vector_2fa1784b-dad5-4714-b3a5-afd2b04c3f36.png';
import aboutImage1 from '../../assets/images/masooreh.about.fix.webp';
import aboutImage2 from '../../assets/images/masooreh.about-big.webp';
import ArrowRigth from '../../assets/icons/ArrowRight';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function AboutusProducts() {
  const { i18n } = useTranslation(); // هوک ترجمه
  const {
    productTitle1, productDescription1, aboutUsProductTitle1,
    productTitle2, productDescription2, aboutUsProductTitle2,
    productTitle3, productDescription3, aboutUsProductTitle3,
    productTitle4, productDescription4, aboutUsProductTitle4,
    shopNow
  } = i18n.getDataByLanguage(i18n.language); // دریافت داده‌ها بر اساس زبان فعلی

  const [angle, setAngle] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for right, -1 for left

  useEffect(() => {
    const interval = setInterval(() => {
      setAngle((prev) => {
        if (prev >= 10) setDirection(-1); // تغییر جهت به سمت چپ
        if (prev <= -10) setDirection(1); // تغییر جهت به سمت راست
        return prev + direction * 0.5; // چرخش کمی به سمت چپ یا راست
      });
    }, 50); // به روز رسانی زاویه هر 50 میلی ثانیه

    return () => clearInterval(interval); // پاک کردن interval وقتی کامپوننت unmount شود
  }, [direction]);

  return (
    <div className="flex flex-col md:flex-row gap-5 py-20 w-11/12 m-auto">
      {/* بخش چپ: اطلاعات محصولات */}
      <div className="md:w-1/2 grid gap-8 grid-cols-1 sm:grid-cols-2">
        {[{ title: productTitle1, description: productDescription1 },
          { title: productTitle2, description: productDescription2 },
          { title: productTitle3, description: productDescription3 },
          { title: productTitle4, description: productDescription4 }].map((product, index) => (
            <div key={index} className="flex flex-col gap-5">
              <div className="flex gap-1 text-base-accent-1">
                <img src={vector} alt="Vector Icon " />
                <p>{product.title}</p>
              </div>
              <p className="text-base-accent-2 text-[14px] leading-6">
                {product.description}
              </p>
             
            </div>
          ))}
        <div className="col-span-1 sm:col-span-2">
          {/* دکمه خرید */}
          <Link
            to="/cart"
            className={`text-base-accent-1 justify-center md:w-fit mt-4 m-auto bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center  gap-2 rounded-[30px] px-[1.5rem] py-[0.7rem] w-full md:m-0 border border-1 border-base-accent-2`}
          >
            {shopNow} {/* ترجمه دکمه */}
            <span>
              <ArrowRigth />
            </span>
          </Link>
        </div>
      </div>

      {/* بخش راست: تصاویر */}
      <div className="md:w-1/2 flex relative justify-center md:justify-start  items-center">
        <img
          src={aboutImage1}
          className="w-10/12  rounded-[50%] md:w-2/3"
          alt="About Us Image 1"
        />
        <img
          src={aboutImage2}
          className="absolute w-1/3 rounded-md"
          alt="About Us Image 2"
          style={{
            transform: `rotate(${angle}deg)`,
            transformOrigin: 'top center',
            zIndex: 10,
            bottom: '10%',
            right: '30%',
          }}
        />
      </div>
    </div>
  );
}

export default AboutusProducts;
