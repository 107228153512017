
const translationCheckOut = {
    en: {
      CheckOutBtn:'Check Out',
    },
    de: {
        CheckOutBtn:'Warenkorb anzeigen',
    }
  };
  
  export default translationCheckOut;