import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import getToken from '../../utils/getToken';
import { setUser } from '../../store/slices/authSlice';
import Loader from '../../components/Loader/Loader';
import { api } from '../../services/apiServices';

// Registering ChartJS components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function DashboardWithUserInfo() {
  const token = getToken();
  const [userData, setUserData] = useState(0); // Users count
  const [productData, setProductData] = useState(0); // Products count
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // API endpoints
  const USERS_API = 'auth/users';
  const PRODUCTS_API = 'products';
  const USER_INFO_API = `auth/user-info?token=${token}`;

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch user data
        const usersResponse = await api.get(USERS_API, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(usersResponse.data.totalElements); 
        // Fetch product data
        const productsResponse = await api.get(PRODUCTS_API, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProductData(productsResponse.data.totalElements); // Total products

        // Fetch user information (if not already in store)
        if (!user) {
          const userResponse = await api.get(USER_INFO_API, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          dispatch(setUser(userResponse.data)); // Save user information in the store
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, user, dispatch]);

  // Chart data for Users
  const userChartData = {
    labels: ['Users'],
    datasets: [
      {
        label: 'Total Users',
        data: [userData],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  // Chart data for Products
  const productChartData = {
    labels: ['Products'],
    datasets: [
      {
        label: 'Total Products',
        data: [productData],
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
      },
    ],
  };

  if (loading || !user) {
    return <Loader />;
  }

  return (
    <div className="dashboard w-11/12 m-auto my-10">
      <div className="">
        <h5 className="font-bold text-[25px] text-base-accent-1 mt-5 text-center md:text-left">Masooreh Dashboard</h5>
        <p className="text-gray-500 text-center md:text-left">Welcome {user.name} !</p>
      </div>


      <div className="chart-container my-10 grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="chart-item max-h-96 overflow-hidden">
          <h5>Total Users</h5>
          <Bar
            data={userChartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: true,
                  text: 'Total Users',
                },
                tooltip: {
                  enabled: true,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
            height={300} 
          />
        </div>

        <div className="chart-item max-h-96 overflow-hidden">
          <h5>Total Products</h5>
          <Bar
            data={productChartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: true,
                  text: 'Total Products',
                },
                tooltip: {
                  enabled: true,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
            height={300} 
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardWithUserInfo;
