import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import getToken from '../../utils/getToken';
import { showInfo } from '../../utils/toastUtils';
import { Toast } from 'primereact/toast';
import { api } from '../../services/apiServices';

const FileUpload = () => {
    const toast = useRef(null); // Toast reference
    const { id } = useParams();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');

    // Clean up object URLs to prevent memory leaks
    useEffect(() => {
        return () => {
            selectedFiles.forEach((file) => {
                if (file.preview) {
                    URL.revokeObjectURL(file.preview);
                }
            });
        };
    }, [selectedFiles]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files).map((file) => ({
            file, // Keep the original file object
            preview: URL.createObjectURL(file), // Create a preview URL for each file
        }));
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        if (selectedFiles.length === 0) {
            setMessage('Please select a file.');
            return;
        }

        const token = getToken();
        setIsSubmitting(true);

        try {
            const uploadPromises = selectedFiles.map(({ file }) => {
                const formData = new FormData();
                formData.append('file', file);
                return api.post(
                    `product_images?productId=${id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            });

            await Promise.all(uploadPromises);
            showInfo(toast, 'success', 'Success', 'Files sent successfully!');
            setSelectedFiles([]); // Clear the file input after upload
        } catch (error) {
            showInfo(
                toast,
                'error',
                'Error',
                `${error.response ? error.response.data.message : error.message}`
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='h-full'>
                        <Toast  position="top-center" className='w-11/12 z-50  m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />

            <form onSubmit={handleUpload} className="flex flex-col h-[100%] justify-center  items-center">
                <label className="flex items-center justify-center w-16 h-16 rounded-full border-2 border-gray-400 cursor-pointer hover:bg-gray-200">
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className="hidden"
                        multiple
                        accept="image/*"
                    />
                    <span className="text-2xl text-gray-400">+</span>
                </label>
                <button
                    type="submit"
                    className={`my-4 px-6  py-2 text-white ${
                        isSubmitting ? 'bg-gray-400' : 'bg-base-accent-1'
                    } rounded-[30px] hover:${isSubmitting ? '' : 'bg-green-600'}`}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Sending...' : 'Send'}
                </button>
                {selectedFiles.length > 0 && (
                    <div className="flex flex-wrap my-5 items-center">
                        {selectedFiles.map(({ file, preview }, index) => (
                            <div key={index} className="flex items-center mx-2">
                                {file?.type?.startsWith('image/') ? (
                                    <img
                                        src={preview}
                                        alt={`Uploaded ${file.name}`}
                                        className="w-24 h-24 object-cover border rounded"
                                    />
                                ) : (
                                    <span className="text-sm text-gray-600">{file.name}</span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {message && <p className="mb-4 text-red-500">{message}</p>}
            </form>
        </div>
    );
};

export default FileUpload;
