const translationsUserPannel = {
    en: {
        accountDetails: "Account details",
        userName: "Name",
        UserfamilyName: "Family Name",
        UserEmail: "Email",
        UserYourAddress: "Your Address"
    },
    de: {
        accountDetails: "Kontodaten",
        userName: "Name",
        UserfamilyName: "Nachname",
        UserEmail: "E-Mail",
        UserYourAddress: "Ihre Adresse"
    }
};

export default translationsUserPannel;
