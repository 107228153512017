import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import BannerAccount from "../components/banner/BannerAccount";
import { useTranslation } from "react-i18next";
import { showInfo } from "../utils/toastUtils";
import { useDispatch, useSelector } from "react-redux";
import { login, setUser } from "../store/slices/authSlice";
import Captcha from "../components/captch/GoogleRecaptch";
import { api } from "../services/apiServices";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import FaEye from '../assets/icons/FaEye';
import FaEyeSlase from '../assets/icons/EyeSlash'

function SignIn() {
  const { i18n } = useTranslation();
  const { signIn, userNameInput, password, forgotPass, signUp,isNotActive } =
    i18n.getDataByLanguage(i18n.language);

  const navigate = useNavigate();
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    usernameOrEmail: "",
    password: ""
  });

  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);  // State to manage password visibility

  useEffect(() => {
    if (isAuth) {
      const from = location.state?.from || "/";
      navigate(from);
    }
  }, [isAuth, navigate, location.state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!loginForm.usernameOrEmail)
      newErrors.usernameOrEmail = "Username or email is required";
    if (!loginForm.password) newErrors.password = "Password is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendLoginForm = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      showInfo(toast, "error", "Error", "Please fill in all required fields!");
      return;
    }
    if (!isVerified) {
      showInfo(toast, "error", "Error", "Please complete the CAPTCHA.");
      return;
    }

    setLoading(true);

    try {
      const res = await api.post("auth/signin", loginForm);
      if (!res.data.active){
        showInfo(toast, "error", "error", isNotActive);
        return}

      
      const { accessToken } = res.data;

      if (accessToken) {
        dispatch(login({ accessToken }));
        const userResponse = await api.get(
          `auth/user-info?token=${accessToken}`
        );
        const user = userResponse.data;
        dispatch(setUser(user));
        showInfo(toast, "success", "Success", "You have successfully logged in!");
        
        const from = location.state?.from || "/";
        setTimeout(() => {
          navigate(from);
        }, 2000);
      }
    } catch (error) {
      showInfo(toast, "error", "Error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginSuccess = async (credentialResponse) => {
    if (!credentialResponse || !credentialResponse.credential) {
      return;
    }

    try {
      const response = await api.post("auth/google-login", {
        token: credentialResponse.credential,
      });
      const { token } = response.data;

      if (token) {
        dispatch(login({ accessToken: token }));
        dispatch(setUser(response.data));

        showInfo(toast, "success", "Success", "You have successfully logged in!");

        const from = location.state?.from || "/";
        setTimeout(() => {
          navigate(from);
        }, 1000);
      }
    } catch (error) {
      showInfo(toast, "error", "Error", "Failed to log in with Google!");
    }
  };

  const handleLoginError = () => {
    showInfo(toast, "error", "Error", "Google login failed! Please try again.");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);  // Toggle password visibility
  };

  return (
    <>
      <div>
        <BannerAccount title={signIn} />
        <div className="text-base-accent-1 w-11/12 md:3/4 lg:w-1/2 m-auto flex flex-col gap-5 items-center text-center my-20 justify-center">
          <h5 className="text-[30px]">{signIn}</h5>
          <form
            onSubmit={(event) => event.preventDefault()}
            className="flex flex-col w-full m-auto md:w-11/12 gap-5 mt-6 text-[14px]"
          >
            <Toast position="top-center" className="w-11/12 z-50 m-auto md:w-[300px] text-[14px] h-auto" ref={toast} />
            
            {/* Username or Email Input */}
            <div className="relative">
              <input
                type="text"
                value={loginForm.usernameOrEmail}
                name="usernameOrEmail"
                onChange={handleChange}
                className={`py-3 w-full outline-none px-6 rounded-[25px] border ${
                  errors.usernameOrEmail ? "border-red-500" : "border-base-second_border"
                }`}
              />
              <label htmlFor="usernameOrEmail" className="absolute text-sm text-base-second_border left-6 top-0 pt-2">{userNameInput}</label>
              {errors.usernameOrEmail && (
                <p className="text-red-500 text-sm absolute">{errors.usernameOrEmail}</p>
              )}
            </div>

            {/* Password Input */}
            <div className="relative">
              <input
                type={passwordVisible ? "text" : "password"}  // Use "text" if password is visible, else "password"
                value={loginForm.password}
                name="password"
                onChange={handleChange}
                className={`py-3 w-full outline-none px-6 rounded-[25px] border ${
                  errors.password ? "border-red-500" : "border-base-second_border"
                }`}
              />
              <label htmlFor="password" className="absolute text-sm text-base-second_border left-6 top-0 pt-2">{password}</label>
              {errors.password && (
                <p className="text-red-500 text-sm absolute">{errors.password}</p>
              )}

              {/* Eye Icon to toggle password visibility */}
              <span
                onClick={togglePasswordVisibility}
                className="absolute right-6 top-1/2 transform -translate-y-1/2 cursor-pointer"
              >
                {passwordVisible ? <FaEyeSlase size={20} /> : <FaEye size={20} />}  {/* Show the appropriate icon */}
              </span>
            </div>

            {/* Google reCAPTCHA */}
            <Captcha onVerified={setIsVerified} />

            {/* Google Login */}
            <GoogleOAuthProvider clientId="257214365412-72qfdfmtjsufnfg2iqnfmc589f4d2190.apps.googleusercontent.com">
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onError={handleLoginError}
                  theme="outline"
                  size="large"
                  text="Sign In with Google"
                />
              </div>
            </GoogleOAuthProvider>

            {/* Submit Button */}
            <div className="mx-auto w-full">
              <button
                onClick={sendLoginForm}
                disabled={loading}
                className={`text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[100%] md:w-[130px] md:mx-auto text-center border ${
                  loading ? "opacity-50 cursor-not-allowed" : "border-base-accent-2"
                }`}
              >
                {loading ? "Loading..." : signIn}
              </button>
            </div>
          </form>

          {/* Links for Forgot Password and Sign Up */}
          <div className="w-full flex text-center mt-2 text-[14px] justify-between md:text-[15px]">
            <Link to="/account/forgot-password" className="underline text-base-accent-1">
              {forgotPass}
            </Link>
            <Link to="/account/register" className="underline text-base-accent-1">
              {signUp}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
