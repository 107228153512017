
const translationSoldOut = {
    en: {
      SoldOutBtn:'Sold Out',
    },
    de: {
        SoldOutBtn:'Ausverkauft',
      
    }
  };
  
  export default translationSoldOut;