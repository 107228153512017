import React, { useEffect } from 'react';
import Mainpage from './pages/Mainpage';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import Layout from './layouts/Layout';
import ResetPassword from './pages/ResetPassword';
import AdminPannel from './pages/Admin-pannel/AdminPannel';
import AddItem from './pages/Admin-pannel/AddProduct';
import AddCategory from './pages/Admin-pannel/AddCategory';
import AllItems from './pages/Admin-pannel/AllProducts';
import Users from './pages/Admin-pannel/Users';
import Orders from './pages/Admin-pannel/Orders';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, setUser } from './store/slices/authSlice';
import axios from 'axios';
import UserPannel from './pages/User-pannel/UserPannel';
import getToken from './utils/getToken';
import ProductImages from './pages/Admin-pannel/ProductImages';
import UserInfo from './pages/Admin-pannel/UserInfo';
import EditProduct from './pages/Admin-pannel/EditProduct';
import EditProductImage from './pages/Admin-pannel/EditProductImage';
import Categories from './pages/shop/Categories';
import ShopPage from './pages/shop/ShopPage';
import ProductDetails from './pages/shop/ProductDetails';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import checkAuthStatus from './utils/chackAuth';
import ProtectedRoute from './components/route/ProtectedRoute';
import AllProducts from './pages/shop/AllProducts';
import UserShoppigCart from './pages/UserShoppingCart/UserShoppigCart';
import CheckOut from './pages/CheckOut/CheckOut';
import UserOrders from './pages/User-pannel/UserOrders';
import UserAddresses from './pages/User-pannel/UserAddresses';
import { syncCartWithServer } from './store/slices/cartSlice';
import Loader from './components/Loader/Loader';
import AboutUs from './pages/AboutUs';
import { PaymentFailed } from './pages/Payment/Failed';
import { PaymentSuccess } from './pages/Payment/Success';
import ContactUs from './pages/ContactUs';
import ActivateAccount from './pages/ActiveAccount';
import { api } from './services/apiServices';
import OrderDetailsPage from './pages/Admin-pannel/OrderDetails';
import ContactusMessages from './pages/Admin-pannel/ContactusMessages';
import Impressum from './pages/Impressum';
function App() {
  const dispatch = useDispatch();
  const token = getToken();
  const {user}=useSelector(state=>state.auth)
  useEffect(() => {
    const checkAuthentication = async () => {
      checkAuthStatus(dispatch); 
     

      if (token) {
        dispatch(login({ accessToken: token }));

        try {
          const userResponse = await api.get(
            `auth/user-info?token=${token}`
          );
          dispatch(setUser(userResponse.data));
         

        } catch (error) {
        }
      }
      
    };

    checkAuthentication();
  }, [dispatch,token]);
 
  const isLoading = useSelector((state) => state.cart.isLoading);

  useEffect(() => {
    if (user) {
        dispatch(syncCartWithServer());
    }
}, [dispatch, user]);
  if (isLoading)
    return <Loader />
  return (
    <Layout>
        <ScrollToTop />
      <Routes>
      
        <Route path='/' element={<Mainpage />} />
        <Route path='/categories' element={<Categories />} />
        <Route path='/categories/:id' element={<ShopPage />} />
        <Route path="/account/login" element={<SignIn />} />
        <Route path="/account/register" element={<SignUp />} />
        <Route path="/account/forgot-password" element={<ForgotPassword />} />
        <Route path="/account/reset-password" element={<ResetPassword />} />
        <Route path="/account/active"  element={<ActivateAccount />} />
        
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute>
              <AdminPannel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/contact-messages"
          element={
            <ProtectedRoute>
              <ContactusMessages />
            </ProtectedRoute>
          }
        />
       

        <Route
          path="/admin/add-product"
          element={
            <ProtectedRoute>
              <AddItem />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/add-product/product-images/:id"
          element={
            <ProtectedRoute>
              <ProductImages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/add-category"
          element={
            <ProtectedRoute>
              <AddCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/dashboard"
          element={
            <ProtectedRoute>
              <UserPannel />
            </ProtectedRoute>
          } />
          <Route
          path="/user/orders"
          element={
            <ProtectedRoute>
              <UserOrders  />
            </ProtectedRoute>
          } />
          <Route
          path="/user/addresses"
          element={
            <ProtectedRoute>
              <UserAddresses  />
            </ProtectedRoute>
          } />
        <Route
          path="/checkout"
          element={<CheckOut />}
        />
        <Route
         path='/admin/orders'
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          } />
        <Route
          path="/orders/:orderId"
          element={
            <ProtectedRoute>
              <OrderDetailsPage  />
            </ProtectedRoute>
          } />
        <Route
          path="/user/orders"
          element={
            <ProtectedRoute>
              <UserOrders  />
            </ProtectedRoute>
          } />
        <Route path='/admin/products' element={<AllItems />} />
        <Route path='/admin/items/edit-product/:id' element={<EditProduct />} />
        <Route path='/admin/items/edit-images/:id' element={<EditProductImage />} />
        <Route path='/admin/users' element={<Users />} />
        <Route path='/admin/users/user-info/:id' element={<UserInfo />} />
        
        <Route path='/user/dashboard' element={<UserPannel />} />
        <Route path='/products' element={<AllProducts />} />
        <Route path='/products/product-details/:id' element={<ProductDetails />} />
        <Route path="/cart" element={<UserShoppigCart /> } />
        <Route path="/about-us" element={<AboutUs /> } />
        <Route path="/contact" element={<ContactUs /> } />
        <Route path="/payment/success" element={<PaymentSuccess /> } />
        <Route path="/payment/failed" element={<PaymentFailed /> } />
        <Route path='/Impressum' element={<Impressum />} />        
      </Routes>
    </Layout>
  );
}

export default App;