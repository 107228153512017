import React, { useState, useEffect, useRef } from 'react';
import getToken from '../../utils/getToken';
import { showInfo } from '../../utils/toastUtils';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import Trash from  '../../assets/icons/Trash';
import Edit from '../../assets/icons/PencilMd';
import formatPrice from '../../utils/formatPrice';
import { api } from '../../services/apiServices';

const ProductManagement = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [message, setMessage] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [discount, setDiscount] = useState(false);
    const [currentPage, setCurrentPage] = useState(0); // صفحه فعلی
    const [totalPages, setTotalPages] = useState(0); // تعداد صفحات کل
    const [pageSize] = useState(10); // تعداد محصولات در هر صفحه
    const token = getToken();
    const toast = useRef(null); // Toast reference

    // Fetch products from API
    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await api.get(`products?pageNo=${currentPage}&pageSize=${pageSize}`);
                setProducts(response.data.content);
                setTotalPages(response.data.totalPages); // تعداد صفحات کل
            } catch (error) {
                showInfo(toast, "error", "Error", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [currentPage, discount, pageSize]);

    // Handle discount application
    const handleApplyDiscount = async () => {
        // Validate discount percentage
        if (!discountPercentage || isNaN(discountPercentage) || discountPercentage < 0 || discountPercentage > 100) {
            showInfo(toast, "error", "Invalid Discount", "Please enter a valid discount percentage between 0 and 100.");
            return;
        }
    
        const isDiscount = discountPercentage > 0;
        try {
            const response = await api.put(
                `products/discount/all?discountPercentage=${discountPercentage}&isDiscount=${isDiscount}`, 
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            setDiscount(!discount);
            showInfo(toast, "success", "Success", `Discount of ${discountPercentage}% applied to all products!`);
        } catch (error) {
            showInfo(toast, "error", "Error", error.message);
        }
    };

    const handleDeleteProduct = async () => {
        try {
            await api.delete(`products/${selectedProduct.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setProducts(products.filter(product => product.id !== selectedProduct.id));
            showInfo(toast, "success", "Success", 'Product deleted successfully!');
            setIsDeleting(false);
            setSelectedProduct(null);
        } catch (error) {
            showInfo(toast, "error", "Error", error.message);
        }
    };

    // Handlers for pagination
    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(prev => prev + 1);
        }
    };

    if (loading) return <Loader />;

    return (
        <div className='h-full w-full'>
            <div className="container w-11/12 mx-auto overflow-x-auto mb-5">
            <Toast  position="top-center" className='w-11/12 z-50  m-auto md:w-[300px] text-[14px] h-auto' ref={toast} />
            <h5 className="text-xl md:text-2xl text-base-accent-1 my-8 font-semibold text-center md:text-left mb-4">Product Management</h5>

                {/* Discount Input and Button */}
                <div className="flex items-center mb-5">
                    <input
                        type="text"
                        value={discountPercentage}
                        onChange={(e) => setDiscountPercentage(e.target.value)}
                        placeholder="Enter Discount Percentage"
                        className="border px-4 py-2 mx-2 rounded-[30px]"
                    />
                    <button
                        onClick={handleApplyDiscount}
                        className="bg-base-accent-1 text-white px-4 py-2 rounded-[30px]"
                    >
                        Apply Discount
                    </button>
                </div>

                {message && <p className="text-green-500 my-4">{message}</p>}
                
                <table className="w-full min-w-[800px] m-auto overflow-scroll px-4 py-5 border-collapse border border-gray-300">
                    <thead className='text-[16px]'>
                        <tr className='text-base-accent-1 text-left px-2 '>
                            <th className="border border-base px-4 py-2">Image</th>
                            <th className="border px-4 py-2">Name</th>
                            <th className="border px-4 py-2">Price</th>
                            <th className="border border-gray-300 px-4 py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody className='text-[14px]'>
                        {products?.map(product => (
                            <tr key={product.id} className='hover:bg-gray-50 duration-200 cursor-pointer'>
                                <td className="border border-gray-300 px-4 py-2">
                                    <img src={product.images[0]?.imageUrl} alt={product.name} className="w-16 rounded-md h-16 object-cover" />
                                </td>
                                <td className="border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">{product.translations[0]?.name || product.name}</td>
                                <td className="border text-[13px] md:text-[15px] border-gray-300 px-4 py-2">
                                    {product.discount ? (
                                        <div className="flex flex-col">
                                            <span className="line-through text-gray-500">{formatPrice(product.mainPrice)}</span>
                                            <span className="text-green-500">{formatPrice(product.price)} <span className='text-white text-[13px] bg-red-500 p-1 ml-3 rounded-[30px]'>{product.discountPercentage}%</span></span>
                                        </div>
                                    ) : (
                                        `${formatPrice(product.price)}`
                                    )}
                                </td>
                                <td className="border text-[13px] md:text-[15px] px-4 py-2">
                                    <div className='flex justify-between'>
                                        <button className={`px-2 py-1 text-[13px] md:text-[15px] w-[100px] ${product.active === false ? 'text-red-500' : 'text-green-500'} rounded`}>
                                            {product.active === false ? 'Deactivate' : 'Activate'}
                                        </button>
                                        <div className='flex items-center'>
                                            <Link to={`/admin/items/edit-product/${product.id}`} className="ml-2 text-[13px] md:text-[15px] px-2 py-1 text-blue-500 rounded">
                                                <Edit />
                                            </Link>
                                            <button onClick={() => { setSelectedProduct(product); setIsDeleting(true); }} className="ml-2 text-[13px] md:text-[15px] px-2 py-1 text-red-500 rounded">
                                                <Trash />
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Pagination Controls */}
                <div className="flex justify-between items-center mt-6">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 0}
                        className="px-4 py-2 bg-gray-200 rounded-lg"
                    >
                        Previous
                    </button>
                    <span>
                        Page {currentPage + 1} of {totalPages}
                    </span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages - 1}
                        className="px-4 py-2 bg-gray-200 rounded-lg"
                    >
                        Next
                    </button>
                </div>

                {/* Confirmation Modal for Deleting Product */}
                {isDeleting && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded shadow-md">
                            <p className="text-lg font-semibold text-base-accent-1">Confirm Deletion</p>
                            <p className='text-base-accent-1'>Are you sure you want to delete this product?</p>
                            <div className="mt-4">
                                <button onClick={handleDeleteProduct} className="bg-red-500 text-white px-4 py-2 rounded-[30px] mr-2">Yes</button>
                                <button onClick={() => setIsDeleting(false)} className="bg-gray-300 text-black px-4 py-2 rounded-[30px]">No</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductManagement;
