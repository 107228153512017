import React, { useEffect, useState } from "react";
import formatPrice from "../../../utils/formatPrice";
import Share from "../../../assets/icons/Share";
import { useTranslation } from "react-i18next";
import Basket from '../../../assets/icons/Basket'
import { Link } from "react-router-dom";
const ProductDetails = ({ product, isCopied, handleCopyLink }) => {
  const { i18n } = useTranslation();

  // Extract translations dynamically
  const { 
    ProductCategory, 
    stockMessage, 
    outOfStock, 
    share, 
    copied, 
    linkCopied ,
    viewCartDetails
  } = i18n.getDataByLanguage(i18n.language)


  return (
    <>
      {/* Product Name */}
      <h6 className="text-[18px] md:text-[24px]  text-base-accent-1 mt-5 font-semibold">
        {i18n.getDataByLanguage(i18n.language)?.product?.[`productName-${product.id}`] || ""}
      </h6>

      {/* Product Description */}
      <p className="text-base-accent-2">
        {i18n.getDataByLanguage(i18n.language)?.product?.[`productDescription-${product.id}`] || ""}
      </p>

      {/* Product Price */}
     {product.discount ?
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center ">
          <p className="text-[14px] line-through text-gray-400 ">
          {formatPrice(product?.mainPrice)}
        </p>
        <p className="text-white bg-red-500 w-7 h-7 flex items-center justify-center text-[12px]  rounded-[10px]">{product.discountPercentage}%</p></div>

        <p className="text-[16px]   ">
          {formatPrice(product?.price)}
        </p>
      </div>

   :
      <p className="text-[16px]  ">
        {formatPrice(product?.price)}
      </p>
     }

      {/* Product Category */}
      {/*<p className="text-base-accent-1">
        {ProductCategory}: {product.productCategoryName}
      </p>*/}

      {/* Stock Status */}
      {product.active ? (
        <p className="bg-base-accent-1 text-white p-1 px-5 w-fit rounded-[20px]">
          {stockMessage?.replace("{{stock}}", product.stockQuantity)}
        </p>
      ) : (
        <p className="bg-base-accent-1 text-white p-1 px-5 w-fit rounded-[20px]">
          {outOfStock}
        </p>
      )}

      {/* Share Button */}
      <div className="text-[14px]">
        <button
          onClick={handleCopyLink}
          className="text-base-accent-1 rounded hover:text-base-background-3 transition"
        >
          {isCopied ? (
            <div className="flex gap-3">
              <p className="text-green-600">{copied}</p>
              <p>{linkCopied}</p>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <Share /> <p>{share}</p>
            </div>
          )}
        </button>
        
      </div>
    <Link to='/cart' className="text-base-accent-1 flex items-center gap-2 text-sm">
    <Basket />
    <p >{viewCartDetails}</p>
    </Link>
    </>
  );
};

export default ProductDetails;
