const translationUserOrders = {
    en: {
      title: 'Your Orders',
      noOrders: 'You have not placed any orders yet.',
      orderNumber: 'Order #',
      date: 'Date',
      total: 'Total',
      status: 'Status',
      firstItemImage: 'First Item Image',
      details: 'Details',
      viewDetails: 'View Details',
    },
    de: {
      title: 'Ihre Bestellungen',
      noOrders: 'Sie haben noch keine Bestellungen aufgegeben.',
      orderNumber: 'Bestellnummer',
      date: 'Datum',
      total: 'Gesamt',
      status: 'Status',
      firstItemImage: 'Bild des ersten Artikels',
      details: 'Einzelheiten',
      viewDetails: 'Einzelheiten anzeigen',
    }
  };
  
  export default translationUserOrders;