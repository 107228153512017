import banner from "../../assets/images/masooreh.home6.webp"
function BannerShop({title}) {
  return (
    <div style={{backgroundImage: `url(${banner})`}} className="h-[200px] md:h-[300px] bg-gray-300/65 bg-center bg-blend-overlay flex items-center justify-center flex-col bg-cover md:bg-top">
            <h4 className="text-base-accent-1 text-center w-11/12 m-auto text-[18px] md:text-[30px]">{title}</h4>
            <div className="flex gap-10 text-[18px] md:text-[20px]">

            </div>
    </div>
  )
}

export default BannerShop;