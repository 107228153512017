import React, { useRef, useState } from 'react';
import ArrowRight from '../assets/icons/ArrowRight';
import BannerShop from '../components/banner/bannerShop';
import { useTranslation } from 'react-i18next';
import Captcha from '../components/captch/GoogleRecaptch';
import { Toast } from 'primereact/toast';
import { api } from '../services/apiServices';
import { useNavigate } from 'react-router-dom';
import { showInfo } from '../utils/toastUtils';


function ContactUs() {
  const { i18n } = useTranslation();

  const navigate = useNavigate()
  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    message,
    formTitle,
    formSubtitle,
    required,
    sendMessage,
    captchaError,
    errorSendMessage,
    invalidEmail,invalidPhone,captchaRequired,formError,submitting
  } = i18n.getDataByLanguage(i18n.language);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });
  const toast = useRef(null); 

  const [errors, setErrors] = useState({});
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate required fields
    if (!formData.firstName.trim()) {
      newErrors.firstName = `${firstName} ${required}`;
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = `${lastName} ${required}`;
    }
    if (!formData.email.trim()) {
      newErrors.email = `${email} ${required}`;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = invalidEmail;
    }
    if (!formData.phone.trim()) {
      newErrors.phone = `${mobileNumber} ${required}`;
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = invalidPhone;
    }
    if (!formData.message.trim()) {
      newErrors.message = `${message} ${required}`;
    }

  
    if (!captchaVerified) {
      newErrors.captcha = captchaError || captchaRequired;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.current.show({
        severity: 'error',
        summary: 'Form Error',
        detail: formError,
        life: 2000,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await api.post('contact-us', {
        ...formData,
        createdAt: new Date().toISOString(),
      });

      if (response.status === 200 || response.status === 201) {
       showInfo(toast, "success", "Success", message);
        

        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '',
        });

        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
    } catch (error) {
      showInfo(toast, "error", "Error", errorSendMessage);

    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Toast ref={toast} position="top-center" className='w-11/12  m-auto md:w-[300px] text-[14px] h-auto'  />

      <BannerShop title={formTitle} />
      <div className="  flex text-base-accent-1 items-center justify-center">
        <form
          className="bg-white md:p-8 py-8 rounded-lg w-11/12 md:w-1/2 m-auto"
          onSubmit={handleSubmit}
        >
          <h6 className="text-2xl text-base-accent-1 font-semibold text-center mb-6">
            {formTitle}
          </h6>
          <p className="text-base-accent-2">{formSubtitle}</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
            <div>
              <label htmlFor="firstName" className="block text-[14px] md:text-[16px] font-medium mb-1">
                {firstName} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className={`w-full  px-4 py-2 border rounded-[30px] ${
                  errors.firstName ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none`}
              />
              {errors.firstName && (
                <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>
              )}
            </div>

            <div>
              <label htmlFor="lastName" className="block text-[14px] md:text-[16px] font-medium mb-1">
                {lastName} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className={`w-full px-4 py-2 border rounded-[30px] ${
                  errors.lastName ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none`}
              />
              {errors.lastName && (
                <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label htmlFor="email" className="block text-[14px] md:text-[16px] font-medium mb-1">
                {email} <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-4 py-2 border rounded-[30px] ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <div>
              <label htmlFor="phone" className="block text-[14px] md:text-[16px] font-medium mb-1">
                {mobileNumber} <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className={`w-full px-4 py-2 border rounded-[30px] ${
                  errors.phone ? 'border-red-500' : 'border-gray-300'
                } focus:outline-none`}
              />
              {errors.phone && (
                <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="message" className="block text-[14px] md:text-[16px] font-medium mb-1">
              {message} <span className="text-red-500">*</span>
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-[20px] ${
                errors.message ? 'border-red-500' : 'border-gray-300'
              } focus:outline-none`}
            ></textarea>
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">{errors.message}</p>
            )}
          </div>

          <div className="mb-4">
            <Captcha onVerified={setCaptchaVerified} />
            {errors.captcha && (
              <p className="text-red-500 text-sm mt-1">{errors.captcha}</p>
            )}
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full md:w-[230px] flex items-center gap-4 justify-center border bg-base-accent-1 text-white py-2 px-4 rounded-[30px] hover:bg-white hover:text-base-accent-1 transition duration-300"
            >
              {isSubmitting ? submitting : sendMessage} <ArrowRight />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
