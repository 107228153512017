import React from 'react'
import { useSelector } from 'react-redux'
import CheckOutProductBox from '../../components/checkOut/CheckOutProductBox'
import formatPrice from '../../utils/formatPrice'
import AddAddressPage from '../../components/checkOut/AddAddress'

function CheckOut() {
    const {products,totalCost}=useSelector(state=>state.cart)
  return (
    <div className='flex flex-col-reverse md:flex-row'>
        <div className='w-full md:w-1/2 '>
        <AddAddressPage /></div>
        <div className='h-fit md:h-[100vh] flex flex-col gap-5 p-10 bg-gray-200 w-full md:w-1/2 sticky'>
        {products.map(product =>
            <CheckOutProductBox key={product.id} product={product} />
        )}
       <div className='mt-10 text-base-accent-1 font-semibold flex justify-between'>
        <p>Total</p>
        <p>{formatPrice(totalCost)}</p>
       </div>
       <p className='text-base-accent-1 text-justify text-[14px] md:text-[16px]'>Hinweis zur Kleinunternehmerregelung gemäß § 19 UStG:

Auf Grund der Anwendung der Kleinunternehmerregelung wird keine Umsatzsteuer erhoben und ausgewiesen.</p>
        </div>

        
    </div>
  )
}

export default CheckOut