const translationsAboutusProducts = {
    en: {
      productTitle1: "Handmade bags made of fabric and wool",
      productDescription1: "Unique handcrafted bags made from high-quality fabric and wool, combining both functionality and style.",
      
      productTitle2: "Handmade purses made of yarn and colorful fabric",
      productDescription2: "Creatively designed purses made from high-quality thread and colorful fabrics, perfectly complementing any outfit.",
      
      productTitle3: "Handmade makeup bags made of yarn and fabric",
      productDescription3: "Practical and stylish makeup bags crafted from high-quality thread and fabric for everyday use.",
      
      productTitle4: "Handmade jewelry made from excellent materials",
      productDescription4: "Elegant handcrafted jewelry made from excellent materials, enhancing every outfit.",
  
      shopNow: "Shop Now",
    },
    de: {
      productTitle1: "Handgefertigte Taschen aus Stoff und Wolle",
      productDescription1: "Einzigartige, handgefertigte Taschen aus hochwertigem Stoff und Wolle, die sowohl funktional als auch stilvoll sind.",
      
      productTitle2: "Handgefertigte Geldbörsen aus Garn und buntem Stoff",
      productDescription2: "Kreativ gestaltete Geldbörsen aus hochwertigem Garn und buntem Stoff, die jedes Outfit perfekt ergänzen.",
      
      productTitle3: "Handgefertigte Make-up Taschen aus Garn und Stoff",
      productDescription3: "Praktische und stilvolle Make-up Taschen, gefertigt aus hochwertigem Garn und Stoff für den täglichen Gebrauch.",
      
      productTitle4: "Handgefertigter Schmuck aus ausgezeichneten Materialien",
      productDescription4: "Eleganter, handgefertigter Schmuck aus erstklassigen Materialien, der jedes Outfit veredelt.",
  
      shopNow: "Jetzt einkaufen",
    },
  };
  
  export default translationsAboutusProducts;
  