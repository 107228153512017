import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowRight from "../../assets/icons/ArrowRight";
import leftImage from "../../assets/images/masooreh.aboutus.webp";

function Training() {
  const { i18n } = useTranslation(); // هوک ترجمه
  const {
    trainingTitle,
    trainingDescription,
    trainingShopNow,
  } = i18n.getDataByLanguage(i18n.language);

  return (
    <div className="w-11/12 mt-20 md:h-[400px] gap-10 m-auto flex flex-col md:flex-row">
      <div
        className="w-full md:w-1/2 bg-center rounded-card bg-cover h-[350px]"
        style={{ backgroundImage: `url(${leftImage})` }}
      ></div>
      <div className="w-full md:w-1/2 flex flex-col justify-center items-start gap-5">
        <h6 className="text-[30px] text-base-accent-1">{trainingTitle}</h6>
        <p className="leading-7 text-base-accent-2">{trainingDescription}</p>
        <div className="m-0 flex justify-start items-start">
          <Link
            to="/cart"
            className={`text-base-accent-1 mt-4 m-auto w-fit bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] border border-1 border-base-accent-2`}
          >
            {trainingShopNow}
            <span>
              <ArrowRight />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Training;
