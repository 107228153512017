import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../services/apiServices';

export const fetchProducts = createAsyncThunk(
  'shop/fetchProducts',
  async ({ id, filters, currentPage, pageSize }) => {
    const params = {
      pageNo: currentPage,
      pageSize,
      sortBy: 'id',
      sortDir: filters.sortOrder,
      ...(filters.minPrice && { minPrice: filters.minPrice }),
      ...(filters.maxPrice && { maxPrice: filters.maxPrice }),
      ...(filters.search && { search: filters.search }),
    };

    const queryString = new URLSearchParams(params).toString();
    const response = await api.get(
      `products/category/${id}?${queryString}`
    );

    return response.data;
  }
);

const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    products: [],
    loading:'idle',
    filters: {
      minPrice: '',
      maxPrice: '',
      sortOrder: 'desc',
      search: '',
    },
    currentPage: 0,
    pageSize: 10,
  },
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.loading = 'success';
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.loading = 'error';
      });
  },
});

export const { setFilters, setCurrentPage, setPageSize } = shopSlice.actions;
export default shopSlice.reducer;
