import React from 'react'

function ProductImages({product,setSelectedImage,selectedImage}) {
  return (
    <div className="flex w-full md:w-1/2 lg:w-5/12 flex-col lg:flex-row gap-4">
    {/* Main Image */}
    <div style={{backgroundImage: `url(${selectedImage})`}} className="relative w-full bg-cover md:bg-contain rounded-card  bg-center bg-no-repeat  lg:w-[350px] h-[400px]     overflow-hidden  ">
      
    </div>

    {/* Thumbnails */}
    <div className="flex flex-row lg:flex-col gap-2">
      {product.images && product.images?.length > 0 &&
        product.images.slice(0, 4).map((item, index) => (
          <img
            key={index}
            src={item.imageUrl} // Thumbnail image source
            alt={`Thumbnail ${index}`} // Thumbnail alt text
            onClick={() => setSelectedImage(item.imageUrl)} // Update the main image when a thumbnail is clicked
            className={`w-24 rounded-card h-24 object-cover cursor-pointer   border-[2px]  ${
              selectedImage === item.imageUrl ? 'border-gray-500' : 'border-transparent' // Highlight the selected thumbnail
            }`}
          />
        ))}
    </div>
  </div>

  )
}

export default ProductImages