import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function PhoneNumberDropdown() {
  const [value, setValue] = useState();

  return (
   
      <PhoneInput
        defaultCountry="DE"  
        value={value}
        onChange={setValue}
        international
        withCountryCallingCode 
      />
   
  );
}

export default PhoneNumberDropdown;
