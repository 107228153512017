const translationsFilters = {
    en: {
      filtersTitle: 'Filters',
      priceLabel: 'Price',
      sortLabel: 'Sort',
      ascending: 'Ascending',
      descending: 'Descending',
      searchPlaceholder: 'Search products',
      applyButton: 'Apply',
    },
    de: {
      filtersTitle: 'Filter',
      priceLabel: 'Preis',
      sortLabel: 'Sortieren',
      ascending: 'Aufsteigend',
      descending: 'Absteigend',
      searchPlaceholder: 'Produkte suchen',
      applyButton: 'Anwenden',
    }
  };
  
  export default translationsFilters;
  