import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getToken from '../../utils/getToken';
import { api } from '../../services/apiServices';

const UserInfo = () => {
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const token = getToken();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`auth/user/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
      }
    };

    fetchUser();
  }, [id, token]);

  if (!user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-lg font-semibold text-gray-600">User not found!</p>
      </div>
    );
  }

  return (
    <div className="w-10/12 md:w-1/2 m-auto   bg-white shadow-lg rounded-lg p-8 my-10 border border-gray-200">
      <div className="flex flex-col items-center">
        <div
          className="w-24 h-24 flex items-center justify-center rounded-full text-white font-bold text-2xl mb-4 shadow-lg"
          style={{ backgroundColor: getRandomColor() }}
        >
          {user.name.charAt(0)}
        </div>
        <h6 className="text-[16px] md:text-[20px] font-semibold mb-2 text-gray-800">{`${user.name} ${user.familyName}`}</h6>
        <p className="text-gray-500 text-[14px] mb-4">{user.email}</p>
        <span
          className={`px-4 py-1 rounded-full text-white text-sm ${
            user.active ? 'bg-green-500' : 'bg-red-500'
          }`}
        >
          {user.active ? 'Active' : 'Inactive'}
        </span>
      </div>

      <div className="mt-6">
        <p className="md:text-[20px] text-[16px] font-semibold mb-4 text-gray-800">Addresses:</p>
        {user.addresses && user.addresses.length > 0 ? (
          user.addresses.map((address, index) => (
            <div
              key={index}
              className="p-4 mb-4 bg-gray-50 rounded-lg shadow border border-gray-100"
            >
              {address.street && (
                <p className="text-gray-700 mb-2">
                  <span className="font-semibold text-gray-900">Street:</span> {address.street}
                </p>
              )}
              {address.city && (
                <p className="text-gray-700 mb-2">
                  <span className="font-semibold text-gray-900">City:</span> {address.city}
                </p>
              )}
              {address.country && (
                <p className="text-gray-700 mb-2">
                  <span className="font-semibold text-gray-900">Country:</span> {address.country}
                </p>
              )}
              {address.postalCode && (
                <p className="text-gray-700">
                  <span className="font-semibold text-gray-900">Postal Code:</span> {address.postalCode}
                </p>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-500">No addresses found.</p>
        )}
      </div>
    </div>
  );
};

// Helper function for random color generation
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export default UserInfo;
