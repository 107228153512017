const addTranslations = (categories, i18n) => {
  // Group translations by language
  const translations = categories.reduce((acc, category) => {
    category.translations.forEach((translation) => {
      if (!acc[translation.languageCode]) {
        acc[translation.languageCode] = {};
      }
      // Add category name with a unique key for each language
      acc[translation.languageCode][`categoryName-${category.id}`] = translation.name;
    });
    return acc;
  }, {});

  // Add translations to i18n under the "category" namespace
  Object.keys(translations).forEach((lang) => {
    i18n.addResourceBundle(lang, "category", translations[lang], true, true);
  });
};

export default addTranslations;
