
const translationsbuttomMenu = {
    en: {
      buttomMenuHome:'home',
      buttomMenuShop:'Shop',

      buttomMenuLogin:'Login',
      buttomMenuAccount:'Account',



      
  
    },
    de: {
        buttomMenuHome:'heim',
        buttomMenuShop:'Geschäft',
  
        buttomMenuLogin:'Login',
        buttomMenuAccount:'Konto',
  
  
    }
  };
  
  export default translationsbuttomMenu;