import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Submenu from "./SubMenu"; // Import Submenu
import masoorehLogo from "../../../assets/images/masoorehLogo.jpeg";
import Bag from "../../../assets/icons/Bag";
import LanguageSelection from "./LanguageSelection";
import { SET_IS_OPEN } from "../../../store/slices/CartOpenSlice";
import ProfileButton from "../../Button/ProfileButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../store/slices/categoriesSlice";
import { useTranslation } from "react-i18next";
import addTranslations from "../../../utils/convertCategoriesToi18Format";

function DesktopHeader() {
  const categories = useSelector((state) => state.categories.items);
  const { i18n, t } = useTranslation();

  const { count } = useSelector((state) => state.cart); 

  useEffect(() => {
    if (categories.length > 0) {
      addTranslations(categories, i18n);
    }
  }, [categories, i18n]);

  const status = useSelector((state) => state.categories.status);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate =useNavigate()

  const { HomeMenu, ShopMenu, AboutUsMenu, ContactUsMenu } =
    i18n.getDataByLanguage(i18n.language);

  useEffect(() => {
    if (status === "idle") dispatch(fetchCategories());
  }, [id, status, dispatch]);

  const toggleMenu = () => setMenuOpen((prev) => !prev);
  const toggleSubmenuMobile = () => setSubmenuOpen((prev) => !prev);


  const clickHandler = () => {
    navigate('/cart')
  };

  return (
    <header className="w-full bg-white text-base-accent-1">
      {/* Desktop Header */}
      <div className="hidden md:flex justify-between items-center w-11/12 mx-auto py-6">
        <div className="w-1/4 flex items-center">
          <Link to='/'>
          <img src={masoorehLogo} alt="masooreh" className="h-10" />
          </Link>        </div>
        <div className="w-2/4 flex justify-center">
          <nav className="relative flex space-x-8">
            {/* Home */}
            <Link
              to="/"
              className="text-base font-medium hover:text-base-background-3 duration-200 flex items-center"
            >
              {HomeMenu}
            </Link>

            {/* Shop - Desktop Submenu */}
            <Submenu
              categories={categories}
              submenuOpen={submenuOpen}
              toggleSubmenu={() => setSubmenuOpen(!submenuOpen)}
            />

            {/* About Us */}
            <Link
              to="/about-us"
              className="text-base font-medium duration-200 hover:text-base-background-3 flex items-center"
            >
              {AboutUsMenu}
            </Link>
            <Link
              to="/contact"
              className="text-base font-medium duration-200 hover:text-base-background-3 flex items-center"
            >
              {ContactUsMenu}
            </Link>
          </nav>
        </div>
        <div className="w-1/4 flex items-center justify-end gap-6 relative">
          <span className="cursor-pointer relative" onClick={clickHandler}>
            <Bag />
            {count > 0 && (
              <span className="absolute -top-2 -right-2 bg-base-accent-1 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                {count}
              </span>
            )}
          </span>
          <ProfileButton />
          <LanguageSelection />
        </div>
      </div>

      {/* Mobile Header */}
      <div className="flex md:hidden justify-between items-center w-full px-4 py-4">
        <button onClick={toggleMenu} className="text-xl">
          ☰
        </button>
        <div>
          <Link to='/'>          <img src={masoorehLogo} alt="masooreh" className="h-10 mx-auto" />
          </Link>
        </div>
        <span className="relative"  onClick={clickHandler}>
          <Bag />
          {count > 0 && (
            <span className="absolute -top-2 -right-2 bg-base-accent-1 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {count}
            </span>
          )}
        </span>
      </div>

      {/* Mobile Menu */}
      <div
        className={`absolute w-full left-0 top-[60px] z-20 bg-white shadow-md transition-all duration-300 ${
          menuOpen
            ? "max-h-screen opacity-100"
            : "max-h-0 opacity-0 overflow-hidden"
        }`}
      >
        <nav>
          <ul className="flex flex-col space-y-2 py-4 px-6">
            <li>
              <Link
                to="/"
                className="block px-4 py-2 text-sm hover:text-base-background-3"
                onClick={() => setMenuOpen(false)}
              >
                {HomeMenu}
              </Link>
            </li>
            <li>
              <Submenu
                categories={categories}
                submenuOpen={submenuOpen}
                setMenuOpen={setMenuOpen}
                toggleSubmenu={toggleSubmenuMobile}
              />
            </li>
            <li>
              <Link
                to="/about-us"
                className="block px-4 py-2 text-sm hover:text-base-background-3"
                onClick={() => setMenuOpen(false)}
              >
                {AboutUsMenu}
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block px-4 py-2 text-sm hover:text-base-background-3"
                onClick={() => setMenuOpen(false)}
              >
                {ContactUsMenu}
              </Link>
            </li>
            <li>
              <LanguageSelection />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default DesktopHeader;