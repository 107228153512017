
const translationShopNow = {
    en: {
      ShopNowBtn:'Shop Now',
    },
    de: {
        ShopNowBtn:'Jetzt kaufen',
      
    }
  };
  
  export default translationShopNow;