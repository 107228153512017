import  { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import BannerShop from '../../components/banner/bannerShop';
import {  useDispatch, useSelector } from 'react-redux';
import ProductImages from '../../components/shopping/ProductDetails/ProductImages';
import ProductDetailsSection from '../../components/shopping/ProductDetails/ProductDetailsSection';
import { api } from '../../services/apiServices';
import addProductTranslations from '../../utils/convertProductToi18Next';
import { useTranslation } from 'react-i18next';



function ProductDetails() {
  const {pathname}=useLocation()
  const {loading}= useSelector((state) => state.shop);
  const { id } = useParams(); // Extract product ID from the URL parameters
  const [product, setProduct] = useState([]); // State to store the product details
  const [error, setError] = useState(false); // State to handle errors
  const [isLoading, setLoading] = useState(false); // State to manage loading status
  const [selectedImage, setSelectedImage] = useState(''); // State for the selected main image
  const [shareLink, setShareLink] = useState(''); // State to store the shareable link
  const [isCopied, setIsCopied] = useState(false); // State to track if the link is copied
  const [productCategory,setProductCategory]=useState(null)
  const [categoryId,setCategoryId]=useState(null);
    const [isTranslationsAdded, setTranslationsAdded] = useState(false);
    const { i18n } = useTranslation();
  
  
  useEffect(() => {
   
    // Function to fetch product details
    const getProduct = async () => {
      setLoading(false)
      try {
        const response = await api.get(`products/${id}`); // API call to fetch product by ID
        setCategoryId(response.data.productCategoryId)
        setLoading(true)
        setProduct(response.data); // Set product details in state
        setSelectedImage(response.data.images[0]?.imageUrl); // Set the first image as the default selected image
        setShareLink(`${window.location.origin}/products/${id}`); // Generate the shareable link
      } catch (error) {
        setError(error.message); // Handle any errors during the fetch
      }
    };
    if (id) getProduct(); // Fetch product details only if an ID is available
   
  }, [id,pathname]);
  useEffect(() => {
      if (product) {
        addProductTranslations([product], i18n);
        setTranslationsAdded(true); // Mark translations as added
      }
    }, [product, i18n]);
  
    // Render only if translations are added
    if (!isLoading) {
      return <Loader />;
    }
    if (!isTranslationsAdded)
      return <Loader />

  // Function to copy the shareable link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setIsCopied(true); // Set copied status to true
      setTimeout(() => setIsCopied(false), 2000); // Reset the copied status after 2 seconds
    });
  };
if (!isLoading)
{
  return <Loader />
}
  return (
    <>
      {!isLoading && !product && (loading=='error' || loading=='loading') ? (
        // Show loader while the product data is being fetched
        <Loader />
      ) : (
        <>
          {/* Render the banner with the product name */}
         <BannerShop title={  i18n.getDataByLanguage(i18n.language)?.product?.[`productName-${product.id}`] || ""}
 />
          <div className="flex flex-col relative md:flex-row items-start md:gap-5 lg:gap-10 w-11/12 my-20 m-auto">
            
            {/* Image Section */}
           <ProductImages selectedImage={selectedImage} setSelectedImage={setSelectedImage} product={product} />
            {/* Product Details Section */}
            <ProductDetailsSection isCopied={isCopied} productCategory={productCategory} handleCopyLink={handleCopyLink} product={product}/>
          </div>
        </>
      )}
              

    </>
  );
}

export default ProductDetails;
