const translationsProfileButtons = {
    en: {
        AccountDetails:"Account details",
        logOut:"Log Out"
    },
    de: {
      AccountDetails:"Kontodetails",
        logOut:"Ausloggen"
    },
   
  };
  
  export default translationsProfileButtons;
  