import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import Captcha from "../components/captch/GoogleRecaptch"; // Import Captcha
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { api } from "../services/apiServices";

function ResetPassword() {
  const { i18n } = useTranslation();
  const navigate = useNavigate(); // Initialize useNavigate for page redirection

  const {
    title,
    newPassword,
    confirmNewPassword,
    buttonReset,
    successMessage,
    errorMessage,
    errorCAPTCHA,
    errorTokenMissing,
    errorPasswordMismatch,
    errorPasswordRequired,
    errorConfirmPasswordRequired,
    loginRedirectMessage, // Message for redirection to login page
  } = i18n.getDataByLanguage(i18n.language);

  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  const [isVerified, setIsVerified] = useState(false); // CAPTCHA verification state
  const toast = useRef(null); // Toast reference

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isVerified) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorCAPTCHA || "Please complete the CAPTCHA.",
        life: 2000,
      });
      return;
    }

    if (!form.password) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorPasswordRequired || "Password is required.",
        life: 2000,
      });
      return;
    }

    if (!form.confirmPassword) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorConfirmPasswordRequired || "Confirm password is required.",
        life: 3000,
      });
      return;
    }

    if (form.password !== form.confirmPassword) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorPasswordMismatch || "Passwords do not match.",
        life: 3000,
      });
      return;
    }

    try {
      const token = new URLSearchParams(window.location.search).get("token"); // Get token from URL
      if (!token) {
        throw new Error(errorTokenMissing || "Invalid or missing reset token.");
      }

      // Send request
      await api.post(
        `auth/reset-password/${token}`, // Include token in URL
        form.password, // Send password as plain text
        {
          headers: {
            "Content-Type": "text/plain", // Set content type to plain text
          },
          transformRequest: [(data) => data], // Prevent transformation to JSON
        }
      );

      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: successMessage || "Password reset successfully.",
        life: 2000,
      });

      // Redirect user to the login page after a few seconds
      setTimeout(() => {
        navigate("/account/login");
      }, 2000); // Redirect after 2 seconds
    } catch (error) {
      const error_ = error.response?.data?.message || errorMessage || "An error occurred.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error_,
        life: 2000,
      });
    }
  };

  return (
    <div className="text-base-accent-1 w-11/12 md:3/4 lg:w-1/2 m-auto flex flex-col gap-5 items-center text-center my-20 justify-center">
      <Toast position="top-center" className="w-11/12 z-50 m-auto md:w-[300px] text-[14px] h-auto" ref={toast} />
      <h5 className="text-[30px]">{title || "Reset Password"}</h5>
      
      <form
        className="flex flex-col w-full m-auto md:w-11/12 gap-5 mt-6 text-[14px]"
        onSubmit={handleSubmit}
      >
        {/* New Password Input */}
        <div className="relative">
          <input
            type="password"
            value={form.password}
           
            name="password"
            onChange={handleChange}
            className="py-3 w-full outline-none px-6 rounded-[25px] border border-1 border-base-second_border"
          />
          <label htmlFor="password" className="absolute text-sm text-base-second_border left-6 top-0 pt-2">{newPassword || "New Password"}</label>
        </div>

        {/* Confirm New Password Input */}
        <div className="relative">
          <input
            type="password"
            value={form.confirmPassword}
          
            name="confirmPassword"
            onChange={handleChange}
            className="py-3 w-full outline-none px-6 rounded-[25px] border border-1 border-base-second_border"
          />
          <label htmlFor="confirmPassword" className="absolute text-sm text-base-second_border left-6 top-0 pt-2">{confirmNewPassword || "Confirm New Password"}</label>
        </div>

        {/* Google reCAPTCHA */}
        <Captcha onVerified={setIsVerified} /> 

        {/* Submit Button */}
        <div className="mx-auto w-full">
          <button
            type="submit"
            className="text-base-accent-1 bg-white hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[100%] md:w-fit md:mx-auto text-center border border-1 border-base-accent-2"
          >
            {buttonReset || "Reset Password"}
          </button>
        </div>
      </form>

      {/* Redirection Message */}
      <p className="text-gray-500 mt-4">
        {loginRedirectMessage || "You will be redirected to the login page shortly."}
      </p>
    </div>
  );
}

export default ResetPassword;
