import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import { Provider } from 'react-redux';
import store from './store/store';
import CookieConsent from './components/cookei'; // same file name
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId="257214365412-72qfdfmtjsufnfg2iqnfmc589f4d2190.apps.googleusercontent.com">
    <Provider store={store}>
        <PrimeReactProvider>
        <BrowserRouter>
              <App />
              <CookieConsent />
        </BrowserRouter>
     </PrimeReactProvider>
    </Provider>
  </GoogleOAuthProvider>
);