const translationsMeet = {
    en: {
      meetTitle: "Meet Our Experts",
      memberName1: "Massoreh",
      memberRole1: "Designer",
      memberName2: "Massoreh",
      memberRole2: "Craftsman",
      memberName3: "Massoreh",
      memberRole3: "Jewelry Maker",
    },
    de: {
      meetTitle: "Treffen Sie unsere Experten",
      memberName1: "Massoreh",
      memberRole1: "Designer",
      memberName2: "Massoreh",
      memberRole2: "Handwerker",
      memberName3: "Massoreh",
      memberRole3: "Schmuckhersteller",
    },
  };
  
  export default translationsMeet;
  