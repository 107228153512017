import React, { useEffect } from 'react';
import BannerShop from '../../components/banner/bannerShop';
import { useDispatch, useSelector } from 'react-redux';
import ShoppingCartCard from '../../components/UserShoppingCart/ShoppingCartCard';
import { Link } from 'react-router-dom';
import ArrowRight from '../../assets/icons/ArrowRight';
import CheckOutBtn from '../../components/cart/Buttons/CheckOutBtn';
import { useTranslation } from 'react-i18next';
import addTranslations from '../../utils/convertCategoriesToi18Format';
import formatPrice from '../../utils/formatPrice';
import Trash from '../../assets/icons/Trash';
import { api } from '../../services/apiServices';
import getToken from '../../utils/getToken';
import { RESET_CART } from '../../store/slices/cartSlice';

function UserShoppigCart() {
  const { products, totalCost } = useSelector(state => state.cart);
  const { i18n } = useTranslation();
  const { user } = useSelector(state => state.auth);
  const dispatch=useDispatch()

  useEffect(() => {
    if (products && products.length > 0) {
      addTranslations(products, i18n);
    }
  }, [products, i18n]);

  const removeHandler = async () => {
    const cartId = localStorage.getItem('cartId');
    const token = getToken();
    dispatch(RESET_CART())
    if (!cartId || !user) return;

    try {
      const body = {
        id: cartId,
        userId: user.id,
        cartItems: [],
      };

      const response = await api.put(
        `cart/user/${user.id}`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Clear localStorage
      localStorage.removeItem('products');
      localStorage.removeItem('count');
      localStorage.removeItem('totalCost');
      
     
    } catch (error) {
    }
  };

  const {
    UserShoppingCart,
    UserContinueShopping,
    subTotal,
    UserProduct,
    UserQuantity,
    UserTotal,
    cartEmptyMessage,
    removeCart
  } = i18n.getDataByLanguage(i18n.language);

  if (products.length === 0)
    return (
      <p className="flex items-center justify-center text-gray-400 py-20">
        {cartEmptyMessage}
      </p>
    );

  return (
    <div>
      <BannerShop title={UserShoppingCart} />
      <div className="my-10 w-11/12 m-auto">
        {/* Table for larger screens */}
        <div className="hidden md:block">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="border-b text-base-accent-1">
                <th className="text-left py-3">{UserProduct}</th>
                <th className="text-left py-3">{UserQuantity}</th>
                <th className="text-left py-3">{UserTotal}</th>
              </tr>
            </thead>
            <tbody className="hidden md:table-row-group">
              {products.map(product => (
                <ShoppingCartCard key={product.id} product={product} />
              ))}
            </tbody>
            <div className="md:hidden">
              {products.map(product => (
                <ShoppingCartCard key={product.id} product={product} />
              ))}
            </div>
          </table>
        </div>

        {/* For small screens */}
        <div className="block md:hidden">
          <p className="text-base-accent-1 text-center text-lg font-medium">
            {UserShoppingCart}
          </p>
          <div className="space-y-4 mt-5">
            {products.map(product => (
              <div key={product.id} className="border-b pb-3">
                <ShoppingCartCard product={product} />
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between my-8">
          <div className="text-right">
            <Link
              to="/products"
              className={`text-base-accent-1 mx-auto md:mx-0 bg-[rgba(0,0,0,0)] hover:text-white hover:bg-base-accent-1 tracking-[0.1rem] duration-500 button_hover flex items-center justify-center gap-2 rounded-[30px] px-[2rem] py-[0.7rem] w-[300px] text-center border border-1 border-base-accent-2`}
            >
              {UserContinueShopping}
              <span>
                <ArrowRight />
              </span>
            </Link>
          </div>
          <div
            onClick={removeHandler}
            className="flex gap-2 justify-center m-auto md:m-0  w-[300px] md:w-fit  mt-5 md:mt-0 text-center text-red-500 border items-center rounded-[30px] border-red-500 py-[0.7rem] hover:bg-red-100 duration-300  px-3"
          >
            <span>{removeCart}</span>
            <span>
              <Trash />
            </span>
          </div>
        </div>

        {/* Total cost */}
        <div className="mt-10 md:mt-20 flex flex-col items-center md:items-end md:text-right">
          <span className="text-[18px] md:text-lg text-base-accent-1">
            {subTotal} {formatPrice(totalCost)}
          </span>
          <span className="w-[300px] mr-0">
            <CheckOutBtn />
          </span>
        </div>
      </div>
    </div>
  );
}

export default UserShoppigCart;
